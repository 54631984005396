import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoService } from '@app/services/po.service';
import { PuchaseOrderItemModel, Tax, Delivery } from '@app/models/purchase-order-item.model';
import { ProductModel } from '@app/models/product.model';
import { AuthService } from '../auth.service';
import { BrandModel } from '@app/models/brand.model';
// import { ItemSheetModel } from '@app/models/itemsheet.model';
import * as moment from 'moment';
import { BuyerService } from '../buyer.service';
import * as lodash from 'lodash-es';
import { ValidationService } from './validation.service';
import { SuppliersSheetHelperService } from './suppliers-sheet-helper.service';
import { AlertMessageService } from '../alert-message.service';
import { ToastrService } from 'ngx-toastr';
import { DropDownService } from '@app/shared/customexcel/service/dropdown.service';
import { SaService } from '../sa.service';
import { CatalogSheetHelperService } from './catalog-sheet-helper.service';
import { OmtSheetHelperService } from './omt-sheet-helper.service';
import { SaSheetHelperService } from './sa-sheet-helper.service';
import { rfqSheetModel } from '@app/models/rfqsheet.model';
import { PoItemService } from '@app/services/po-item.service';
import { MESSAGES } from '@app/config/messages';
import { PARAMS } from '@app/config/params';
import { Observable, BehaviorSubject } from 'rxjs';
import { Dropdownconstants } from '@app/shared/customexcel/dropdownconstants';
import { CommonService } from '../common.service';
import { RfqstatusModel } from '@app/models/rfqstatus.model';


@Injectable({
  providedIn: 'root'
})
export class RfqItemHelperService {
  warehouseList = [];

  constructor(private toastr: ToastrService, private authService: AuthService, private activatedRoute: ActivatedRoute, private alertMessageService: AlertMessageService,
    private suppliersSheetHelperService: SuppliersSheetHelperService, private catalogSheetHelperService: CatalogSheetHelperService, private omtSheetHelperService: OmtSheetHelperService, private saSheetHelperService: SaSheetHelperService, private poService: PoService, private buyerService: BuyerService, private validationService: ValidationService, private poItemService: PoItemService, private commonService: CommonService) {
      this.getuserRoleList();
    }
  selectedPlants: Array<any> = [];
  selectedGroups: Array<any> = [];
  selectedTeam: any;
  selectedRfqStatus: any;
  selectedPriority: any;
  selectedSourcingPoc: any;
  selectedCustomerPoc: any;
  selectedRegions: any;
  itemDetails: Object = {};
  itemsToValidate: Array<Object> = [];
  selectedWarehouse: number;
  selectedPoStatus: Array<any> = [];
  searchFilterValue: string;
  //hiddenItemFields: Array<any> = ["status", "customerRfqDate", "modificationDate", "companyName", "companyId", "msnDescription", "attachment", "teamId", "warehouseId", "supplierQuotedCount"];
  hiddenItemFields: Array<any> = ["customerRfqDate", "modificationDate", "companyName", "companyId", "msnDescription", "attachment", "teamId", "warehouseId", "supplierQuotedCount", "acceptedFlag", "defaulterSupplier", "supplierPercentageReturnByValue", "netOutStandingDebitBalance", "supplierDefaulterCount", "supplierTotalGstrEligibleInvoices", "supplierPercentageGstDefaultCount", "supplierAmountReturnByValue", "supplierBusinessNature", "supplierPaymentTermId"];
  searchFilterType: number;
  coloumsToAudit = ["purchaser", "plantName", "plantId", "productName", "moglixPOC", "customerPoc", "brand", "productType", "arc", "qty", "uomRfq", "moq", "pricingCategory", "status", "clarificationNeeded", "clarificationDetail", "lastSourcedDate", "moglixDescription", "vendorName", "listPrice", "discount", "leadTime", "finalTp", "customerDiscount", "finalSp", "remark", "hsn", "gstPercentage", "edr", "edq", "deliveryType", "quotedDate", "paymentTerm", "reasonForRfqLoss", "reqForGlobalMsn", "remarkForPOLostRegret", "poWonByVendor", "tpByCustomer", "moglixVendorRegistered", "priceValidityDate", "rfqClosureDate", "remark1", "remark2", "remark3", "remark4", "moq", "msn", "tentativeField",,"l1Category","l2Category","gmStarRfq","gmStar","gmApprovalStatus","rejectedFrom","approvedFrom","gmApprovalRemark","autoQuoteToCustomerStatus", "msnUom", "lpToCustomer"];
  coloumsToMSNAssign = ["msn"];
  selectedDateRange: Object;
  wholeItemSheetRowObj = {};
  selectedGMStarStatus: Array<any> = [];
  searchRfqIdValue: string;
  userRoleList: Array<string> = [];
  rfqStatusListForHeader = [{ id: 'quoted', value: 'quoted' }, { id: 'clarification', value: 'clarification' }, { id: 'regret', value: 'regret' }, { id: 'po won', value: 'po won' }, { id: 'po lost', value: 'po lost' }, { id: 'quotation received from supplier', value: 'quotation received from supplier' }, { id: 'po on hold', value: 'po on hold' }, { id: 'closed by customer', value: 'closed by customer' }, { id: 'created', value: 'created' }, { id: 'working', value: 'working' }, { id: 'sent to supplier', value: 'sent to supplier' },{ id: 'erfq response received-read', value: 'erfq response received-read' },{ id: 'erfq response received-unread', value: 'erfq response received-unread' },{ id: 'cm approval pending', value: 'cm approval pending' },{ id: 'cm approved', value: 'cm approved' },{ id: 'cm rejected', value: 'cm rejected' }];
  gstPercentageListForHeader =[{ id: 1, value: 0 },{ id: 2, value: 3 }, { id: 3, value: 5 }, { id: 4, value: 12 }, { id: 5, value: 18 }, { id: 6, value: 28 }];



  public static readonly LINEITEM_PLANT = 'selectedPlants';
  public static readonly LINEITEM_TEAM = 'selectedTeam';
  public static readonly LINEITEM_GROUP = 'selectedGroups';
  public static readonly LINEITEM_REGION = 'selectedRegions';
  public static readonly LINEITEM_PO_STATUS = 'selectedPoStatus';
  public static readonly LINEITEM_WAREHOUSE = 'selectedWarehouse';
  public static readonly LINEITEM_DATE_RANGE = 'selectedDateRange';
  public static readonly LINEITEM_SEARCH_FILTER_VALUE = 'searchFilterValue';
  public static readonly LINEITEM_SEARCH_FILTER_TYPE = 'searchFilterType';
  public static readonly LINEITEM_RFQ_STATUS = 'selectedRfqStatus';
  public static readonly LINEITEM_PRIORITY = "selectedPriority";
  public static readonly LINEITEM_SOURCING_POC = "selectedSourcingPoc";
  public static readonly LINEITEM_CUSTOMER_POC = "selectedCustomerPoc";
  public static readonly LINEITEM_GMSTAR_STATUS = 'selectedGMStarStatus';
  public static readonly LINEITEM_SEARCH_RFQ_ID_VALUE = 'searchRfqIdValue';

  setItemDetails(itemDetails: Object) {
    this.itemDetails = itemDetails
  }

  getItemDetails() {
    return this.itemDetails;
  }

  getuserRoleList() {
    this.userRoleList = this.authService.userRoleList;
  }

  setItemsToValidate(itemsToValidate) {
    this.itemsToValidate = itemsToValidate
  }

  getItemsToValidate() {
    return this.itemsToValidate;
  }

  getColoumsToAudit() {
    return this.coloumsToAudit;
  }

  getHiddenItemFields(): Array<any> {
    return this.hiddenItemFields;
  }

  getColoumsToMSNAssign() {
    return this.coloumsToMSNAssign;
  }

  getPlants(): Array<any> {

    return this.selectedPlants = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_PLANT));
  }

  setPlants(selectedPlants) {
    if (Array.isArray(selectedPlants)) {

      localStorage.setItem(RfqItemHelperService.LINEITEM_PLANT, JSON.stringify(selectedPlants));
    }

  }

  public getGroups(): Array<any> {
    return this.selectedGroups = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_GROUP));
  }

  public setGroups(selectedGroups) {
    if (Array.isArray(selectedGroups)) {
      localStorage.setItem(RfqItemHelperService.LINEITEM_GROUP, JSON.stringify(selectedGroups));
    }
  }

  setTeam(selectedTeam) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_TEAM, JSON.stringify(selectedTeam));

  }

  getTeam() {
    return this.selectedTeam = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_TEAM));
  }

  setRfqStatusFilter(selectedRfqStatus) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_RFQ_STATUS, JSON.stringify(selectedRfqStatus));

  }
  
  setPriorityFilter(selectedPriority) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_PRIORITY, JSON.stringify(selectedPriority));

  }

  getRfqStatusFilter() {
    return this.selectedRfqStatus = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_RFQ_STATUS));
  }

  getPriorityFilter() {
    return this.selectedPriority = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_PRIORITY));
  }

  setSourcingPocFilter(selectedSourcingPoc) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_SOURCING_POC, JSON.stringify(selectedSourcingPoc));

  }

  getSourcingPocFilter() {
    return this.selectedSourcingPoc = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_SOURCING_POC));
  }

  setCustomerPocFilter(selectedCustomerPoc) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_CUSTOMER_POC, JSON.stringify(selectedCustomerPoc));

  }

  getCustomerPocFilter() {
    return this.selectedCustomerPoc = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_CUSTOMER_POC));
  }

  public getRegions(): Array<any> {
    return this.selectedRegions = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_REGION));
  }

  public setRegions(selectedRegions) {
      localStorage.setItem(RfqItemHelperService.LINEITEM_REGION, JSON.stringify(selectedRegions));
  }

  public setWarehouseFilter(selectedWarehouse) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_WAREHOUSE, JSON.stringify(selectedWarehouse));

  }

  public getWarehouseFilter(): Number {
    return this.selectedWarehouse = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_WAREHOUSE));
  }

  public setDateRangeFilter(selectedDateRange) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_DATE_RANGE, JSON.stringify(selectedDateRange));

  }

  public getDateRangeFilter(): Object {
    return this.selectedDateRange = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_DATE_RANGE));
  }

  public setPoStatusFilter(selectedPoStatus) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_PO_STATUS, JSON.stringify(selectedPoStatus));

  }

  public getPoStatusFilter(): Array<any> {
    return this.selectedPoStatus = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_PO_STATUS));
  }

  public setsearchFilterValue(selectedSearchValue) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_SEARCH_FILTER_VALUE, JSON.stringify(selectedSearchValue));

  }

  public getsearchFilterValue(): String {
    return this.searchFilterValue = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_SEARCH_FILTER_VALUE));
  }

  public setsearchFilterType(selectedSearchType) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_SEARCH_FILTER_TYPE, JSON.stringify(selectedSearchType));

  }

  public getsearchFilterType() {
    return this.searchFilterType = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_SEARCH_FILTER_TYPE));
  }

  public setGMStarStatusFilter(selectedGMStarStatus) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_GMSTAR_STATUS, JSON.stringify(selectedGMStarStatus));

  }

  public getGMStarStatusFilter(): Array<any> {
    return this.selectedGMStarStatus = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_GMSTAR_STATUS));
  }

  public setSearchRfqIdValue(selectedSearchValue) {
    localStorage.setItem(RfqItemHelperService.LINEITEM_SEARCH_RFQ_ID_VALUE, JSON.stringify(selectedSearchValue));

  }

  public getSearchRfqIdValue(): String {
    return this.searchRfqIdValue = JSON.parse(localStorage.getItem(RfqItemHelperService.LINEITEM_SEARCH_RFQ_ID_VALUE));
  }
  teamsListConfArc = [];
  companyListConfArc = [];
  plantListConfArc = [];
  setConfDataForArcPrice(data) {
    this.teamsListConfArc = data?.teamIds;
    this.companyListConfArc = data?.companyIds;
    this.plantListConfArc = data?.plantIds
  }

  createSheetGroupInfo: Array<any> = [
    { id: 'poDetails', class: 'dark-green', name: 'Po Details' },
  ];

  sheetGroupInfo: Array<any> = [
    { id: 'rfqData', class: 'sky-blue', name: 'RFQ Data' },
    { id: 'repeatRfq', class: 'sheet-group-repeatRfq', name: 'Repeat RFQ' },
    { id: 'pocDetails', class: 'dark-green', name: 'POC Details' },
    { id: 'itemDetails', class: 'blue', name: 'Item' },
    { id: 'category', class: 'light-green', name: 'Category' },
    { id: 'status', class: 'dark-green', name: 'Status' },
    { id: 'msnAssign', class: 'sky-blue', name: 'MSN' },
    { id: 'catalog', class: 'blue', name: 'Catalog' },
    { id: 'vendorDetails', class: 'light-green', name: 'Supplier Details' },
    { id: 'gmApprovalStatus', class: 'dark-green', name: 'CM Approval Status' },
    { id: 'ongoingTat', class: 'black', name: 'Ongoing&TAT' },
    { id: 'remarks', class: 'dark-green', name: 'Remarks' }
  ];
  cptBrandList = ["addison", "cumi", "cello", "kennedy", "festo", "philips", "schneider", "l&t", "yuken", "omron", "megha", "smc", "wonder", "lapp", "steelgrip", "pye", "lovejoy", "swastik", "lps", "luxor", "champion", "sandvik", "jk", "anabond", "legrand", "asian paints", "wd 40", "anchor", "natraj", "camlin", "skf", "exide", "deerfos", "tvs", "bosch", "castrol", "3m", "fenner", "pidilite", "taparia", "unbrako", "stanley", "miranda", "cobra", "osg", "crompton", "voltas", "totem", "addison", "mungyo", "polycab", "mobil", "dowells", "siemens", "faber-castell", "ajanta", "havells", "kangaro", "epson", "reynolds", "trident", "century", "bch", "syska", "rexnord", "fevicol", "kores", "bahco", "apsara", "d-link", "almonard", "sant", "pilot", "janatics", "crompton greaves", "hp", "merck", "transcend", "shell", "sun", "fluke", "loctite", "kirloskar", "ntn", "lenovo", "bajaj", "phoenix", "de neers", "berger", "fag", "yg1", "henkel", "autonics", "servo", "dewalt", "finolex", "jk super drive", "uniball", "lg", "lotus", "camel", "ceratizit", "groz", "bindal", "ankita", "baumer", "microtek", "hans", "oddy", "omega", "hansa", "aeromax", "araldite", "apl", "nilkamal", "halonix", "usha", "kei", "jk files", "logitech", "neeraj", "astral", "hy poxy", "flair", "add gel", "indian tools", "rorito", "fevi stik", "valvoline", "wd", "jk copier", "cp", "ashoka", "king", "solo", "deepak", "luminous", "m seal", "dulux", "black & decker", "fevi kwik", "glory", "xps", "nataraj", "larsen & toubro (l&t)", "steel grip", "legris", "seimens", "smc pneumatics", "fevistick", "anchor roma", "dneers", "dell", "hy-poxy", "wd-40", "apar", "jk sparkle", "faber castell", "locktite", "fiber castle", "bharat petroleum", "pedilite", "pheonix", "esab", "ador", "drp", "sant"];

  defaultSelectedSheetGroup = ['rfqData', 'pocDetails', 'itemDetails', 'category', 'status', 'msnAssign', 'catalog', 'vendorDetails', 'gmApprovalStatus', 'ongoingTat', 'remarks'];

  getCreateLineItemObj() {
    let lineObject = {
      poDetails: {
        title: 'PO Details',
        headingClass: 'basic-info',
        items: {
          CPN: {
            title: 'CPN',
            hide: false,
            value: null,
          },
          materialDescription: {
            title: 'Material Description',
            hide: false,
            value: null
          },
          brand: {
            title: 'Brand',
            hide: false,
            value: null
          },
          qty: {
            title: 'Qty',
            hide: false,
            value: null
          },
          uom: {
            title: 'Customer UOM',
            hide: false,
            value: null,
            inputType: 'dropdown'
          },
          hsn: {
            title: 'HSN',
            hide: false,
            value: null
          },
          tax: {
            title: 'Tax %',
            hide: false,
            value: null
          },
          spUnitPO: {
            title: 'Sp/Unit in PO',
            hide: false,
            value: null
          },
          customerDueDeliveryDate: {
            title: 'Customer Due Delivery Date',
            hide: false,
            value: null
          },
          invoiceSrNo: {
            title: 'Invoice Sr. No.',
            hide: false,
            value: null
          },
          tolerance: {
            title: 'Tolerance %',
            hide: false,
            value: null
          }
        }
      },
    };
    return lineObject;
  }

  getCreateLineItemObjForSa() {
    let lineObject = {
      poDetails: {
        title: 'PO Details',
        headingClass: 'basic-info',
        items: {
          CPN: {
            title: 'CPN',
            hide: false,
            value: null,
          },
          materialDescription: {
            title: 'Material Description',
            hide: false,
            value: null
          },
          brand: {
            title: 'Brand',
            hide: false,
            value: null
          },
          qty: {
            title: 'Qty',
            hide: false,
            value: null
          },
          uom: {
            title: 'UoM',
            hide: false,
            value: null
          },
          hsn: {
            title: 'HSN',
            hide: false,
            value: null
          },
          tax: {
            title: 'Tax %',
            hide: false,
            value: null
          },
          spUnitPO: {
            title: 'Sp/Unit in PO',
            hide: false,
            value: null
          },
        }
      },
    };
    return lineObject;
  }
  getViewLineItemsObjForRFQ() {
    let lineObject = {
      rfqData: {
        title: 'RFQ Details',
        headingClass: 'basic-info',
        items: {
          attachment: {
            title: 'Attachments',
            hide: true,
            value: null,
            disable: true
          },
          supplierQuotedCount: {
            title: 'Supplier Quoted Count',
            hide: true,
            value: null,
            disable: true
          },
          acceptedFlag: {
            title: 'Supplier Quote Accepted Flag',
            hide: true,
            value: null,
            disable: true
          },
          enquiryType: {
            title: 'Enquiry Type',
            hide: false,
            value: null,
            disable: true,
            inputType: null
          },
          teamName: {
            title: 'Team Name',
            hide: false,
            value: null,
            disable: true
          },
          plantName: {
            title: 'Plant Name',
            hide: false,
            value: null,
            disable: false,
          },
          plantId: {
            title: 'Plant ID',
            hide: false,
            value: null,
            disable: false,
          },
          rfqId: {
            title: 'RFQ ID',
            hide: false,
            value: null,
            disable: true,
          },
          productName: {
            title: 'Product Description',
            hide: false,
            value: null,
            disable: false,
          },
          rfqReceivedDate: {
            title: 'RFQ Recieved Date and Time',
            hide: false,
            value: null,
            disable: true,
          },
          customerRfqDate: {
            title: 'RFQ Date',
            hide: true,
            value: null,
            disable: true,
          },
          companyId: {
            title: 'Company Id',
            hide: true,
            value: null,
            disable: true,
          },
          companyName: {
            title: 'Company Name',
            hide: true,
            value: null,
            disable: true,
          },
          modificationDate: {
            title: 'Modification Date',
            hide: true,
            value: null,
            disable: true
          },
          msnDescription: {
            title: 'MSN Description',
            hide: true,
            value: null,
            disable: true,
          },
          teamId: {
            title: 'Team ID',
            hide: true,
            value: null,
            disable: true
          },
          warehouseId: {
            title: 'Ware House ID',
            hide: true,
            value: null,
            disable: false
          },
          supplierPaymentTermId: {
            title: 'Suggested Supplier Payment Terms Id',
            hide: true,
            value: null,
            disable: false,
          },
          defaulterSupplier: {
            title: 'defaulterSupplier',
            hide: true,
            value: null,
            disable: false
          },
          supplierPercentageReturnByValue: {
            title: 'supplierPercentageReturnByValue',
            hide: true,
            value: null,
            disable: false
          },
          netOutStandingDebitBalance: {
            title: 'netOutStandingDebitBalance',
            hide: true,
            value: null,
            disable: false
          },
          supplierDefaulterCount: {
            title: 'supplierDefaulterCount',
            hide: true,
            value: null,
            disable: false
          },
          supplierTotalGstrEligibleInvoices: {
            title: 'supplierTotalGstrEligibleInvoices',
            hide: true,
            value: null,
            disable: false
          },
          supplierPercentageGstDefaultCount: {
            title: 'supplierPercentageGstDefaultCount',
            hide: true,
            value: null,
            disable: false
          },
          supplierAmountReturnByValue: {
            title: 'supplierAmountReturnByValue',
            hide: true,
            value: null,
            disable: false
          },
          supplierBusinessNature: {
            title: 'supplierBusinessNature',
            hide: true,
            value: null,
            disable: false
          }
        }
      },
      repeatRfq: {
        title: 'Repeat RFQ',
        headingClass: 'sheet-group-repeatRfq',
        items: {
          originRfqItemId: {
            title: 'Original RFQ Item ID',
            hide: false,
            value: null,
            disable: true,
          },
          itemRepeatRfq: {
            title: 'Is Repeat RFQ',
            hide: false,
            value: null,
            disable: true,
          },
        }
      },
      pocDetails: {
        title: 'POC Details',
        headingClass: 'sheet-group-invoicevalue',
        items: {
          purchaser: {
            title: 'Purchaser',
            hide: false,
            value: null,
            disable: false,
          },
          purchaserEmail: {
            title: 'Purchaser Email',
            hide: false,
            value: null,
            disable: false,
          },
          moglixPOC: {
            title: 'Sourcing POC',
            hide: false,
            value: null,
            disable: false,
          },
          customerPoc: {
            title: 'Customer POC',
            hide: false,
            value: null,
            disable: false,
          }
        }
      },
      itemDetails: {
        title: 'Item Details',
        headingClass: 'sheet-group-itemdetails',
        items: {
          customerRfqId: {
            title: 'Customer RFQ ID',
            hide: false,
            value: null,
            disable: true
          },
          itemId: {
            title: 'RFQ ITEM ID',
            hide: false,
            value: null,
            disable: true
          },
          manualOrBotPunchedRfq: {
            title: 'Enquiry Punching Source',
            hide: false,
            value: null,
            disable: true
          },
          convertStatus: {
            title: 'Converted / Duplicate',
            hide: false,
            value: null,
            disable: true
          },
          creationDate: {
            title: 'RFQ Creation Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true
          },
          cpn: {
            title: 'CPN',
            hide: false,
            value: null,
            disable: true,
          },
          brand: {
            title: 'Brand',
            hide: false,
            value: null,
            disable: false,
          },
          productType: {
            title: 'Product Type',
            hide: false,
            value: null,
            disable: false,
          },
          arc: {
            title: 'Is ARC',
            hide: false,
            value: null,
            disable: true,
            // inputType: 'dropdown'
          },
          cpt: {
            title: 'CPT(Y/N)',
            hide: false,
            value: null,
            disable: true,
          },
          qty: {
            title: 'Quantity',
            hide: false,
            value: null,
            disable: false,
          },
          uomRfq: {
            title: 'Customer UOM',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          moq: {
            title: 'moq',
            hide: false,
            value: null,
            disable: false,
          },
          // tentativeField: {
          //   title: 'Tentative Value',
          //   hide: false,
          //   value: null,
          //   disable: true,
          // },
          priority: {
            title: 'Priority',
            hide: false,
            value: null,
            disable: true
          }
        }
      },
      category: {
        title: 'Category',
        headingClass: 'sheet-group-podata',
        items: {
          pricingCategory: {
            title: 'Pricing Category',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          l1Category: {
            title: 'L1 Category',
            hide: false,
            value: null,
            disable: false
          },
          l2Category: {
            title: 'L2 Category',
            hide: false,
            value: null,
            disable: true,
            // inputType: 'dropdown'
          },
          categoryUpdatedByAlgo: {
            title: 'Category Updated By Algo',
            hide: false,
            value: null,
            disable: true,
          }
        }
      },
      status: {
        title: 'Status',
        headingClass: 'sheet-group-invoicevalue',
        items: {
          status: {
            title: 'RFQ Status',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          clarificationNeeded: {
            title: 'Clarification Needed',
            hide: false,
            value: null,
            inputType: 'dropdown'
          },
          clarificationDetail: {
            title: 'Clarification Detail',
            hide: false,
            value: null,
            disable: false,
          },
          lastSourcedDate: {
            title: 'Last Source Date(MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
          },
          reasonForRfqLoss: {
            title: 'Reason For RFQ Loss',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
        }
      },
      msnAssign: {
        title: 'MSN',
        headingClass: 'sheet-group-postatus',
        items: {
          remark4: {
            title: 'Additional Input (Algo)',
            hide: false,
            value: null,
            disable: false,
          },
          msnSuggestions: {
            // title: 'MSN Suggestions',
            title: 'MSN Suggestions (Algo)',
            hide: false,
            value: null,
            disable: false,
            color: null,
          },
          // msn: {
          //   title: 'MSN Assign',
          //   hide: false,
          //   value: null,
          //   disable: true,
          //   color: null,
          // },
          // msnL1Category: {
          //   // title: 'MSN L1 Category',
          //   title: 'Suggested Category (Algo)',
          //   hide: false,
          //   value: null,
          //   disable: true,
          //   color: null,
          // },
          catalogMsn: {
            title: 'Suggested MSN from Catalog',
            hide: false,
            value: null,
            disable: false,
            color: null,
          },
          msnUom: {
            title: 'MSN UOM',
            hide: false,
            value: null,
            disable: true,
            color: null,
          },
          msn: {
            title: 'MSN Assign',
            hide: false,
            value: null,
            disable: true,
            color: null,
          },
          warehouseName: {
            title: 'Warehouse',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown',
            color: null,
          },
          warehouseQuantity: {
            title: 'Inventory at WH',
            hide: false,
            value: null,
            disable: true,
          }
        }
      },
      catalog: {
        title: 'Catalog',
        headingClass: 'sheet-group-itemdetails',
        items: {
          catalogPocMailId: {
            title: 'Catalog POC Email ID',
            hide: false,
            value: null,
            disable: false
          },
          clarificationFromSourcing: {
            title: 'Clarification From Sourcing',
            hide: false,
            value: null,
            disable: false
          },
          clarificationFromCatalog: {
            title: 'Clarification From Catalog',
            hide: false,
            value: null,
            disable: false
          },
          msnStatus: {
            title: 'MSN Status',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          msnclarificationClosed: {
            title: 'MSN Clarification Closed',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          additionalRemark: {
            title: 'Additional Remark',
            hide: false,
            value: null,
            disable: false
          }
        }
      },
      vendorDetails: {
        title: 'Supplier Details',
        headingClass: 'sheet-group-buyerpodata',
        items: {
          vendorName: {
            title: 'Supplier Name',
            hide: false,
            value: null,
            disable: false,
            color: null,
            supplierReturnDefaultFlag: false,
            supplierNetBalanceDefaultFlag: false,
            supplierGstDefaultFlag: false,
          },
          vendorId: {
            title: 'Supplier Id',
            hide: false,
            value: null,
            disable: true,
            color: null,
          },
          supplierPaymentTerm: {
            title: 'Suggested Supplier Payment Terms',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          listPrice: {
            title: 'Supplier Quoted Price',
            hide: false,
            value: null,
            disable: false,
          },
          discount: {
            title: 'Discount% (from supplier)',
            hide: false,
            value: null,
            disable: false,
          },
          sourcingLeadTime: {
            title: 'Sourcing Lead Time (Days)',
            hide: false,
            value: null,
            disable: false,
          },
          leadTime: {
            title: 'Lead time to Customer (Days)',
            hide: false,
            value: null,
            disable: false,
          },
          suggestedBrand: {
            title: 'Suggested Brand',
            hide: false,
            value: null,
            disable: true,
          },
          tp: {
            title: 'TP/unit',
            hide: false,
            value: null,
            disable: true,
          },
          finalTp: {
            title: 'Final TP/unit',
            hide: false,
            value: null,
            disable: false,
            color: null,
          },
          manualSp: {
            title: 'Sourcer Recommended SP/Unit',
            hide: false,
            value: null,
            disable: false,
            color: null,
            tooltip:'This field will help indicate the SP suggested by Sourcer to KAM as per market connect'
          },
          // transferCharges: {
          //   title: 'Transfer Charges',
          //   hide: false,
          //   value: null,
          //   disable: false,
          // },
          expectedGM: {
            title: 'Expected GM(%)',
            hide: false,
            value: null,
            disable: false
          },
          arcType: {
            title: 'ARC Type',
            hide: false,
            value: null,
            disable: true,
          },
          lpToCustomer: {
            title: 'List Price',
            hide: false,
            value: null,
            disable: false,
          },
          customerDiscount: {
            title: 'Discount(To Customer)',
            hide: false,
            value: null,
            disable: false,
          },
          arcDiscountPercentage: {
            title: 'ARC Discount%',
            hide: false,
            value: null,
            disable: true,
          },
          arcNetPrice: {
            title: 'ARC Net Price',
            hide: false,
            value: null,
            disable: true,
          },
          arcPrice: {
            title: 'ARC Price',
            hide: false,
            value: null,
            disable: true,
          },
          sp: {
            title: 'SP/unit',
            hide: false,
            value: null,
            disable: true,
          },
          finalSp: {
            title: 'Final SP/unit',
            hide: false,
            value: null,
            disable: false,
            color: null,
          },    
          moglixDescription: {
            // title: 'Product Details',
            title:'Suggested MSN Description (Algo)',
            hide: false,
            value: null,
            disable: false,
            color: null,
          },      
          remark: {
            title: 'eRFQ Remark',
            hide: false,
            value: null,
            disable: false,
          },
          hsn: {
            title: 'HSN',
            hide: false,
            value: null,
            disable: false,
          },
          gstPercentage: {
            title: 'GST %',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          supplierValidity: {
            title: ' Supplier Validity',
            hide: false,
            value: null,
            disable: true,
          },
          value: {
            title: 'Value(Excl GST)',
            hide: false,
            value: null,
            disable: true,
          },
          gmPercent: {
            title: 'GM %',
            hide: false,
            value: null,
            disable: true,
          },
          supplierQuotationNumbers: {
            title: 'Supplier Quotation Number',
            hide: false,
            value: null,
            disable: true,
          },
          edr: {
            title: 'Estimated Days of Response',
            hide: false,
            value: null,
            disable: false,
          },
          edq: {
            title: 'Estimated Date For Quotation(MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
          },
          deliveryType: {
            title: 'DeliveryType',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          firstQuotedDate: {
            title: 'First Quoted Date(MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          quotedDate: {
            title: 'Latest Quoted Date(MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          timePostQuote: {
            title: 'Time Post Quote',
            hide: false,
            value: null,
            disable: true
          },
          highValueRFQ: {
            title: 'High Value RFQ',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          autoQuoteToCustomerStatus: {
            title: 'Auto-Quote to Customer Status',
            hide: false,
            value: null,
            disable: true
          },
          systemQuoted: {
            title: 'System Quoted',
            hide: false,
            value: null,
            disable: true
          },
          quotationNumber: {
            title: 'Quotation Number',
            hide: false,
            value: null,
            disable: true
          }
        }
      },
      gmApprovalStatus: {
        title: 'CM Approval Status',
        headingClass: 'sheet-group-invoicevalue',
        items: {
          gmStarRfq: {
            title: 'RFQ GM*',
            hide: false,
            value: null,
            disable: true
          },
          gmStar: {
            title: 'Item GM*',
            hide: false,
            value: null,
            disable: true
          },
          scmCost: {
            title: 'SCM Cost',
            hide: false,
            value: null,
            disable: true,
          },
          cmStar: {
            title: 'Item CM*',
            hide: false,
            value: null,
            disable: true,
          },
          cmStarRfq: {
            title: 'RFQ CM*',
            hide: false,
            value: null,
            disable: true,
          },
          gmApprovalStatus: {
            title: 'CM* Approval status',
            hide: false,
            value: null,
            disable: true
          },
          rejectedFrom: {
            title: 'Rejected From',
            hide: false,
            value: null,
            disable: true
          },
          approvedFrom: {
            title: 'Approved From',
            hide: false,
            value: null,
            disable: true
          },
          gmApprovalRemark: {
            title: 'Remark',
            hide: false,
            value: null,
            disable: true
          },
          paymentTerm: {
            title: 'Transactional Customer Payment Terms',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          actualPaymentTerms: {
            title: 'Contractual Customer Payment Term',
            hide: false,
            value: null,
            disable: true, 
          },
        }
      },
      ongoingTat: {
        title: 'Ongoing & TAT',
        headingClass: 'sheet-group-catalog',
        items: {
          tat: {
            title: 'Recieved Date to Quoted Date TAT (Days)',
            hide: false,
            value: null,
            disable: true
          },
          sourcerTAT: {
            title: 'Sourcer TAT (Hour)',
            hide: false,
            value: null,
            disable: true
          },
          kamTAT: {
            title: 'KAM TAT - Before Approval (Hour)',
            hide: false,
            value: null,
            disable: true
          },
          kamTatNew: {
            title: 'KAM TAT - After Approval (Hour)',
            hide: false,
            value: null,
            disable: true
          },
          reqForGlobalMsn: {
            title: 'Req For Global MSN',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          ageing: {
            title: 'Ageing',
            hide: false,
            value: null,
            disable: true
          },
          remarkForPOLostRegret: {
            title: 'Remark For PO Lost/Regret',
            hide: false,
            value: null,
            disable: false,
          },
          poWonByVendor: {
            title: 'PO won by Supplier',
            hide: false,
            value: null,
            disable: false,
          },
          tpByCustomer: {
            title: 'TP by customer',
            hide: false,
            value: null,
            disable: false,
          },
          moglixVendorRegistered: {
            title: 'Moglix Supplier registered?',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          priceValidityDate: {
            title: 'Price Validity Date(MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
          },
          rfqClosureDate: {
            title: 'RFQ Closure Date(MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
          }
        }
      },
      remarks: {
        title: 'Remarks',
        headingClass: 'sheet-group-invoicevalue',
        items: {
          remark1: {
            title: 'Remark1',
            hide: false,
            value: null,
            disable: false,
          },
          remark2: {
            title: 'Remark2',
            hide: false,
            value: null,
            disable: false,
          },
          remark3: {
            title: 'Remark3',
            hide: false,
            value: null,
            disable: false,
          },
          // remark4: {
          //   title: 'Remark4',
          //   hide: false,
          //   value: null,
          //   disable: false,
          // }
        }
      }
    };
    return lineObject;
  }

  getRealIndexOfSheetColumn(colId: String, activeSheet: String) {
    let itemSheetObject;
    if (activeSheet == PoService.PO_ACTION_SUPPLIER_SHEET) {
      itemSheetObject = this.suppliersSheetHelperService.getViewLineItemsObj();
    } else if (activeSheet == PoService.PO_ACTION_CATALOG_SHEET) {
      itemSheetObject = this.catalogSheetHelperService.getViewLineItemsObjForCatalog();
    } else if (activeSheet == PoService.PO_ACTION_OMT_SHEET) {
      itemSheetObject = this.omtSheetHelperService.getViewLineItemsObjForOmt();
    } else if (activeSheet == PoService.PO_ACTION_SA_SHEET) {
      itemSheetObject = this.saSheetHelperService.getViewLineItemsObjForSa();
    } else if (activeSheet == PoService.PO_ACTION_RFQ_SHEET) {
      itemSheetObject = this.getViewLineItemsObjForRFQ();
    }
    let index = 0;
    let iscolFound = false;
    Object.keys(itemSheetObject).some((key) => {
      Object.keys(itemSheetObject[key].items).some((colKey) => {
        if (colKey === colId) {
          iscolFound = true;
          return true;
        }
        index++;
      });
      if (iscolFound) {
        return true;
      }
    });
    return index;
  }

  getDefaultData(Action: string) {
    let lineItemObj;
    if (Action === PoService.PO_ACTION_RFQ_SHEET) {
      lineItemObj = this.getViewLineItemsObjForRFQ();
    } else {
      if (Action == SaService.ACTION_CREATE || Action == SaService.ACTION_VIEW || Action == SaService.ACTION_EDIT) {
        lineItemObj = this.getCreateLineItemObjForSa();
      } else {
        lineItemObj = this.getCreateLineItemObj();
      }
    }
    let data = [];
    for (let i = 0; i < 50; i++) {
      data[i] = [];
      for (const dsHeadKey of Object.keys(lineItemObj)) {
        let title = lineItemObj[dsHeadKey]['title'];
        for (const key of Object.keys(lineItemObj[dsHeadKey]['items'])) {
          let itemTitle = lineItemObj[dsHeadKey]['items'][key]['title'];
          let itemValue = lineItemObj[dsHeadKey]['items'][key]['value'];
          let hide = lineItemObj[dsHeadKey]['items'][key]['hide'];
          let inputType = (lineItemObj[dsHeadKey]['items'][key]['inputType']) ? lineItemObj[dsHeadKey]['items'][key]['inputType'] : '';
          if (!hide) {
            data[i].push({
              value: itemValue, priority: 1, parent: { key: itemTitle, name: dsHeadKey },
              id: key, hide: hide, validation: [{ type: 'string' }], disable: false, inputType: inputType
            });
          }
        }
      }
    }
    return data;
  }

  getHeaders(selectedHeaders: Array<String>, Action: String) {
    let nestedHeaders = [[], [], []];
    let isFirstHeading: boolean = true;
    let colSpanSize;
    let lineItemObj;
    if (Action === PoService.PO_ACTION_SUPPLIER_SHEET) {
      lineItemObj = this.suppliersSheetHelperService.getViewLineItemsObj();
      nestedHeaders[2].push({ totalStickyItems: Object.keys(lineItemObj.rfqData.items).length });
    } else if (Action === PoService.PO_ACTION_CATALOG_SHEET) {
      lineItemObj = this.catalogSheetHelperService.getViewLineItemsObjForCatalog();
      nestedHeaders[2].push({ totalStickyItems: Object.keys(lineItemObj.rfqData.items).length });
    } else if (Action === PoService.PO_ACTION_OMT_SHEET) {
      lineItemObj = this.omtSheetHelperService.getViewLineItemsObjForOmt();
      nestedHeaders[2].push({ totalStickyItems: Object.keys(lineItemObj.rfqData.items).length });
    } else if (Action === PoService.PO_ACTION_SA_SHEET) {
      lineItemObj = this.saSheetHelperService.getViewLineItemsObjForSa();
      nestedHeaders[2].push({ totalStickyItems: Object.keys(lineItemObj.rfqData.items).length });
    }
    else if (Action === PoService.PO_ACTION_RFQ_SHEET) {
      lineItemObj = this.getViewLineItemsObjForRFQ();
      nestedHeaders[2].push({ totalStickyItems: Object.keys(lineItemObj.rfqData.items).length });
    }
    else {
      if (Action === PoService.PO_ACTION_PO_VIEW || Action === PoService.PO_ACTION_VIEW_MULTIPLE) {
        lineItemObj = this.getViewLineItemsObjForRFQ();
        nestedHeaders[2].push({ totalStickyItems: Object.keys(lineItemObj.rfqData.items).length });
      } else {
        if (Action == SaService.ACTION_CREATE || Action == SaService.ACTION_VIEW || Action == SaService.ACTION_EDIT) {
          // lineItemObj = this.getCreateLineItemObjForSa();
        } else {
          // lineItemObj = this.getCreateLineItemObj();
        }
        nestedHeaders[2].push({ totalStickyItems: Object.keys(lineItemObj.rfqData.items).length });
      }
    }
    for (const dsHeadKey of Object.keys(lineItemObj)) {

      if (selectedHeaders.includes(dsHeadKey) === false) {
        continue;
      }

      let title = lineItemObj[dsHeadKey]['title'];
      let headingClass = lineItemObj[dsHeadKey]['headingClass'];
      if (isFirstHeading) {
        colSpanSize = 0;
        for (const key of Object.keys(lineItemObj[dsHeadKey]['items'])) {
          if (!lineItemObj[dsHeadKey]['items'][key]['hide']) {
            colSpanSize++;
          }
        }
        isFirstHeading = false;
      } else {
        colSpanSize = Object.keys(lineItemObj[dsHeadKey]['items']).length;
      }
      nestedHeaders[0].push({
        'label': title, 'headingClass': headingClass,
        'colspan': colSpanSize
      });
      for (const key of Object.keys(lineItemObj[dsHeadKey]['items'])) {
        let itemTitle = lineItemObj[dsHeadKey]['items'][key]['title'];
        let tooltip = lineItemObj[dsHeadKey]['items'][key]['tooltip'];
        if (!lineItemObj[dsHeadKey]['items'][key]['hide'] && lineItemObj[dsHeadKey]['title'] == 'PO Details') {
          nestedHeaders[1].push({ key: itemTitle, id: key, mandatory: false,  tooltip: tooltip });
        }
        else if (!lineItemObj[dsHeadKey]['items'][key]['hide'] && lineItemObj[dsHeadKey]['title'] != 'PO Details') {
          nestedHeaders[1].push({ key: itemTitle, id: key, mandatory: false,  tooltip: tooltip });
        }
      }
    }
    return nestedHeaders;
  }

  /**
   * Convert the product selected from in field auto poulation in standard format
   * for punch PO
   *
   * @param data
   *
   * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
   */
  buildPurchaseOrderItemData(data, index) {
    let punchPoData = this.poService.getPunchPoData();
    const dataObject = this.convertSheetValueToObject(data);
    let validatedProduct = this.isValidatedProduct(dataObject, index);
    if (validatedProduct) {
      punchPoData.items[index].quantity.qty = dataObject.qty;
      punchPoData.items[index].rate.amount = dataObject.rate;
      punchPoData.items[index].delivery.customerDueDeliveryDate = dataObject.customerDueDeliveryDate;
      punchPoData.items[index].invoiceSrNo = dataObject.invoiceSrNo;
      if (!this.validationService.checkEmptyOrNull(punchPoData.items[index]['extraDetails'])) {
        punchPoData.items[index]['extraDetails']['tolerance'] = dataObject.tolerance
      }
      else {
        punchPoData.items[index]['extraDetails'] = {};
        punchPoData.items[index]['extraDetails']['tolerance'] = dataObject.tolerance
      }
      return { item: punchPoData.items[index] };
    }
    let product = this.makeDataSheetProductModel(dataObject, punchPoData.buyer._id,
      punchPoData.group, punchPoData.createdBy, punchPoData.createdByName);
    let tax = this.getItemTaxInfo(dataObject.tax);
    let currency = this.buyerService.getPlantData().branch ? this.buyerService.getPlantData().branch.currency : 'INR';
    return {
      item: new PuchaseOrderItemModel(null, punchPoData.buyer._id, product, dataObject.qty,
        product.uom, dataObject.rate, currency, tax, null, dataObject.customerDueDeliveryDate, dataObject.invoiceSrNo, dataObject.tolerance), index: index
    }
  }

  public isValidatedProduct(data, index) {
    let punchPoData = this.poService.getPunchPoData();
    if (punchPoData.items[index] && punchPoData.items[index].product
      && punchPoData.items[index].product._id) {
      let product = punchPoData.items[index].product;
      if (data.cpn === product.cpn && data.name === product.name && data.brand === product.brand.displayName && data.uom === product.uom && data.hsn === product.hsnCode) {
        return true;
      }
    }
    return false;
  }

  public getItemTaxInfo(taxValue) {
    let punchPoData = this.poService.getPunchPoData();
    let plantData = this.buyerService.getPlantData();
    let taxType: string;
    if (!this.checkEmptyOrNull(punchPoData.buyer.gstn) && !this.checkEmptyOrNull(punchPoData.vendor.gstn)) {
      // const buyerIsSez = plantData['branch']['branchLang']['sez'];
      // if (buyerIsSez) {
      //   taxType = 'No Tax';
      //   taxValue = 0;
      // } else {
      if (punchPoData.sapDetails.isGovtPo) {
        taxValue = 12;
      }
      let sellerGstStateCode = punchPoData.vendor.gstn.substring(0, 2);
      let buyerGstStateCode = punchPoData.buyer.gstn.substring(0, 2);
      if (sellerGstStateCode === buyerGstStateCode) {
        taxType = 'CGST/SGST';
      } else {
        taxType = 'IGST';
      }
      // }
    }
    return (new Tax(taxType, 'PERCENTAGE', taxValue));
  }

  public formatHsn(hsn: String) {
    const replacedValue = hsn ? hsn.replace(/ /g, '').trim() : '';
    return replacedValue;
  }

  public checkEmptyOrNull(value) {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
  }

  makeDataSheetProductModel(dataObject, plantId, companyId, createdBy, createdByName) {
    const brand = new BrandModel(dataObject.brand, dataObject.brand);
    return new ProductModel(null, dataObject.cpn, dataObject.name, plantId, companyId, brand,
      dataObject.uom, dataObject.hsn, dataObject.rate, createdBy, createdByName);
  }

  convertSheetValueToObject(data) {
    let poDetailsObject = {
      _id: null, cpn: null, name: null, brand: null, qty: null, uom: null, hsn: null,
      tax: null, rate: null, customerDueDeliveryDate: null, invoiceSrNo: null, tolerance: null
    };
    data.forEach(element => {

      switch (element.id) {
        case 'CPN':
          poDetailsObject.cpn = element.value;
          break;
        case 'materialDescription':
          poDetailsObject.name = element.value;
          break;
        case 'brand':
          poDetailsObject.brand = element.value;
          break;
        case 'qty':
          poDetailsObject.qty = Number(String(element.value).replace(/,/g, ''));
          break;
        case 'uom':
          poDetailsObject.uom = element.value;
          break;
        case 'hsn':
          poDetailsObject.hsn = element.value;
          break;
        case 'tax':
          poDetailsObject.tax = element.value;
          break;
        case 'spUnitPO':
          poDetailsObject.rate = Number(String(element.value).replace(/,/g, ''));
          break;
        case 'customerDueDeliveryDate':
          poDetailsObject.customerDueDeliveryDate = Number(String(moment(element.value, 'MM/DD/YYYY', true).valueOf()));
          break;
        case 'invoiceSrNo':
          poDetailsObject.invoiceSrNo = element.value;
          break;
        case 'tolerance':
          poDetailsObject.tolerance = element.value;
          break;
      }
    });

    return poDetailsObject;
  }

  convertSheetValueToObjectV3(data) {
    if (data) {
      let rfqItemSheetModel = new rfqSheetModel();
      data.forEach(element => {
        let key = element.id;
        if (element.id) {
          rfqItemSheetModel[key] = element.value;
        }
      });
      this.wholeItemSheetRowObj = rfqItemSheetModel;
      return rfqItemSheetModel;
    }
  }

  getFieldValue(data: Object, field: String) {
    return lodash.has(data, field) ? data[`${field}`] : ''
  }

  assignFieldValueForEmpty(data: Object, field: String) {
    let value = "";
    if (field == 'status') {
      value = !this.checkEmptyOrNull(data[`${field}`]) ? data[`${field}`] : 'Created';
    }
    return value;
  }

  makeItemsData(data) {
    const poDataSet: any[] = [];
    const dataIndex: Object = {};
    let sourcingPocArray = [];
    let sheetDataIndex = -1;
    for (let i = 0; i < data.length; i++) {
      const lineItemObject = this.getViewLineItemsObjForRFQ();
      const itemData = data[i];
      let plantName = this.getFieldValue(itemData, 'branchName');
      if (lodash.has(itemData, 'branchId') && this.checkEmptyOrNull(plantName)) {
        plantName = this.authService.getPlantNameFromId(itemData.branchId);
      }
      sheetDataIndex = sheetDataIndex + 1;
      dataIndex[itemData.id] = sheetDataIndex;

      lineItemObject.rfqData.items.plantName.value = plantName;
      lineItemObject.rfqData.items.plantName.disable = this.getFieldValue(itemData, 'originalRfqItemId');
      lineItemObject.rfqData.items.plantId.value = this.getFieldValue(itemData, 'branchId');
      lineItemObject.rfqData.items.plantId.disable = this.getFieldValue(itemData, 'originalRfqItemId');
      lineItemObject.pocDetails.items.purchaser.value = this.getFieldValue(itemData, 'purchaser');
      lineItemObject.pocDetails.items.purchaserEmail.value = this.getFieldValue(itemData, 'purchaserEmail');
      lineItemObject.pocDetails.items.moglixPOC.value = this.getFieldValue(itemData, 'moglixPOC');
      lineItemObject.pocDetails.items.customerPoc.value = this.getFieldValue(itemData, 'customerPoc');
      lineItemObject.itemDetails.items.productType.value = this.getFieldValue(itemData, 'productType');
      lineItemObject.ongoingTat.items.moglixVendorRegistered.value = this.getFieldValue(itemData, 'moglixVendorRegistered');
      lineItemObject.ongoingTat.items.poWonByVendor.value = this.getFieldValue(itemData, 'poWonByVendor');
      lineItemObject.ongoingTat.items.remarkForPOLostRegret.value = this.getFieldValue(itemData, 'remarkForPOLostRegret');
      lineItemObject.ongoingTat.items.tpByCustomer.value = this.getFieldValue(itemData, 'tpByCustomer');
      lineItemObject.rfqData.items.rfqReceivedDate.value = this.validationService.formatDateandTime(this.getFieldValue(itemData, 'rfqReceivedDate'));
      lineItemObject.vendorDetails.items.edr.value = this.getFieldValue(itemData, 'edr');
      lineItemObject.vendorDetails.items.finalTp.value = this.getFieldValue(itemData, 'finalTp');
      lineItemObject.vendorDetails.items.manualSp.value = this.getFieldValue(itemData, 'manualSp');
      lineItemObject.rfqData.items.rfqId.value = this.getFieldValue(itemData, 'rfqIdNew');
      lineItemObject.itemDetails.items.arc.value = this.getFieldValue(itemData, 'arc');
      lineItemObject.itemDetails.items.brand.value = this.getFieldValue(itemData, 'brand');
      lineItemObject.itemDetails.items.cpt.value = this.getFieldValue(itemData, 'cpt');
      if (lodash.has(itemData, 'brand')) {
        if (this.cptBrandList.includes(String(itemData.brand).toLowerCase())) {
          lineItemObject.itemDetails.items.cpt.value = 'Y';
        }
        else {
          lineItemObject.itemDetails.items.cpt.value = 'N';
        }
      }
      lineItemObject.vendorDetails.items.timePostQuote.value = this.getFieldValue(itemData, 'timePostQuote');
      lineItemObject.vendorDetails.items.gmPercent.value = this.getFieldValue(itemData, 'gmPercentage');
      lineItemObject.itemDetails.items.cpn.value = this.getFieldValue(itemData, 'cpn');
      lineItemObject.rfqData.items.productName.value = this.getFieldValue(itemData, 'productName');
      lineItemObject.rfqData.items.productName.disable = this.getFieldValue(itemData, 'originalRfqItemId');
      // lineItemObject.itemDetails.items.itemId.value = this.getFieldValue(itemData, 'itemId');
      lineItemObject.itemDetails.items.qty.value = this.getFieldValue(itemData, 'qty');
      lineItemObject.category.items.l1Category.value = this.getFieldValue(itemData, 'l1Category');
      lineItemObject.category.items.l2Category.value = this.getFieldValue(itemData, 'l2Category');
      lineItemObject.category.items.categoryUpdatedByAlgo.value = this.getFieldValue(itemData, 'categoryUpdatedByAlgo');
      lineItemObject.category.items.pricingCategory.value = this.getFieldValue(itemData, 'pricingCategory');
      lineItemObject.status.items.clarificationDetail.value = this.getFieldValue(itemData, 'clarificationDetail');
      lineItemObject.status.items.clarificationNeeded.value = this.getFieldValue(itemData, 'clarificationNeeded');
      lineItemObject.status.items.lastSourcedDate.value = this.validationService.formatDate(this.getFieldValue(itemData, 'lastSourcedDate'));
      lineItemObject.status.items.status.value = this.assignFieldValueForEmpty(itemData, 'status');
      if (lineItemObject.status.items.status.value === PARAMS.STATIC_PARAMS.RFQ_STATUS_PO_WON) {
        lineItemObject.status.items.status.disable = true;
        lineItemObject.status.items.status.inputType = "";
      }
      lineItemObject.msnAssign.items.msn.value = this.getFieldValue(itemData, 'msn');
      lineItemObject.vendorDetails.items.moglixDescription.value = this.getFieldValue(itemData, 'moglixDescription');
      lineItemObject.vendorDetails.items.gstPercentage.value = this.getFieldValue(itemData, 'gstPercentage');
      lineItemObject.vendorDetails.items.leadTime.value = this.getFieldValue(itemData, 'leadTime');
      lineItemObject.vendorDetails.items.sp.value = this.getFieldValue(itemData, 'sp');
      lineItemObject.vendorDetails.items.tp.value = this.getFieldValue(itemData, 'tp');
      lineItemObject.vendorDetails.items.vendorName.value = this.getFieldValue(itemData, 'vendorName');
      lineItemObject.vendorDetails.items.vendorName.supplierReturnDefaultFlag = this.getFieldValue(itemData, 'supplierReturnDefaultFlag');
      lineItemObject.vendorDetails.items.vendorName.supplierNetBalanceDefaultFlag = this.getFieldValue(itemData, 'supplierNetBalanceDefaultFlag');
      lineItemObject.vendorDetails.items.vendorName.supplierGstDefaultFlag = this.getFieldValue(itemData, 'supplierGstDefaultFlag');
      lineItemObject.vendorDetails.items.listPrice.value = this.getFieldValue(itemData, 'listPrice');
      lineItemObject.ongoingTat.items.ageing.value = this.getFieldValue(itemData, 'ageing');
      lineItemObject.gmApprovalStatus.items.paymentTerm.value = this.getFieldValue(itemData, 'paymentTerm');
      lineItemObject.gmApprovalStatus.items.actualPaymentTerms.value = this.getFieldValue(itemData, 'actualPaymentTerms');
      lineItemObject.vendorDetails.items.remark.value = this.getFieldValue(itemData, 'remark');
      lineItemObject.ongoingTat.items.reqForGlobalMsn.value = this.getFieldValue(itemData, 'reqForGlobalMsn');
      lineItemObject.ongoingTat.items.tat.value = this.getFieldValue(itemData, 'tat');
      lineItemObject.ongoingTat.items.sourcerTAT.value = this.getFieldValue(itemData, 'sourcerTAT');
      lineItemObject.ongoingTat.items.kamTAT.value = this.getFieldValue(itemData, 'kamTAT');
      lineItemObject.vendorDetails.items.quotedDate.value = this.validationService.formatDateandTime(this.getFieldValue(itemData, 'quotedDate'));
      lineItemObject.vendorDetails.items.deliveryType.value = this.getFieldValue(itemData, 'deliveryType');
      lineItemObject.itemDetails.items.uomRfq.value = this.getFieldValue(itemData, 'uom');
      lineItemObject.itemDetails.items.itemId.value = this.getFieldValue(itemData, 'id');
      lineItemObject.itemDetails.items.manualOrBotPunchedRfq.value = this.getFieldValue(itemData, 'manualOrBotPunchedRfq')
      lineItemObject.rfqData.items.customerRfqDate.value = this.getFieldValue(itemData, 'customerRfqDate');
      lineItemObject.rfqData.items.companyId.value = this.getFieldValue(itemData, 'companyId');
      lineItemObject.rfqData.items.companyName.value = this.getFieldValue(itemData, 'companyName');
      lineItemObject.rfqData.items.modificationDate.value = this.getFieldValue(itemData, 'modificationDate');
      lineItemObject.rfqData.items.enquiryType.value = this.getFieldValue(itemData, 'enquiryType');
      // if (this.assignFieldValueForEmpty(itemData, 'status')!=PARAMS.STATIC_PARAMS.RFQ_STATUS_PO_WON && this.userRoleList.includes(PARAMS.STATIC_PARAMS.CDD2_ENABLE_ROLE)) {
      //   lineItemObject.rfqData.items.enquiryType.disable = false;
      //   lineItemObject.rfqData.items.enquiryType.inputType = 'dropdown';
      // }
      if((this.authService.getUserEmail() == this.getFieldValue(itemData, 'moglixPOC')) || (this.authService.getUserEmail() == this.getFieldValue(itemData, 'customerPoc'))) {
        lineItemObject.rfqData.items.enquiryType.disable = false;
        lineItemObject.rfqData.items.enquiryType.inputType = 'dropdown';
      }
      if(this.getFieldValue(itemData, 'arc') == 'Y' && this.getFieldValue(itemData, 'enquiryType') == 'ARC') {
        lineItemObject.rfqData.items.enquiryType.disable = true;
        lineItemObject.rfqData.items.enquiryType.inputType = null;
      }
      lineItemObject.rfqData.items.teamName.value = this.getFieldValue(itemData, 'teamName');
      lineItemObject.itemDetails.items.moq.value = this.getFieldValue(itemData, 'moq');
      lineItemObject.vendorDetails.items.edq.value = this.validationService.formatDate(this.getFieldValue(itemData, 'edq'));
      lineItemObject.vendorDetails.items.hsn.value = this.getFieldValue(itemData, 'hsn');
      lineItemObject.vendorDetails.items.value.value = this.getFieldValue(itemData, 'totalValue');
      lineItemObject.vendorDetails.items.discount.value = this.getFieldValue(itemData, 'discount');
      lineItemObject.remarks.items.remark1.value = this.getFieldValue(itemData, 'remark1');
      lineItemObject.remarks.items.remark2.value = this.getFieldValue(itemData, 'remark2');
      lineItemObject.remarks.items.remark3.value = this.getFieldValue(itemData, 'remark3');
      // lineItemObject.remarks.items.remark4.value = this.getFieldValue(itemData, 'remark4');
      lineItemObject.msnAssign.items.remark4.value = this.getFieldValue(itemData, 'remark4');
      lineItemObject.vendorDetails.items.finalSp.value = this.getFieldValue(itemData, 'finalSp');
      lineItemObject.vendorDetails.items.arcPrice.value = this.getFieldValue(itemData, 'arcPrice');
      lineItemObject.vendorDetails.items.arcNetPrice.value = this.getFieldValue(itemData, 'arcNetPrice');
      lineItemObject.vendorDetails.items.arcType.value = this.getFieldValue(itemData, 'arcType');
      lineItemObject.vendorDetails.items.arcDiscountPercentage.value = this.getFieldValue(itemData, 'arcDiscountPercentage');
      lineItemObject.vendorDetails.items.arcDiscountPercentage.disable = this.getFieldValue(itemData, 'arcType') !== "LP-Discount";
      lineItemObject.rfqData.items.msnDescription.value = this.getFieldValue(itemData, 'msnDescription');
      lineItemObject.vendorDetails.items.customerDiscount.value = this.getFieldValue(itemData, 'customerDiscount');
      lineItemObject.vendorDetails.items.customerDiscount.disable = this.getFieldValue(itemData, 'enquiryType') == PARAMS.STATIC_PARAMS.PO_TYPE_ARC;
      lineItemObject.ongoingTat.items.priceValidityDate.value = this.validationService.formatDate(this.getFieldValue(itemData, 'priceValidityDate'));
      lineItemObject.rfqData.items.attachment.value = this.addAttachments(this.getFieldValue(itemData, 'attachedFiles'));
      lineItemObject.rfqData.items.supplierQuotedCount.value = this.getFieldValue(itemData, 'supplierQuotedCount');
      lineItemObject.ongoingTat.items.rfqClosureDate.value = this.validationService.formatDate(this.getFieldValue(itemData, 'rfqClosureDate'));
      lineItemObject.rfqData.items.teamId.value = this.getFieldValue(itemData, 'teamId');
      lineItemObject.vendorDetails.items.lpToCustomer.value = this.getFieldValue(itemData, 'lpToCustomer');
      lineItemObject.vendorDetails.items.lpToCustomer.disable = this.getFieldValue(itemData, 'arcType') == 'Net Price';
      lineItemObject.itemDetails.items.creationDate.value = this.validationService.formatDate(this.getFieldValue(itemData, 'creationDate'));
      lineItemObject.vendorDetails.items.expectedGM.value = this.getFieldValue(itemData, 'expectedGM');
      lineItemObject.vendorDetails.items.highValueRFQ.value = this.getFieldValue(itemData, 'highValueRFQ');
      lineItemObject.vendorDetails.items.autoQuoteToCustomerStatus.value = this.getFieldValue(itemData, 'autoQuoteToCustomerStatus');
      lineItemObject.vendorDetails.items.systemQuoted.value = this.getFieldValue(itemData, 'systemQuoted');
      lineItemObject.vendorDetails.items.quotationNumber.value = this.getFieldValue(itemData, 'rfqQuoteId');
      lineItemObject.catalog.items.clarificationFromSourcing.value = this.getFieldValue(itemData, 'clarificationFromSourcing');
      lineItemObject.catalog.items.clarificationFromCatalog.value = this.getFieldValue(itemData, 'clarificationFromCatalog');
      lineItemObject.catalog.items.msnStatus.value = this.getFieldValue(itemData, 'msnStatus');
      lineItemObject.catalog.items.additionalRemark.value = this.getFieldValue(itemData, 'additionalRemark');
      lineItemObject.msnAssign.items.warehouseName.value = this.getFieldValue(itemData, 'warehouseName');
      lineItemObject.msnAssign.items.warehouseQuantity.value = this.getFieldValue(itemData, 'warehouseQuantity');
      lineItemObject.msnAssign.items.catalogMsn.value = this.getFieldValue(itemData, 'catalogMsn');
      lineItemObject.msnAssign.items.msnUom.value = this.getFieldValue(itemData, 'msnUom');
      lineItemObject.rfqData.items.warehouseId.value = this.getFieldValue(itemData, 'warehouseId');
      lineItemObject.rfqData.items.supplierPaymentTermId.value = this.getFieldValue(itemData, 'supplierPaymentTermId');
      lineItemObject.catalog.items.msnclarificationClosed.value = this.getFieldValue(itemData, 'msnclarificationClosed');
      // lineItemObject.itemDetails.items.originalRfqItemId.value = this.getFieldValue(itemData, 'originalRfqItemId');
      lineItemObject.itemDetails.items.convertStatus.value = this.getFieldValue(itemData, 'convertStatus');
      //lineItemObject.itemDetails.items.tentativeField.value = this.getFieldValue(itemData, 'tentativeField');
      lineItemObject.itemDetails.items.priority.value = this.getFieldValue(itemData, 'priority');
      lineItemObject.catalog.items.catalogPocMailId.value = this.getFieldValue(itemData, 'catalogPocMailId');
      lineItemObject.vendorDetails.items.sourcingLeadTime.value = this.getFieldValue(itemData, 'sourcingLeadTime');
      lineItemObject.vendorDetails.items.vendorId.value = this.getFieldValue(itemData, 'vendorId');
      lineItemObject.vendorDetails.items.firstQuotedDate.value = this.validationService.formatDateandTime(this.getFieldValue(itemData, 'firstQuotedDate'));
      lineItemObject.status.items.reasonForRfqLoss.value = this.getFieldValue(itemData, 'reasonForRfqLoss');
      lineItemObject.status.items.reasonForRfqLoss.value = this.getFieldValue(itemData, 'reasonForRfqLoss');
      lineItemObject.vendorDetails.items.supplierValidity.value = this.getFieldValue(itemData, 'supplierValidity');
      lineItemObject.msnAssign.items.msnSuggestions.value = this.getFieldValue(itemData, 'msnSuggestions');
      //lineItemObject.msnAssign.items.msnL1Category.value = this.getFieldValue(itemData, 'msnL1Category');
      lineItemObject.vendorDetails.items.suggestedBrand.value = this.getFieldValue(itemData, 'suggestedBrand');
      lineItemObject.vendorDetails.items.supplierPaymentTerm.value = this.getFieldValue(itemData, 'supplierPaymentTerm');
      lineItemObject.vendorDetails.items.supplierPaymentTerm.disable = !this.validationService.checkEmptyOrNull(this.getFieldValue(itemData, 'vendorId'));
     // lineItemObject.vendorDetails.items.transferCharges.value = this.getFieldValue(itemData, 'transferCharges');
      lineItemObject.gmApprovalStatus.items.gmStarRfq.value = this.getFieldValue(itemData, 'gmStarRfq');
      lineItemObject.gmApprovalStatus.items.gmStar.value = this.getFieldValue(itemData, 'gmStar');
      lineItemObject.gmApprovalStatus.items.scmCost.value = this.getFieldValue(itemData, 'scmCost');
      lineItemObject.gmApprovalStatus.items.cmStar.value = this.getFieldValue(itemData, 'cmStar');
      lineItemObject.gmApprovalStatus.items.cmStarRfq.value = this.getFieldValue(itemData, 'cmStarRfq');
      lineItemObject.gmApprovalStatus.items.gmApprovalStatus.value = this.getFieldValue(itemData, 'gmApprovalStatus');
      lineItemObject.gmApprovalStatus.items.rejectedFrom.value = this.getFieldValue(itemData, 'rejectedFrom');
      lineItemObject.gmApprovalStatus.items.approvedFrom.value = this.getFieldValue(itemData, 'approvedFrom');
      lineItemObject.gmApprovalStatus.items.gmApprovalRemark.value = this.getFieldValue(itemData, 'gmApprovalRemark');
      lineItemObject.vendorDetails.items.supplierQuotationNumbers.value = this.getFieldValue(itemData, 'supplierQuotationNumbers');
      lineItemObject.rfqData.items.acceptedFlag.value = this.getFieldValue(itemData, 'acceptedFlag');
      lineItemObject.ongoingTat.items.kamTatNew.value = this.getFieldValue(itemData, 'kamTatNew');
      //--------------   Repeat RFQ   --------------------------////
      lineItemObject.repeatRfq.items.originRfqItemId.value = this.getFieldValue(itemData, 'originalRfqItemId');
      lineItemObject.repeatRfq.items.itemRepeatRfq.value = this.getFieldValue(itemData, 'repeatedRfq');
      // hidden fields for supplier name 
      lineItemObject.rfqData.items.defaulterSupplier.value = this.getFieldValue(itemData, 'defaulterSupplier');
      lineItemObject.rfqData.items.supplierPercentageReturnByValue.value = this.getFieldValue(itemData, 'supplierPercentageReturnByValue');
      lineItemObject.rfqData.items.netOutStandingDebitBalance.value = this.getFieldValue(itemData, 'netOutStandingDebitBalance');
      lineItemObject.rfqData.items.supplierDefaulterCount.value = this.getFieldValue(itemData, 'supplierDefaulterCount');
      lineItemObject.rfqData.items.supplierTotalGstrEligibleInvoices.value = this.getFieldValue(itemData, 'supplierTotalGstrEligibleInvoices');
      lineItemObject.rfqData.items.supplierPercentageGstDefaultCount.value = this.getFieldValue(itemData, 'supplierPercentageGstDefaultCount');
      lineItemObject.rfqData.items.supplierAmountReturnByValue.value = this.getFieldValue(itemData, 'supplierAmountReturnByValue');
      lineItemObject.rfqData.items.supplierBusinessNature.value = this.getFieldValue(itemData, 'supplierBusinessNature');

      

      // lineItemObject.ongoingTat.items.approverTat.value = this.getFieldValue(itemData, 'approverTat');
      lineItemObject.itemDetails.items.customerRfqId.value = this.getFieldValue(itemData, 'customerRfqId');
      //  this.getFieldValue(itemData, 'attachment');
      // lineItemObject.rfqData.items.attachments.link = this.addAttachments(this.getFieldValue(itemData, 'attachments'), 'link');
      // if (this.checkEmptyOrNull(itemData.branchId)) {
      //   lineItemObject.rfqData.items.plantId.disable = false;
      //   lineItemObject.rfqData.items.plantName.disable = false;
      // }
      if (this.getFieldValue(itemData, 'gmApprovalStatus') && (this.getFieldValue(itemData, 'gmApprovalStatus').includes("Pending") || this.getFieldValue(itemData, 'gmApprovalStatus') == 'Approved')) {
        lineItemObject.vendorDetails.items.supplierPaymentTerm.disable = true
        lineItemObject.gmApprovalStatus.items.paymentTerm.disable = true
        //lineItemObject.vendorDetails.items.transferCharges.disable = true
        lineItemObject.vendorDetails.items.finalTp.disable = true
        lineItemObject.vendorDetails.items.finalSp.disable = true
        lineItemObject.vendorDetails.items.gstPercentage.disable = true
        lineItemObject.itemDetails.items.qty.disable = true
        lineItemObject.vendorDetails.items.vendorName.disable = true
        lineItemObject.vendorDetails.items.deliveryType.disable = true
        lineItemObject.rfqData.items.enquiryType.disable = true
        lineItemObject.rfqData.items.enquiryType.inputType = null
        lineItemObject.msnAssign.items.catalogMsn.disable = true
        lineItemObject.msnAssign.items.msnSuggestions.disable = true
      }
      if (this.getFieldValue(itemData, 'status') == 'po won') {
        for(let section in lineItemObject){
          Object.keys(lineItemObject[section].items).forEach(element => {
            lineItemObject[section].items[element].disable = true;
          });
        }
      }
      poDataSet.push(lineItemObject);

    }
    return { poDataSet, dataIndex };
  }



  convertEStoMongo(data: any[]) {
    return data.filter(x =>
      String(x._source['status']).toLowerCase() != 'cancelled'
    ).
      map(x => {
        x._source['_id'] = x._source['id'];
        return x._source;
      });
  }

  convertDataToCsv(data) {
    let csvData: Array<any> = [];
    let csvLine: Array<string> = [];
    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data[0].length; i++) {
      csvLine.push(data[0][i].parent.key);
    }
    csvData.push(csvLine.join(','));
    let longDataFields = ['itemId', 'buyersPoId', 'revisedPickupDate', 'supplierPoRaisedDate', 'edd', 'supplierFollowDate', 'supplierPickupDate', 'actualPickupDate', 'invoiceDate', 'deliveryDate'];
    for (let i = 0; i < data.length; i++) {
      csvLine = [];
      if (!data[i][0].value) continue;
      for (let j = 0; j < data[i].length; j++) {
        let parsedData = (data[i][j].value) ? String(data[i][j].value) : '';
        if (longDataFields.includes(data[i][j].id)) {
          parsedData = parsedData + '\t';
        }
        parsedData = parsedData.replace(/"/g, "");
        parsedData = '"' + parsedData + '"';
        csvLine.push(parsedData);
      }
      csvData.push(csvLine.join(','));
    }

    return csvData.join('\n');
  }



  cleanString(data: any) {
    return data.toString().replace(/[^ -~]+/g, "");
  }

  removeSpecialCharacterAndLowerCaseString(data: any) {
    return data.toString().toLowerCase().replace(/[^ -~]+/g, "").replace(/[^a-zA-Z0-9]/g, "");

  }

  calculateDeviationLppPrice(itemData) {
    if (lodash.has(itemData, 'rate.amount') && lodash.has(itemData, 'product.extraDetails.arcRate.amount')) {
      return String(Number(itemData.rate.amount) - Number(itemData.product.extraDetails.arcRate.amount));
    }
    return '';
  }

  /**
   *
   * @param data
   * @deprecated
   */
  makeDataForSaveViewItem(data: any) {
    let requestDataArray: Array<any> = [];
    let requestData;
    let itemsArray: Array<any> = [];
    for (let i = 0; i < data.length; i++) {
      const itemObj = this.makeDataForUpdateItem(data[i]);
      if (itemObj) itemsArray.push(itemObj);
    }
    for (let i = 0; i < itemsArray.length; i++) {
      if (!itemsArray[i].poId) {
        if (i != 0) requestDataArray.push(requestData);
        break;
      } else {
        if (i == 0 || itemsArray[i].poId != itemsArray[i - 1].poId) {
          if (i != 0) requestDataArray.push(requestData);
          requestData = {};
          requestData['_id'] = itemsArray[i].poId;
          requestData['updated_by'] = this.authService.getUserId();
          requestData['updateFromExcel'] = true;
          requestData['items'] = [];
        }
        requestData['items'].push(itemsArray[i]);
      }
    }
    requestDataArray.push(requestData);
    return requestDataArray;
  }

  getListPriceForSave(listPrice) {
    if (isNaN(listPrice.toString())) {
      return null
    } else {
      return Number(listPrice)
    }
  }

  async makeDataForUpdateItem(data: any, editedItem?) {
    const rfqSheetModel = this.convertSheetValueToObjectV3(data);
    console.log(rfqSheetModel,'ssssss');
    if (!(lodash.has(editedItem, 'id'))) {
      return null;
    }
    let emptycheck = this.checkDataType(rfqSheetModel, editedItem);
    let sourcerEmailCheck: any = true;
    let catalogPocEmailCheck: boolean = true;
    let reasonForPOLossCheck: boolean = true;
    let rfqStatusCheck: boolean = true;
    let purchaserEmailCheck: boolean = true;
    let customerPoccheck: boolean = true;
    let spCalculationCheck: boolean = true;
    let validStatus: boolean = true;
    
    if (!this.checkEmptyOrNull(rfqSheetModel.moglixPOC)) {
      if (this.validationService.emailFormatValidationCheck(rfqSheetModel.moglixPOC)) {
        let emailcheck = [];
        rfqSheetModel.moglixPOC = rfqSheetModel.moglixPOC.toLowerCase();
        emailcheck.push(rfqSheetModel.moglixPOC);
        sourcerEmailCheck = await this.poItemService.checkSourcerEmail(emailcheck);
      }
      else {
        sourcerEmailCheck = false;
        this.alertMessageService.addError(MESSAGES.ERROR.RFQ.INVALID_SOURCER_POC).show();
      }
    }
    if(!this.checkEmptyOrNull(rfqSheetModel.customerPoc)){
     if(this.validationService.moglixEmailFormatValidationCheck(rfqSheetModel.customerPoc, 'customerPoc')){
      rfqSheetModel.customerPoc = rfqSheetModel.customerPoc.toLowerCase();
     }else{
      customerPoccheck = false;
      this.alertMessageService.addError(MESSAGES.ERROR.RFQ.INVALID_CUSTOMER_POC).show();
     }
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.purchaserEmail)) {
      if (!this.validationService.emailFormatValidationCheck(rfqSheetModel.purchaserEmail, 'Purchaser Email')) {
        purchaserEmailCheck = false;
      }
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.catalogPocMailId) && !this.validationService.moglixEmailFormatValidationCheck(rfqSheetModel.catalogPocMailId, 'catalogPocMailId')) {
      catalogPocEmailCheck = false;
    }
    if ((rfqSheetModel.status === PARAMS.STATIC_PARAMS.RFQ_STATUS_PO_LOST) && this.checkEmptyOrNull(rfqSheetModel.reasonForRfqLoss)) {
      reasonForPOLossCheck = false;
      this.alertMessageService.addError(`${MESSAGES.ERROR.RFQ.MANDATORY_FIELD_REASON_FOR_LOSS} - ${rfqSheetModel.itemId}`).show();
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.customerDiscount) && !this.checkEmptyOrNull(rfqSheetModel.expectedGM) && !this.checkEmptyOrNull(rfqSheetModel.finalTp) && !this.checkEmptyOrNull(rfqSheetModel.lpToCustomer)) {
      this.alertMessageService.addError(`${MESSAGES.ERROR.RFQ.SP_CALCULATION_GM_REMOVE}`).show();
      spCalculationCheck = false;
    }
    if (editedItem && editedItem.editObj && editedItem.editObj.status && editedItem.editObj.status === PARAMS.STATIC_PARAMS.RFQ_STATUS_QUOTED && (this.checkEmptyOrNull(rfqSheetModel.leadTime)  || this.checkEmptyOrNull(rfqSheetModel.finalTp) || this.checkEmptyOrNull(rfqSheetModel.finalSp) || this.checkEmptyOrNull(rfqSheetModel.paymentTerm) || this.checkEmptyOrNull(rfqSheetModel.enquiryType))) {
      rfqStatusCheck = false;
      this.alertMessageService.addError(MESSAGES.ERROR.RFQ.MANDATORY_FIELD_TO_STATUS_QUOTE).show();
    }
    if (editedItem && editedItem.editObj && editedItem.editObj.status == '') {
      if (!this.rfqStatusListForHeader.some((element) => element.value == editedItem.editObj.status)) {
        validStatus = false;
        this.alertMessageService.addError(MESSAGES.ERROR.RFQ.INVALID_STATUS_VALUE_ERROR).show();
      }
    }
    // if (!this.checkEmptyOrNull(rfqSheetModel.finalTp) && !this.checkEmptyOrNull(rfqSheetModel.arcPrice) && Number(rfqSheetModel.finalTp) < Number(rfqSheetModel.arcPrice)) {
    //   this.alertMessageService.addError('Final TP/Unit should greater then or equal to ARC Net Price').show();
    //   validStatus = false;
    // }
    // if (!this.checkEmptyOrNull(rfqSheetModel.finalTp) && !this.checkEmptyOrNull(rfqSheetModel.tp) && Number(rfqSheetModel.finalTp) < Number(rfqSheetModel.tp)) {
    //   this.alertMessageService.addError('Final TP/Unit should greater then or equal to TP/Unit').show();
    //   validStatus = false;
    // }
    if (editedItem && editedItem.editObj && editedItem.editObj.hasOwnProperty('gstPercentage')) {
      if(!this.checkEmptyOrNull(editedItem.editObj.gstPercentage)) {
        if (!this.gstPercentageListForHeader.some((element) => element.value == editedItem.editObj.gstPercentage)) {
              validStatus = false;
              this.alertMessageService.addError(MESSAGES.ERROR.RFQ.INVALID_GST_PERCENTAGE_ERROR).show();
            }
      } else {
        validStatus = false;
        this.alertMessageService.addError(MESSAGES.ERROR.RFQ.INVALID_GST_PERCENTAGE_ERROR).show();
      }
    }
    if(!this.checkEmptyOrNull(rfqSheetModel.arcPrice)){
      if(editedItem && editedItem.editObj && editedItem.editObj.finalSp){
        // let conf = this.checkConfArcPrice(rfqSheetModel);
        if (editedItem.editObj.finalSp < rfqSheetModel.arcPrice) {
          this.alertMessageService.addError(`RFQ item id ${rfqSheetModel.itemId} created is at lower SP ${editedItem.editObj.finalSp} than ARC price ${rfqSheetModel.arcPrice}. ${MESSAGES.ERROR.RFQ.INVALID_FINAL_SP_FOR_ARC}`).show();
          return 'error';
        }
      }
    }
    // if(editedItem && editedItem.editObj && ((Object.keys(editedItem.editObj).includes('leadTime') && this.checkEmptyOrNull(editedItem.editObj.leadTime))|| (Object.keys(editedItem.editObj).includes('finalSp') && this.checkEmptyOrNull(editedItem.editObj.finalSp))|| (Object.keys(editedItem.editObj).includes('finalTp') && this.checkEmptyOrNull(editedItem.editObj.finalTp)) || (Object.keys(editedItem.editObj).includes('transferCharges') && this.checkEmptyOrNull(editedItem.editObj.transferCharges)))){
    //   this.alertMessageService.addError(MESSAGES.ERROR.RFQ.NULL_UPDATION_ERROR).show();
    //   return 'error'
    // }
    if(editedItem && editedItem.editObj && ((Object.keys(editedItem.editObj).includes('leadTime') && this.checkEmptyOrNull(editedItem.editObj.leadTime))|| (Object.keys(editedItem.editObj).includes('finalSp') && this.checkEmptyOrNull(editedItem.editObj.finalSp))|| (Object.keys(editedItem.editObj).includes('finalTp') && this.checkEmptyOrNull(editedItem.editObj.finalTp)))){
      this.alertMessageService.addError(MESSAGES.ERROR.RFQ.NULL_UPDATION_ERROR).show();
      return 'error'
    }
    if(editedItem && editedItem.editObj && Object.keys(editedItem.editObj).includes('vendorName')&& !Object.keys(editedItem.editObj).includes('vendorId')&& !Object.keys(editedItem.editObj).includes('supplierPaymentTerm')){
      editedItem.editObj['vendorId'] = null;
      editedItem.editObj['supplierPaymentTerm'] = null;
      editedItem.editObj['supplierPaymentTermId'] = null;
    }
    if(editedItem && editedItem.editObj && Object.keys(editedItem.editObj).includes('supplierPaymentTerm') && !this.validationService.checkEmptyOrNull(rfqSheetModel.supplierPaymentTermId)){
      editedItem.editObj['supplierPaymentTermId'] = rfqSheetModel.supplierPaymentTermId;
    }
    if (editedItem && lodash.has(editedItem.editObj, 'warehouseName') && (!lodash.has(editedItem.editObj, 'warehouseId') || !lodash.has(editedItem.editObj, 'idWarehouseEMS'))) {
      let warehouseId = await this.warehouseList.find((element) => {
        if (element.value == editedItem.editObj['warehouseName']) {
          return element.id;
        };
      });
      editedItem.editObj['warehouseId'] = warehouseId?.id;
      editedItem.editObj['idWarehouseEMS'] = warehouseId?.idWarehouseEMS;
    }
    // if((rfqSheetModel.enquiryType === 'RFQ' || rfqSheetModel.enquiryType === 'CRMM') && rfqSheetModel.arc==='Y') {
    //   this.alertMessageService.addError('This item is ARC type so changing the enquiry type to ARC automatically').show();
    //   editedItem.editObj['enquiryType'] = 'ARC';
    // }
    if (emptycheck != 'error' && sourcerEmailCheck && catalogPocEmailCheck && reasonForPOLossCheck && rfqStatusCheck && purchaserEmailCheck && customerPoccheck && spCalculationCheck && validStatus) {
      let itemObj = this.covertFinalEditedDataObject(editedItem);
      itemObj['moglixPOC'] = rfqSheetModel.moglixPOC;
      itemObj['customerPoc'] = rfqSheetModel.customerPoc;
      Object.keys(itemObj).forEach(validationfield => {
        if (this.validationFieldType[validationfield] == "string") {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
        else if (this.validationFieldType[validationfield] == "number") {
          itemObj[validationfield] = this.validationService.getNumberFormat(itemObj[validationfield])
        }
        else if (this.validationFieldType[validationfield] == "date") {
          itemObj[validationfield] = this.validationService.getDateFormatToLongInt(itemObj[validationfield])
        }else if (this.validationFieldType[validationfield] == "boolean"){
          itemObj[validationfield] = this.validationService.getBooleanFormat(itemObj[validationfield]);
        }
        else if (this.validationFieldType[validationfield] == "functionType") {
          if (validationfield == "rfqClosureDate") {
            itemObj[validationfield] = this.setClouserDate(rfqSheetModel.rfqClosureDate, rfqSheetModel.rfqStatus)
          }
          if (validationfield == "brand" || validationfield == "pricingCategory" || validationfield == "status" || validationfield == "deliveryType" || validationfield == "uom" || validationfield == "branchId" || validationfield == "branchName") {
            if (itemObj[validationfield] == undefined) {
              itemObj[validationfield] = "";
            } else {
              if (validationfield !== "deliveryType") {
                itemObj[validationfield] = this.validationService.removeSpecialCharsInString(itemObj[validationfield].toString())
              }
            }
          }
          if (validationfield == "l1Category" || validationfield == "l2Category") { // value will save in l1 if l2 is not present also but for delete both will delete <suggested by vikas> 
            if(validationfield == "l1Category" && itemObj[validationfield] == undefined){
              itemObj['l1Category'] = "";
              itemObj['l2Category'] = "";
            }
          }
          if (validationfield == "customerDiscount" || validationfield == "discount") {
            itemObj[validationfield] = this.validationService.setZeroasValue(rfqSheetModel[validationfield])
          }
          if (validationfield == "warehouseId") {
            itemObj[validationfield] = this.checkWarehouseId(rfqSheetModel);
          }
        }
        else {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
      });
      return itemObj;
    }
    else {
      return 'error';
    }
  }
  checkConfArcPrice(rfqSheetModel) {
    return this.teamsListConfArc?.some(element => element == rfqSheetModel.teamId) || this.companyListConfArc?.some(element => element == rfqSheetModel.companyId) || this.plantListConfArc?.some(element => element == rfqSheetModel.plantId);
  }

  setWarehouseData(data) {
    this.warehouseList = data;
  }

  covertFinalEditedDataObject(editedData) {
    let itemSheetModel = editedData.editObj;
    itemSheetModel['id'] = editedData.id;
    if (itemSheetModel.uomRfq) {
      itemSheetModel['uom'] = itemSheetModel.uomRfq;
      delete itemSheetModel['uomRfq'];
    }
    if (itemSheetModel['plantId']) {
      itemSheetModel['branchId'] = itemSheetModel.plantId;
      delete itemSheetModel['plantId'];
    }
    if (itemSheetModel['plantName']) {
      itemSheetModel['branchName'] = itemSheetModel.plantName;
      delete itemSheetModel['plantName'];
    }
    if (itemSheetModel['rfqId']) {
      itemSheetModel['rfqIdNew'] = itemSheetModel.rfqId;
      delete itemSheetModel['rfqId'];
    }
    if (itemSheetModel['moglixDescription']) {
      itemSheetModel['msnDescription'] = itemSheetModel.moglixDescription;
    }
    return itemSheetModel;
  }

  checkWarehouseId(rfqSheetModel) {
    return (rfqSheetModel.warehouseId && rfqSheetModel.warehouseName) ? rfqSheetModel.warehouseId : null
  }

  validationFieldType = {
    'arc': "string",
    'brand': "functionType",
    'l1Category': "functionType",
    'l2Category': "functionType",
    'pricingCategory': "functionType",
    'clarificationDetail': "string",
    'clarificationNeeded': "string",
    'gstPercentage': "number",
    'paymentTerm': "string",
    'remark': "string",
    'reqForGlobalMsn': "string",
    'qty': "number",
    'purchaser': "string",
    'moglixPOC': "string",
    'cpn': "string",
    'productName': "string",
    'status': "functionType",
    'lastSourcedDate': "date",
    'vendorName': "string",
    'leadTime': "number",
    'reasonForRfqLoss': "string",
    'remarkForPOLostRegret': "string",
    'deliveryType': "functionType",
    'uom': "functionType",
    'msn': "string",
    'moglixDescription': "string",
    'branchId': "functionType",
    'branchName': "functionType",
    'rfqIdNew': "string",
    'rfqReceivedDate': "date",
    'cpt': "string",
    'customerRfqDate': "string",
    'companyName': "string",
    'companyId': "number",
    'modificationDate': "string",
    //'creationDate': rfqSheetModel.creationDate ? rfqSheetModel.creationDate : null,
    'listPrice': "number",
    'teamName': "string",
    'moq': "string",
    'hsn': "number",
    'edq': "date",
    'totalValue': "number",
    'remark1': "string",
    'remark2': "string",
    'remark3': "string",
    'remark4': "string",
    'msnDescription': "string",
    'discount': "functionType",
    'productType': "string",
    'finalTp': "number",
    'customerPoc': "string",
    'edr': "number",
    'poWonByVendor': "string",
    'tpByCustomer': "string",
    'moglixVendorRegistered': "string",
    'priceValidityDate': "date",
    'customerDiscount': "functionType",
    'finalSp': "number",
    'manualSp':"number",
    'quotedDate': "date",
    'rfqClosureDate': "functionType",
    'teamId': "number",
    'lpToCustomer': "number",
    'expectedGM': "number",
    'highValueRFQ': "string",
    'clarificationFromCatalog': "string",
    'clarificationFromSourcing': "string",
    'msnStatus': "string",
    'additionalRemark': "string",
    'warehouseName': "string",
    'warehouseId': "string",
    'msnclarificationClosed': "string",
    'catalogPocMailId': "string",
    'sourcingLeadTime': "number",
    'vendorId': "string",
    'supplierPaymentTerm': "string",
    'supplierPaymentTermId': "string",
    //'transferCharges': "number",
    'gmStarRfq': "string",
    'gmStar': "string",
    'gmApprovalStatus': "string",
    'rejectedFrom': "string",
    'approvedFrom': "string",
    'gmApprovalRemark': "string",
    'arcPrice': "number",
    'defaulterSupplier': 'boolean',
  }

  /**
   * Function returns the Date check true/false
   *
   * @param Date
   *
   * @author Burela Raju
   */

  public checkDateError(value) {
    let validDate: boolean = true;
    if (Math.sign(value) == -1) {
      this.alertMessageService.addError("Please Enter Valid Date").show();
      validDate = false;
    }
    return validDate;
  }

  isManadatoryPoc(editObjCopy: any) { // make non madadory in case of l1Category, l2Category, enquiryType
    let [editObj, editObjKeys] = [{...editObjCopy.editObj}, Object.keys(editObjCopy.editObj)]
    if(editObjKeys.includes('id')) delete editObj.id;
    if(editObjKeys.includes('moglixPOC')) delete editObj.moglixPOC;
    if(editObjKeys.includes('customerPoc')) delete editObj.customerPoc;
    if(editObjKeys.includes('l1Category')) delete editObj.l1Category;
    if(editObjKeys.includes('l2Category')) delete editObj.l2Category;
    if(editObjKeys.includes('enquiryType')) delete editObj.enquiryType;
    return Object.keys(editObj).length;
  }

  public checkDataType(rfqSheetModel, editedItem?) {
    if(Object.keys(editedItem.editObj).includes('customerPoc') && this.checkEmptyOrNull(editedItem.editObj.customerPoc)) { // when user try to delete customerPOC
      this.alertMessageService.addError(`${MESSAGES.ERROR.RFQ.CUSTOMER_POC_MANDATORY} For ${rfqSheetModel.itemId}`).show();
        return 'error';
    }
    if (this.isManadatoryPoc({...editedItem})) { // [id, moglixPOC, customerPoc, l1Category, l2Category, enquiryType] - when user update out of this list
      if (this.checkEmptyOrNull(rfqSheetModel.moglixPOC) || this.checkEmptyOrNull(rfqSheetModel.customerPoc)) {
        this.alertMessageService.addError(`${MESSAGES.ERROR.RFQ.CUSTOMER_POC_MOGLIX_POC_MANDATORY} For ${rfqSheetModel.itemId}`).show();
        return 'error';
      }
    // } else {
    //   if(this.checkEmptyOrNull(rfqSheetModel.moglixPOC)) { // [id, moglixPOC, customerPoc, l1Category, l2Category, enquiryType] - when user from this list - make non manadatory customerPOC
    //     this.alertMessageService.addError(`${MESSAGES.ERROR.RFQ.MOGLIX_POC_MANDATORY} For ${rfqSheetModel.itemId}`).show();
    //     return 'error';
    //   }
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.hsn) && (isNaN(rfqSheetModel.hsn) || !this.poItemService.HsnLengthList.includes(rfqSheetModel.hsn.toString().length))) {
      this.alertMessageService.addError("HSN Should be Numeric" + MESSAGES.ERROR.PO_ERROR.HSN_CODE_LENGTH_ERROR).show();
      return 'error';
    }
    // if (!this.checkEmptyOrNull(rfqSheetModel.tp) && (isNaN(rfqSheetModel.tp) || rfqSheetModel.tp == 0 ) ||  (!this.checkEmptyOrNull(rfqSheetModel.sp) && (isNaN(rfqSheetModel.sp) || rfqSheetModel.sp == 0 || Math.sign(rfqSheetModel.sp) == -1 ) ||  (Math.sign(rfqSheetModel.transferCharges) == -1 ))) {
    //   this.alertMessageService.addError("TP should be Numeric & Non Zero,SP Should be Numeric, Non Negative and Non Zero ,Transfer Charges should be Numeric & Non Negative").show();
    //   return 'error';
    // }
    if ((!this.checkEmptyOrNull(rfqSheetModel.sp) && (isNaN(rfqSheetModel.sp) || rfqSheetModel.sp == 0 || Math.sign(rfqSheetModel.sp) == -1 ))) {
      this.alertMessageService.addError("SP Should be Numeric, Non Negative and Non Zero").show();
      return 'error';
    }
    if ( !this.checkEmptyOrNull(rfqSheetModel.finalTp) && (isNaN(rfqSheetModel.finalTp) || rfqSheetModel.finalTp == 0 || Math.sign(rfqSheetModel.finalTp) == -1)||(!this.checkEmptyOrNull(rfqSheetModel.finalSp) && (isNaN(rfqSheetModel.finalSp) || rfqSheetModel.finalSp == 0 || Math.sign(rfqSheetModel.finalSp) == -1 ))) {
      //this.alertMessageService.addError("Final TP ,Final SP and Discount ( to customer ) Should be Numeric, Non Negative and Non Zero").show();
      this.alertMessageService.addError("Final TP,Final SP Should be Numeric, Non Negative and Non Zero").show();
      return 'error';
    }
    if(!this.checkEmptyOrNull(rfqSheetModel.customerDiscount) && (isNaN(rfqSheetModel.customerDiscount) ||  Math.sign(rfqSheetModel.customerDiscount) == -1)){
      this.alertMessageService.addError("Discount (to customer) Should be Numeric, Non Negative ").show();
      return 'error';
    }
    if(!this.checkEmptyOrNull(rfqSheetModel.discount) && (isNaN(rfqSheetModel.discount) ||  Math.sign(rfqSheetModel.discount) == -1) || rfqSheetModel.discount >100){
      this.alertMessageService.addError("Discount (from supplier) Should be Numeric, Non Negative and Should Not Be Greater Than 100 %").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.leadTime) && (isNaN(rfqSheetModel.leadTime) || rfqSheetModel.leadTime == 0 || Math.sign(rfqSheetModel.leadTime) == -1 || !Number.isInteger(Number(rfqSheetModel.leadTime)))) {
      this.alertMessageService.addError("Lead Time Should be Integer Number, Non Negative and Non Zero ").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.lpToCustomer) && (isNaN(rfqSheetModel.lpToCustomer) || rfqSheetModel.lpToCustomer == 0 || Math.sign(rfqSheetModel.lpToCustomer) == -1)) {
      this.alertMessageService.addError("List Price Should be Numeric, Non Negative and Non Zero").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.edr) && (isNaN(rfqSheetModel.edr) || rfqSheetModel.edr == 0 || Math.sign(rfqSheetModel.edr) == -1)) {
      this.alertMessageService.addError("Estimated Days of Response Should be Numeric, Non Negative and Non Zero").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.qty) && (isNaN(rfqSheetModel.qty) || rfqSheetModel.qty == 0 || Math.sign(rfqSheetModel.qty) == -1)) {
      this.alertMessageService.addError("Quantity Should be Numeric, Non Negative and Non Zero").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.manualSp) && (isNaN(rfqSheetModel.manualSp) || rfqSheetModel.manualSp == 0 || Math.sign(rfqSheetModel.manualSp) == -1)) {
      this.alertMessageService.addError("Sourcer Recommended SP/Unit Should be Numeric, Non Negative and Non Zero").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.expectedGM) && (isNaN(rfqSheetModel.expectedGM) || rfqSheetModel.expectedGM == 0 || Math.sign(rfqSheetModel.expectedGM) == -1)) {
      this.alertMessageService.addError("Expected GM Should be Numeric, Non Negative and Non Zero").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.gstPercentage) && (isNaN(rfqSheetModel.gstPercentage) || Math.sign(rfqSheetModel.gstPercentage) == -1 || !Number.isInteger(Number(rfqSheetModel.gstPercentage)))) {
      this.alertMessageService.addError("GST Should be Integer Number,Non Negative").show();
      return 'error';
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.sourcingLeadTime) && (isNaN(rfqSheetModel.sourcingLeadTime) || rfqSheetModel.sourcingLeadTime == 0 || Math.sign(rfqSheetModel.sourcingLeadTime) == -1 || !Number.isInteger(Number(rfqSheetModel.sourcingLeadTime)))) {
      this.alertMessageService.addError("Sourcing Lead Time Should be Integer Number, Non Negative and Non Zero ").show();
      return 'error';
    }
    return this.checkRfqDateError(rfqSheetModel);
  }

  checkRfqDateError(rfqSheetModel) {
    let errorList = [];
    let error;
    if (!this.checkEmptyOrNull(rfqSheetModel.rfqClosureDate) && !this.validationService.checkDateFormat(rfqSheetModel.rfqClosureDate)) {
      error = 'error';
      errorList.push('RFQ Closure Date');
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.lastSourcedDate) && !this.validationService.checkDateFormat(rfqSheetModel.lastSourcedDate)) {
      error = 'error';
      errorList.push('Last Source Date');
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.edq) && !this.validationService.checkDateFormat(rfqSheetModel.edq)) {
      error = 'error';
      errorList.push('Estimated Date For Quotation');
    }
    if (!this.checkEmptyOrNull(rfqSheetModel.priceValidityDate) && !this.validationService.checkDateFormat(rfqSheetModel.priceValidityDate)) {
      error = 'error';
      errorList.push('Price Validity Date');
    }
    if (errorList.length > 0) {
      this.alertMessageService.addError(`Please Enter Valid Date Format For ${errorList.toString()} and Format Should be MM/DD/YYYY`).show();
    }

    return error;
  }

  addAttachments(value) {
    let data = null;
    if (!this.validationService.checkEmptyOrNull(value) && value.length > 0) {
      data = value[0].url
    }
    return data;
  }

  setClouserDate(date, status) {
    let data = moment(date, "MM/DD/YYYY", true).valueOf();
    let rfqstatus = ['PO Won', 'PO Lost', 'Closed before Quoting', 'Closed after Quoting'];
    if (rfqstatus.includes(status)) {
      data = moment(new Date(), "MM/DD/YYYY", true).valueOf()
    }
    return data;
  }

  calculateTp(listPrice, discountEntered) {
    const discount = discountEntered == "" ? 0 : discountEntered;
    if ((!isNaN(Number(listPrice)) && !isNaN(Number(parseFloat(discount))))
      && String(listPrice).length > 0 && String(discount).length > 0 && listPrice !== null && discountEntered !== null) {
      return (parseFloat(listPrice) - ((discount * listPrice) / 100)).toFixed(2);
    } else {
      return '';
    }
  }

  calculateGm(finalSp, finalTp) {
    if ((!isNaN(Number(finalSp)) && !isNaN(Number(finalTp))) && finalTp
      && String(finalTp).length > 0 && finalSp && String(finalSp).length > 0) {
      return ((parseFloat(finalSp) - parseFloat(finalTp)) / parseFloat(finalSp) * 100).toFixed(2);
    } else {
      return '';
    }
  }

  calculateSP(expectedGM, finalTp) {
    if ((!isNaN(Number(expectedGM)) && !isNaN(Number(finalTp))) && finalTp
      && String(finalTp).length > 0 && expectedGM && String(expectedGM).length > 0) {
      return (parseFloat(finalTp) / (1 - parseFloat(expectedGM) / 100)).toFixed(2);
    } else {
      return '';
    }
  }

  checkPlantName(plantId) {
    plantId = parseInt(this.validationService.removeSpecialCharsInStringWithSpaces(plantId.toString()));
    let plantName = "";
    if (plantId && this.checkEmptyOrNull(plantName)) {
      plantName = this.authService.getPlantNameFromId(plantId);
    }
    return plantName;
  }
}
