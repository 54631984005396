import { LineItemHelperService } from '@app/services/helpers/line-item-helper.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoService } from '@app/services/po.service';
import { AuthService } from '../auth.service';
import * as moment from 'moment';
import { BuyerService } from '../buyer.service';
import * as lodash from 'lodash-es';
import { ValidationService } from './validation.service';
import { SuppliersSheetHelperService } from './suppliers-sheet-helper.service';
import { AlertMessageService } from '../alert-message.service';
import { ToastrService } from 'ngx-toastr';
import { CatalogSheetModel } from '@app/models/catalogsheet.model';
import { OmtSheetModel } from '@app/models/omtsheet.model';
import { SaItemSheetModel } from '@app/models/saitemsheet.model';
import { PoItemService } from '../po-item.service';


@Injectable({
  providedIn: 'root'
})
export class SaSheetHelperService {
  constructor(private toastr: ToastrService, private authService: AuthService, private activatedRoute: ActivatedRoute, private alertMessageService: AlertMessageService,
    private suppliersSheetHelperService: SuppliersSheetHelperService, private poService: PoService, private buyerService: BuyerService, private validationService: ValidationService, private poItemService: PoItemService) {
  }


  sheetGroupInfo: Array<any> = [
    { id: 'plantData', class: 'sky-blue', name: 'Plant Data' },
    { id: 'saData', class: 'light-green', name: 'SA Data' },
  ]

  defaultSelectedSheetGroup = ['plantData', 'saData'];

  getViewLineItemsObjForSa() {
    let lineObject = {
      plantData: {
        title: 'PO Data',
        headingClass: 'basic-info',
        items: {
          verticalName: {
            title: 'Vertical',
            hide: false,
            value: null,
            disable: true,
          },
          teamName: {
            title: 'Team',
            hide: false,
            value: null,
            disable: true,
          },
        
          plantId: {
            title: 'Plant ID',
            hide: false,
            value: null,
            disable: true,
          },
          plantName: {
            title: 'Plant Name',
            hide: false,
            value: null,
            disable: true,
          },
          saNo: {
            title: 'Customer SA No.',
            hide: false,
            value: null,
            disable: true,
          },
          saId: {
            title: 'SA Id',
            hide: false,
            value: null,
            disable: true,
          },
          itemId: {
            title: 'SA Item Id',
            hide: false,
            value: null,
            disable: true,
          },
          
          materialDescription: {
            title: 'Material Description',
            hide: false,
            value: null,
            disable: true,
          },
          saType: {
            title: 'SA Type',
            hide: false,
            value: null,
            disable: true,
          }
        }
      },

      saData: {
        title: 'SA Data',
        headingClass: 'sheet-group-podata',
        items: {
          itemDataRemark: {
            title: 'Remark',
            hide: false,
            value: null,
            disable: false,
          },
          cpn: {
            title: 'CPN',
            hide: false,
            value: null,
            disable: true,
          },
          brand: {
            title: 'Brand',
            hide: false,
            value: null,
            disable: true,
          },
          suggestedBrand: {
            title: 'Suggested Brand',
            hide: false,
            value: null,
            disable: false,
          },
          cpt: {
            title: 'CPT (Y/N)',
            hide: false,
            value: null,
            disable: true,
          },
          totalQty: {
            title: 'Total Qty',
            hide: false,
            value: null,
            disable: true,
          },
          consumedQty: {
            title: 'Consumed Qty',
            hide: false,
            value: null,
            disable: true,
          },
          returnedQuantity: {
            title: 'Returned Qty',
            hide: false,
            value: null,
            disable: true,
          },
          pendingQuantity: {
            title: 'Pending Qty',
            hide: false,
            value: null,
            disable: true,
          },
          orderFrequency: {
            title: 'Order Frequency',
            hide: false,
            value: null,
            disable: false,
          },
          uom: {
            title: 'Customer UOM',
            hide: false,
            value: null,
            disable: true,
          },
          itemId: {
            title: 'Item Id',
            hide: true,
            value: null,
            disable: true,
          },
          spUnit: {
            title: 'SP/Unit',
            hide: false,
            value: null,
            disable: true,
          },
          hsn: {
            title: 'HSN',
            hide: false,
            value: null,
            disable: true,
          },
          taxPercent: {
            title: 'Tax %',
            hide: false,
            value: null,
            disable: true,
          },
          expirationDate: {
            title: 'Expiration Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          poConsumedRemark: {
            title: 'Remark',
            hide: false,
            value: null,
            disable: false,
          },
          opsAllocation: {
            title: 'Ops Allocation',
            hide: false,
            value: null,
            disable: false,
          },
          sourcerEmail: {
            title: 'Sourcing POC',
            hide: false,
            value: null,
            disable: false,
          },
          suggestSupplier: {
            title: ' Suggest Supplier',
            hide: false,
            value: null,
            disable: false,
          },
          suggestSupplierName: {
            title: ' Suggested Supplier Name',
            hide: false,
            value: null,
            disable: true,
          },
          suggestSupplierId: {
            title: ' Suggested Supplier ID',
            hide: false,
            value: null,
            disable: true,
          },
          suggestedSupplierPaymentTerms: {
            title: 'Suggested Supplier Payment Terms',
            hide: false,
            value: null,
            disable: true,
          },
          sourcingRemark: {
            title: ' Remark',
            hide: false,
            value: null,
            disable: false,
          },
          msn: {
            title: 'MSN',
            hide: false,
            value: null,
            disable: true,
          },
          msnDescription: {
            title: 'MSN Description',
            hide: false,
            value: null,
            disable: true,
          },
        }
      }
    }
    return lineObject;
  }

  getFieldValue(data: Object, field: String) {
    return lodash.has(data, field) ? data[`${field}`] : ''
  }

  makeItemsData(data) {
    const poDataSet: any[] = [];
    const dataIndex: Object = {};
    let sheetDataIndex = -1;
    for (let i = 0; i < data.length; i++) {
      const lineItemObject = this.getViewLineItemsObjForSa();
      const itemData = data[i];
      sheetDataIndex = sheetDataIndex + 1;
      dataIndex[itemData.id] = sheetDataIndex;
      lineItemObject.plantData.items.verticalName.value = this.getFieldValue(itemData, 'verticalName');
      lineItemObject.plantData.items.teamName.value = this.getFieldValue(itemData, 'teamName');
      lineItemObject.plantData.items.plantId.value = this.getFieldValue(itemData, 'plantId');
      lineItemObject.plantData.items.materialDescription.value = this.getFieldValue(itemData, 'materialDesc');
      lineItemObject.plantData.items.plantName.value = this.getFieldValue(itemData, 'plantName');;
      lineItemObject.plantData.items.saNo.value = this.getFieldValue(itemData, 'saNo');
      lineItemObject.plantData.items.saId.value = this.getFieldValue(itemData, 'saId');
      lineItemObject.plantData.items.itemId.value = this.getFieldValue(itemData, 'itemId');
      lineItemObject.plantData.items.saType.value = this.getFieldValue(itemData, 'releasedTxt');
      

      lineItemObject.saData.items.brand.value = this.getFieldValue(itemData, 'brand');
      lineItemObject.saData.items.consumedQty.value = this.getFieldValue(itemData, 'consumedQty');
      lineItemObject.saData.items.returnedQuantity.value = this.getFieldValue(itemData, 'returnedQuantity');
      lineItemObject.saData.items.pendingQuantity.value = this.getFieldValue(itemData, 'pendingQuantity');
      lineItemObject.saData.items.cpn.value = this.getFieldValue(itemData, 'cpn');
      lineItemObject.saData.items.cpt.value = 'N';
      if (lodash.has(itemData, 'brand')) {
        if (this.suppliersSheetHelperService.cptBrandList.includes(String(itemData.brand).toLowerCase())) {
          lineItemObject.saData.items.cpt.value = 'Y';
        }
      }
      lineItemObject.saData.items.expirationDate.value = this.validationService.formatDate(this.getFieldValue(itemData, 'expirationDate'));
      lineItemObject.saData.items.hsn.value = this.getFieldValue(itemData, 'hsn');
      lineItemObject.saData.items.itemId.value = this.getFieldValue(itemData, 'itemId');
      lineItemObject.saData.items.msn.value = this.getFieldValue(itemData, 'msn');
      lineItemObject.saData.items.itemDataRemark.value = this.getFieldValue(itemData, 'itemDataRemark');

      lineItemObject.saData.items.msnDescription.value = this.getFieldValue(itemData, 'msnDescription');
      lineItemObject.saData.items.opsAllocation.value = this.getFieldValue(itemData, 'opsAllocation');
      lineItemObject.saData.items.orderFrequency.value = this.getFieldValue(itemData, 'orderFrequency');
      lineItemObject.saData.items.poConsumedRemark.value = this.getFieldValue(itemData, 'poConsumedRemark');
      lineItemObject.saData.items.sourcerEmail.value = this.getFieldValue(itemData, 'sourcerEmail');
      lineItemObject.saData.items.sourcingRemark.value = this.getFieldValue(itemData, 'sourcingRemark');
      lineItemObject.saData.items.spUnit.value = this.getFieldValue(itemData, 'spUnit');
      lineItemObject.saData.items.suggestSupplier.value = this.getFieldValue(itemData, 'suggestSupplier');
      lineItemObject.saData.items.suggestSupplierId.value = this.getFieldValue(itemData, 'suggestSupplierId');
      lineItemObject.saData.items.suggestSupplierName.value = this.getFieldValue(itemData, 'suggestSupplierName');
      lineItemObject.saData.items.suggestedSupplierPaymentTerms.value = this.getFieldValue(itemData, 'suggestedSupplierPaymentTerms');
      lineItemObject.saData.items.suggestedBrand.value = this.getFieldValue(itemData, 'suggestedBrand');
      lineItemObject.saData.items.taxPercent.value = this.getFieldValue(itemData, 'taxPercent');
      lineItemObject.saData.items.totalQty.value = this.getFieldValue(itemData, 'totalQty');
      lineItemObject.saData.items.uom.value = this.getFieldValue(itemData, 'uom');

      poDataSet.push(lineItemObject)
    }
    return { poDataSet, dataIndex };
  }

  public checkEmptyOrNull(value) {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
  }

  convertSheetValueToObjectV3(data) {
    if (data) {
      let itemSheetModel = new SaItemSheetModel();
      data.forEach(element => {
        let key = element.id;
        if (element.id) {
          itemSheetModel[key] = element.value;
        }
      });
      return itemSheetModel;
    }
  }

  async makeDataForUpdateItemInSaSheet(data: any) {

    const itemSheetModel = this.convertSheetValueToObjectV3(data);
    if (!(lodash.has(itemSheetModel, 'itemId'))) {
      return null;
    }
    let sourcerEmailCheck: any = true;
    if (!this.checkEmptyOrNull(itemSheetModel.sourcerEmail)) {
      if (this.validationService.emailFormatValidationCheck(itemSheetModel.sourcerEmail)) {
        let emailcheck = [];
        itemSheetModel.sourcerEmail = itemSheetModel.sourcerEmail.toLowerCase();
        emailcheck.push(itemSheetModel.sourcerEmail)
        sourcerEmailCheck = await this.poItemService.checkSourcerEmail(emailcheck);
      }
      else {
        sourcerEmailCheck = false;
      }
    }
    if (sourcerEmailCheck) {
      let itemObj = {
        "itemId": itemSheetModel.itemId ? itemSheetModel.itemId.toString() : '',
        "itemDataRemark": itemSheetModel.itemDataRemark ? itemSheetModel.itemDataRemark.toString() : '',
        "sourcingRemark": itemSheetModel.sourcingRemark ? itemSheetModel.sourcingRemark.toString() : '',
        "suggestedBrand": itemSheetModel.suggestedBrand ? itemSheetModel.suggestedBrand.toString() : '',
        "orderFrequency": itemSheetModel.orderFrequency ? itemSheetModel.orderFrequency.toString() : '',
        "poConsumedRemark": itemSheetModel.poConsumedRemark ? itemSheetModel.poConsumedRemark.toString() : '',
        "opsAllocation": itemSheetModel.opsAllocation ? itemSheetModel.opsAllocation.toString() : '',
        "sourcerEmail": itemSheetModel.sourcerEmail ? itemSheetModel.sourcerEmail.toString() : '',
        "suggestSupplier": itemSheetModel.suggestSupplier ? itemSheetModel.suggestSupplier.toString() : '',
        "suggestSupplierName": itemSheetModel.suggestSupplierName ? itemSheetModel.suggestSupplierName.toString() : '',
        "suggestSupplierId": itemSheetModel.suggestSupplierId ? itemSheetModel.suggestSupplierId.toString() : '',
        "suggestedSupplierPaymentTerms": itemSheetModel.suggestedSupplierPaymentTerms ? itemSheetModel.suggestedSupplierPaymentTerms.toString() : '',
      }
      return itemObj;
    }
    else {
      return 'error';
    }
  }
}


  
