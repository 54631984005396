
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoService } from '@app/services/po.service';
import { PuchaseOrderItemModel, Tax } from '@app/models/purchase-order-item.model';
import { ProductModel } from '@app/models/product.model';
import { AuthService } from '../auth.service';
import { BrandModel } from '@app/models/brand.model';
import { ItemSheetModel } from '@app/models/itemsheet.model';
import * as moment from 'moment';
import { BuyerService } from '../buyer.service';
import * as lodash from 'lodash-es';
import { ValidationService } from './validation.service';
import { SuppliersItemSheetModel } from '@app/models/suppliers-item-sheet.model';
import { PoItemService } from '../po-item.service';
import { SHEET_DATA_TYPE } from '@app/config/sheetfields-datatype';
import { PARAMS } from '@app/config/params';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class SuppliersSheetHelperService {

  validationFieldType = SHEET_DATA_TYPE.SUPPLIER_SHEET_FIELDS_TYPE;
  public wholeItemSheetRowObj:any;

  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute,
    private poService: PoService, private buyerService: BuyerService, private validationService: ValidationService, private poItemService: PoItemService,private commonService:CommonService) {
  }
  emsPoStatusList = [{ id: "10", value: "ACCEPTED" }, { id: "5", value: "ACCEPTANCE_PENDING" }, { id: "7", value: "REJECTED" }, { id: "12", value: "DROPSHIP_READY" }, { id: "14", value: "DROPSHIP_PENDING" }, { id: "15", value: "READY_FOR_PICKUP" }, { id: "20", value: "OUT_FOR_PICKUP" }, { id: "23", value: "PICK_UP_PENDING" }, { id: "30", value: "DOOR_DELIVERY_DONE" }, { id: "32", value: "DOOR_DELIVERY_PENDING" }, { id: "40", value: "QC_PASS" }, { id: "50", value: "QC_FAIL" }, { id: "52", value: "SEND_FOR_EXCHANGE" }, { id: "53", value: "SUPPLIER_DENIED" }, { id: "55", value: "MAPPED_TO_INVOICE" }, { id: "60", value: "PICKUP_DONE" }, { id: "65", value: "MRN_DONE" }, { id: "80", value: "CANCEL" }, { id: "100", value: "CLOSE" }, { id: "200", value: "DISPOSED" }]

  sourcingPurchaserList = [{ purchaserName: "Prashant Verma", purchaserEmailId: "prashant-verma@bridgestone.co.in", sourcing: "Aakash" },
  { purchaserName: "Rahul Mahajan", purchaserEmailId: "rahul-mahajan@bridgestone.co.in", sourcing: "Gaurav" },
  { purchaserName: "Vijay Shelke", purchaserEmailId: "vijay-shelke@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Darius Edibam", purchaserEmailId: "darius-edibam@bridgestone.co.in", sourcing: "Aakash" },
  { purchaserName: "Niket Kalamkar", purchaserEmailId: "niket-kalamkar@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Sarang Radke", purchaserEmailId: "sarang-radke@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Prafulla Patil", purchaserEmailId: "prafulla-patil@bridgestone.co.in", sourcing: "Atul" },
  { purchaserName: "Sankalp Kashyap", purchaserEmailId: "sankalp-kashyap@bridgestone.co.in", sourcing: "Aakash" },
  { purchaserName: "Tanmay Penta", purchaserEmailId: "tanmay-penta@bridgestone.co.in", sourcing: "Jay" },
  { purchaserName: "Neha Karve", purchaserEmailId: "neha-karve@bridgestone.co.in", sourcing: "Atul" },
  { purchaserName: "Aniket Shetye", purchaserEmailId: "aniket-shetye@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Snehal Mate", purchaserEmailId: "snehal-mate@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Rohan Deshpande", purchaserEmailId: "rohan-deshpande@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Akshay Gund", purchaserEmailId: "akshay_gund@bridgestone.co.in", sourcing: "Atul" },
  { purchaserName: "Aniruddha Sarnaik", purchaserEmailId: "aniruddha-sarnaik@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Sanket Tajane", purchaserEmailId: "sanket-tajane@bridgestone.co.in", sourcing: "Atul" },
  { purchaserName: "Sonali Walunj", purchaserEmailId: "sonali-walunj@bridgestone.co.in", sourcing: "Jay" },
  { purchaserName: "Prasanna V. Khandekar", purchaserEmailId: "prasanna-khandekar@bridgestone.co.in", sourcing: "Jay" },
  { purchaserName: "Uday Kurma", purchaserEmailId: "uday-kurma@bridgestone.co.in", sourcing: "Jay" },
  { purchaserName: "Sourabh Aigwal", purchaserEmailId: "Sourabh-Aigwal@bridgestone.co.in", sourcing: "Vishal" },
  { purchaserName: "Indrajeet Pawar", purchaserEmailId: "indrajeet-pawar@bridgestone.co.in", sourcing: "Jay" },
  { purchaserName: "Testing", purchaserEmailId: "rishabh.verma@moglix.com", sourcing: "Rishabh Mogli" },
  ];

  sheetGroupInfo: Array<any> = [
    { id: 'plantData', class: 'sky-blue', name: 'Plant Data' },
    { id: 'supplierData', class: 'light-green', name: 'PO Data' },

  ]

  defaultSelectedSheetGroup = ['plantData', 'supplierData'];

  cptBrandList = ["esab", "ador", "l&t", "sant", "drp", "nataraj", "larsen & toubro (l&t)", "steel grip", "legris", "seimens", "smc pneumatics", "fevistick", "anchor roma", "dneers", "dell", "hy-poxy", "wd-40", "apar", "jk sparkle", "faber castell", "locktite", "fiber castle", "bharat petroleum", "pedilite", "pheonix", "addison", "cumi", "cello", "kennedy", "festo", "philips", "schneider", "l&t", "yuken", "omron", "megha", "smc", "wonder", "lapp", "steelgrip", "pye", "lovejoy", "swastik", "lps", "luxor", "champion", "sandvik", "jk", "anabond", "legrand", "asian paints", "wd 40", "anchor", "natraj", "camlin", "skf", "exide", "deerfos", "tvs", "bosch", "castrol", "3m", "fenner", "pidilite", "taparia", "unbrako", "stanley", "miranda", "cobra", "osg", "crompton", "voltas", "totem", "addison", "mungyo", "polycab", "mobil", "dowells", "siemens", "faber-castell", "ajanta", "havells", "kangaro", "epson", "reynolds", "trident", "century", "bch", "syska", "rexnord", "fevicol", "kores", "bahco", "apsara", "d-link", "almonard", "sant", "pilot", "janatics", "crompton greaves", "hp", "merck", "transcend", "shell", "sun", "fluke", "loctite", "kirloskar", "ntn", "lenovo", "bajaj", "phoenix", "de neers", "berger", "fag", "yg1", "henkel", "autonics", "servo", "dewalt", "finolex", "jk super drive", "uniball", "lg", "lotus", "camel", "ceratizit", "deneers", "groz", "bindal", "ankita", "baumer", "microtek", "hans", "oddy", "omega", "hansa", "aeromax", "araldite", "apl", "nilkamal", "halonix", "usha", "kei", "jk files", "logitech", "neeraj", "astral", "hy poxy", "flair", "add gel", "indian tools", "rorito", "fevi stik", "valvoline", "wd", "jk copier", "cp", "ashoka", "king", "solo", "deepak", "luminous", "m seal", "dulux", "black & decker", "fevi kwik", "glory", "xps", "unik", "parker", "qualikems", "qualigens", "audco", "karam", "honeywell safety", "fisher scientific", "themo fisher"];

  getViewLineItemsObj() {
    let lineObject = {
      plantData: {
        title: 'PO Data',
        headingClass: 'basic-info',
        items: {
          plantName: {
            title: 'Plant Name',
            hide: false,
            value: null,
            disable: true,
          },
          plantId: {
            title: 'Plant ID',
            hide: false,
            value: null,
            disable: true,
          },
          customerPoNum: {
            title: 'Customer Po No',
            hide: false,
            value: null,
            disable: true,
          },
          materialDescription: {
            title: 'Material Description',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.suggestedSupplierPaymentTermsId': {
            title: 'Suggested Supplier Payment Terms Id',
            hide: true,
            value: null,
            disable: true,
          },
        }
      },
      supplierData: {
        title: 'Supplier Data',
        headingClass: 'sheet-group-podata',
        items: {
          'product.extraDetails.clientData.annexure': {
            title: 'Additional Description',
            hide: false,
            value: null,
            disable: false,
          },
          customerSANumber: {
            title: 'SA Number',
            hide: false,
            value: null,
            disable: true,
          },
          warehouse: {
            title: 'Warehouse',
            hide: false,
            value: null,
            disable: true,
          },
          cpn: {
            title: 'CPN',
            hide: false,
            value: null,
            disable: true,
          },
          brand: {
            title: 'Brand',
            hide: false,
            value: null,
            disable: true,
          },
          'quantity.qty': {
            title: 'Qty',
            hide: false,
            value: null,
            disable: true,
          },
          'quantity.uom': {
            title: 'Customer UOM',
            hide: false,
            value: null,
            disable: true,
          },
          spUnit: {
            title: 'SP/Unit',
            hide: false,
            value: null,
            disable: true,
          },
          buyersPoId: {
            title: 'Buyers PoId',
            hide: false,
            value: null,
            disable: true,
          },
          itemId: {
            title: 'Item Id',
            hide: false,
            value: null,
            disable: true,
          },
          sourcerEmail: {
            title: 'Sourcing POC',
            hide: false,
            value: null,
            disable: false,
          },
          allocatedInventory: {
            title: 'Allocated Inventory',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.suggestSupplier': {
            title: ' Suggest Supplier',
            hide: false,
            value: null,
            disable: false,
          },
          'sourcing.suggestSupplierName': {
            title: ' Suggested Supplier Name',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.suggestSupplierId': {
            title: ' Suggested Supplier ID',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.suggestedSupplierPaymentTerms': {
            title: 'Suggested Supplier Payment Terms',
            hide: false,
            value: null,
            disable: true,
          },
          pendingQty: {
            title: 'Pending Qty',
            hide: false,
            value: null,
            disable: true,
          },
          deliveryType: {
            title: 'SPO delivery Type',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          'sourcing.listPrice': {
            title: 'List Price',
            hide: false,
            value: null,
            disable: false,
          },
          'sourcing.diskReceived': {
            title: 'Discount',
            hide: false,
            value: null,
            disable: false,
          },
          tpUnit: {
            title: 'TP/Unit',
            hide: false,
            value: null,
            disable: true,
          },
          gm: {
            title: 'GM',
            hide: false,
            value: null,
            disable: true,
          },
          "delivery.dropship": {
            title: 'Dropship',
            hide: false,
            value: null,
            disable: false,
          },
          'sourcing.pickupWarehouse': {
            title: 'Pickup Warehouse ',
            hide: false,
            value: null,
            disable: false,
          },
          'sourcing.supplierPickupDueDate': {
            title: 'Committed Supplier Pickup Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
          },
          'sourcing.routingConfiguration': {
            title: 'CRMM Configuration',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          assignedSupplierId: {
            title: 'Supplier ID(AS)',
            hide: false,
            value: null,
            disable: true,
          },
          assignedSupplierName: {
            title: 'Supplier Name(AS)',
            hide: false,
            value: null,
            disable: true,
          },
          transferPrice: {
            title: 'TP(AS)',
            hide: false,
            value: null,
            disable: true,
          },
          assignedGmPercentage: {
            title: 'GM%(AS)',
            hide: false,
            value: null,
            disable: true,
          },
          mappingType: {
            title: 'CRMM / CPT(AS)',
            hide: false,
            value: null,
            disable: true,
          },
          assignedQuantity: {
            title: 'Assigned Quantity(AS)',
            hide: false,
            value: null,
            disable: true,
          },
          pickupDate: {
            title: 'Pickup Date(MM/DD/YYYY)(AS)',
            hide: false,
            value: null,
            disable: true,
          },
          revisedPickupDate: {
            title: 'Revised Supplier Pickup Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          // 'sourcing.supplierFollowDate': {
          //   title: 'Supplier Follow Date (MM/DD/YYYY)',
          //   hide: false,
          //   value: null,
          //   disable: false,
          // },
          'sourcing.supplierPickupDate': {
            title: 'Actual Supplier Pickup Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          inventoryAllocatedQuantity: {
            title: 'Inventory Allocated Quantity',
            hide: false,
            value: null,
            disable: true,
          },
          inventoryAllocationTime: {
            title: 'Inventory Allocation Time',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.sourcingRemarkToOmt': {
            title: 'Sourcing remark to OMT',
            hide: false,
            value: null,
            disable: false,
          },
          'remarks.remark9': {
            title: 'OMT Remark',
            hide: false,
            value: null,
            disable: false,
          },
          supplierPoRaisedDate: {
            title: 'Supplier Po Raised Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          broadStage: {
            title: 'Broad Stage',
            hide: false,
            value: null,
            disable: true,
          },
          supplierPoStatus: {
            title: 'Supplier Po Status',
            hide: false,
            value: null,
            disable: true,
          },
          // 'sourcing.omtStatus': {
          //   title: 'OMT Status',
          //   hide: false,
          //   value: null,
          //   disable: true,
          //   inputType: 'dropdown'
          // },
          'ems.poStatus': {
            title: 'PO Status',
            hide: false,
            value: null,
            disable: false,
          },
          supplierName: {
            title: 'Supplier Name',
            hide: false,
            value: null,
            disable: true,
          },
          supplierId: {
            title: 'Supplier ID',
            hide: false,
            value: null,
            disable: true,
          },
          supplierPoId: {
            title: 'Supplier PoId',
            hide: false,
            value: null,
            disable: true,
          },
          supplierPoItemId: {
            title: 'Supplier PO Item id',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.supplierCreditDays': {
            title: 'Supplier Credit Days',
            hide: false,
            value: null,
            disable: true,
          }
        }
      }
    }
    return lineObject;
  }

  convertSheetValueToObjectV3(data) { 
    let itemSheetModel = {};
    data.forEach(element => {
      let key = element.id;
      if (element.id) {
        itemSheetModel[key] = element.value;
      }
    });
    this.wholeItemSheetRowObj = itemSheetModel;
    return itemSheetModel;
  }


  getFieldValue(data: Object, field: String) {
    return lodash.has(data, field) ? data[`${field}`] : ''
  }

  public checkEmptyOrNull(value) {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
  }


  makeItemsData(data) {
    const poDataSet: any[] = [];
    const dataIndex: Object = {};
    let sourcerEmailsArray = [];
    let sheetDataIndex = -1;

    for (let i = 0; i < data.length; i++) {
      const lineItemObject = this.getViewLineItemsObj();
      const itemData = data[i];
      let plantName = this.getFieldValue(itemData, 'plantName');
      if (lodash.has(itemData, 'plantId') && this.checkEmptyOrNull(plantName)) {
        plantName = this.authService.getPlantNameFromId(itemData.plantId);
      }
      sheetDataIndex = sheetDataIndex + 1;
      dataIndex[itemData.itemId] = sheetDataIndex;
      lineItemObject.plantData.items.plantName.value = plantName;
      lineItemObject.supplierData.items.revisedPickupDate.value = this.getFieldValue(itemData, 'revisedSupplierPickupDate');
      lineItemObject.supplierData.items['sourcing.supplierPickupDate'].value = this.getFieldValue(itemData, 'actualSupplierPickupDate');
      lineItemObject.supplierData.items['sourcing.suggestSupplier'].value = this.getFieldValue(itemData, 'suggestSupplier');
      lineItemObject.supplierData.items['sourcing.suggestSupplierName'].value = this.getFieldValue(itemData, 'suggestedSupplierName');
      lineItemObject.supplierData.items['sourcing.suggestedSupplierPaymentTerms'].value = this.getFieldValue(itemData, 'suggestedSupplierPaymentTerms');
      lineItemObject.supplierData.items['sourcing.suggestSupplierId'].value = this.getFieldValue(itemData, 'suggestedSupplierId');
      lineItemObject.supplierData.items.supplierId.value = this.getFieldValue(itemData, 'supplierId');
      lineItemObject.supplierData.items.supplierName.value = this.getFieldValue(itemData, 'supplierName');
      lineItemObject.supplierData.items.itemId.value = this.getFieldValue(itemData, 'itemId');
      lineItemObject.plantData.items.plantId.value = this.getFieldValue(itemData, 'plantId');
      lineItemObject.plantData.items.customerPoNum.value = this.getFieldValue(itemData, 'customerPoNum');
      lineItemObject.supplierData.items.customerSANumber.value = this.getFieldValue(itemData, 'customerSANumber');
      lineItemObject.supplierData.items.warehouse.value = this.getFieldValue(itemData, 'warehouse');
      lineItemObject.plantData.items.materialDescription.value = this.getFieldValue(itemData, 'materialDescription');
      lineItemObject.plantData.items['sourcing.suggestedSupplierPaymentTermsId'].value = this.getFieldValue(itemData, 'suggestedSupplierPaymentTermsId');
      lineItemObject.supplierData.items.cpn.value = this.getFieldValue(itemData, 'cpn');
      lineItemObject.supplierData.items.brand.value = this.getFieldValue(itemData, 'brand');
      lineItemObject.supplierData.items['quantity.qty'].value = this.getFieldValue(itemData, 'qty');
      lineItemObject.supplierData.items['quantity.uom'].value = this.getFieldValue(itemData, 'uom');
      lineItemObject.supplierData.items.spUnit.value = this.getFieldValue(itemData, 'spUnit');
      lineItemObject.supplierData.items.tpUnit.value = this.getFieldValue(itemData, 'maskedTpUit');
      lineItemObject.supplierData.items.gm.value = this.getFieldValue(itemData, 'maskedGm');
      // lineItemObject.supplierData.items['sourcing.supplierFollowDate'].value = this.validationService.formatDate(this.getFieldValue(itemData, 'supplierFollowDate'));
      lineItemObject.supplierData.items.buyersPoId.value = this.getFieldValue(itemData, 'poId');
      lineItemObject.supplierData.items['remarks.remark9'].value = this.getFieldValue(itemData, 'remark9');
      lineItemObject.supplierData.items['ems.poStatus'].value = this.getFieldValue(itemData, 'poStatus');
      lineItemObject.supplierData.items.supplierPoId.value = this.getFieldValue(itemData, 'supplierPoId');
      lineItemObject.supplierData.items.supplierPoItemId.value = this.getFieldValue(itemData, 'supplierPoItemId');
      lineItemObject.supplierData.items['sourcing.diskReceived'].value = this.getFieldValue(itemData, 'discount');
      lineItemObject.supplierData.items['sourcing.listPrice'].value = this.getFieldValue(itemData, 'maskedListPrice');
      lineItemObject.supplierData.items['sourcing.listPrice'].disable = (this.getFieldValue(itemData, 'maskedListPrice').split("")[0] == PARAMS.STATIC_PARAMS.MASK_TEXT) ? true : false;
      lineItemObject.supplierData.items.pendingQty.value = this.getFieldValue(itemData, 'pendingQty');
      lineItemObject.supplierData.items.deliveryType.value = this.getFieldValue(itemData, 'deliveryType');
      lineItemObject.supplierData.items['product.extraDetails.clientData.annexure'].value = this.getFieldValue(data[i], 'additionalDescription');
      lineItemObject.supplierData.items['delivery.dropship'].value = this.getFieldValue(itemData, 'dropship');
      lineItemObject.supplierData.items.sourcerEmail.value = this.getFieldValue(itemData, 'sourcerEmail');
      lineItemObject.supplierData.items['sourcing.pickupWarehouse'].value = this.getFieldValue(itemData, 'pickupWarehouse');
      lineItemObject.supplierData.items['sourcing.supplierCreditDays'].value = this.getFieldValue(itemData, 'supplierCreditDays');
      lineItemObject.supplierData.items.supplierPoRaisedDate.value = this.getFieldValue(itemData, 'supplierPoRaisedDate');
      lineItemObject.supplierData.items.supplierPoStatus.value = this.getFieldValue(itemData, 'supplierPoStatus');
      lineItemObject.supplierData.items['sourcing.sourcingRemarkToOmt'].value = this.getFieldValue(itemData, 'sourcingRemarkToOmt');
      // lineItemObject.supplierData.items['sourcing.omtStatus'].value = this.getFieldValue(itemData, 'omtStatus');
      lineItemObject.supplierData.items['sourcing.supplierPickupDueDate'].value = this.validationService.formatDate(this.getFieldValue(itemData, 'committedSupplierPickupDate'));
      lineItemObject.supplierData.items.pendingQty.value = this.getFieldValue(itemData, 'pendingQty');
      lineItemObject.supplierData.items.inventoryAllocatedQuantity.value = this.getFieldValue(itemData, 'inventoryAllocatedQuantity');
      lineItemObject.supplierData.items.inventoryAllocationTime.value = this.getFieldValue(itemData, 'inventoryAllocationTime');
      lineItemObject.supplierData.items.broadStage.value = this.getFieldValue(itemData, 'broadStage');
      lineItemObject.supplierData.items.allocatedInventory.value = this.getFieldValue(itemData, 'assignedInventoryQty');
      lineItemObject.supplierData.items.assignedSupplierId.value = this.getFieldValue(itemData, 'assignedSupplierId');
      lineItemObject.supplierData.items.assignedSupplierName.value = this.getFieldValue(itemData, 'assignedSupplierName');
      lineItemObject.supplierData.items.transferPrice.value = this.getFieldValue(itemData, 'transferPrice');
      lineItemObject.supplierData.items.assignedGmPercentage.value = this.getFieldValue(itemData, 'assignedGmPercentage');
      lineItemObject.supplierData.items.mappingType.value = this.getFieldValue(itemData, 'mappingType');
      lineItemObject.supplierData.items.assignedQuantity.value = this.getFieldValue(itemData, 'assignedQuantity');
      lineItemObject.supplierData.items.pickupDate.value = this.validationService.formatDate(this.getFieldValue(itemData, 'pickupDate'));
      lineItemObject.supplierData.items['sourcing.routingConfiguration'].value = this.getFieldValue(itemData, 'routingConfiguration');
      poDataSet.push(lineItemObject)
    }
    return { poDataSet, dataIndex };
  }

  getListPriceForSave(listPrice) {
    if (isNaN(listPrice.toString())) {
      return null
    } else {
      return Number(listPrice)
    }
  }

  async makeDataForUpdateItemInSupplierSheet(data: any, editedItem?) {

    const sheetvalues = this.convertSheetValueToObjectV3(data);
    const itemSheetModel = editedItem.editObj;
    if (!(lodash.has(editedItem, 'id'))) {
      return null;
    }
    let sourcerEmailCheck: any = true;
    if (!this.checkEmptyOrNull(itemSheetModel.sourcerEmail)) {
      if (this.validationService.emailFormatValidationCheck(itemSheetModel.sourcerEmail)) {
        let emailcheck = [];
        itemSheetModel.sourcerEmail = itemSheetModel.sourcerEmail.toLowerCase();
        emailcheck.push(itemSheetModel.sourcerEmail)
        sourcerEmailCheck = await this.poItemService.checkSourcerEmail(emailcheck);
      }
      else {
        sourcerEmailCheck = false;
      }
    }
    if (sourcerEmailCheck) {
      let itemObj = this.convertFinalEditedObj(editedItem);
      Object.keys(itemObj).forEach(validationfield => {
        if (this.validationFieldType[validationfield] == "string") {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
        else if (this.validationFieldType[validationfield] == "number") {
          itemObj[validationfield] = this.validationService.getNumberFormat(itemObj[validationfield])
        }
        else if (this.validationFieldType[validationfield] == "date") {
          itemObj[validationfield] = this.validationService.getDateFormatToLongInt(itemObj[validationfield])
        }
        else {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
      });
      return itemObj;
    }
    else {
      return 'error';
    }

  }

  convertFinalEditedObj(data){
    if (data) {
      let itemSheetModel = data.editObj;
      if (Object.keys(data.editObj).length > 0) {
        itemSheetModel['_id'] = data.id;
      }
      return itemSheetModel;
    }
  }

  convertDataToCsv(data) {
    let csvData: Array<any> = [];
    let csvLine: Array<string> = [];
    if (data.length === 0) {
      return;
    }

    for (let i = 0; i < data[0].length; i++) {
      if (!data[0][i].hide) {
        csvLine.push(data[0][i].parent.key);
      }
    };

    csvData.push(csvLine.join(','));
    let longDataFields = ['itemId', 'buyersPoId'];
    for (let i = 0; i < data.length; i++) {
      csvLine = [];
      if (!data[i][0].value) {
        continue;
      }
      for (let j = 0; j < data[i].length; j++) {

        if (!data[i][j].hide) {
          let parsedData = (data[i][j].value) ? String(data[i][j].value) : '';
          if (longDataFields.includes(data[i][j].id)) {
            parsedData = parsedData + '\t';
          }
          parsedData = parsedData.replace(/"/g, "");
          parsedData = '"' + parsedData + '"';
          csvLine.push(parsedData);
        }
      }
      csvData.push(csvLine.join(','));
    }

    return csvData.join('\n');
  }

  calculateTpUnit(listPrice, discountEntered) {
    const discount = discountEntered == "" ? 0 : discountEntered;
    if ((!isNaN(Number(listPrice)) && !isNaN(Number(parseFloat(discount))))
      && String(listPrice).length > 0 && String(discount).length > 0 && listPrice !== null && discountEntered !== null) {
      return (parseFloat(listPrice) * (100 - (discount ? parseFloat(discount) : 0)) / 100).toFixed(4);
    } else {
      return '';
    }
  }

  calculateGm(tpUnit, spUnit) {
    if ((!isNaN(Number(tpUnit)) && !isNaN(Number(spUnit)))
      && String(spUnit).length > 0 && String(tpUnit).length > 0) {
      return ((parseFloat(spUnit) - parseFloat(tpUnit)) / parseFloat(spUnit) * 100).toFixed(4);
    } else {
      return '';
    }
  }

  calculateNewGm(tpUnit, effectivefinalspUnit, suggestedSupplierPaymentTerms, customerCreditdays) {
    suggestedSupplierPaymentTerms = suggestedSupplierPaymentTerms.toString().match(/\d+/) ? parseFloat(suggestedSupplierPaymentTerms.toString().match(/\d+/)[0]) : null;
    if ((!isNaN(Number(tpUnit)) && !isNaN(Number(effectivefinalspUnit)))
      && String(effectivefinalspUnit).length > 0 && String(tpUnit).length > 0 && (!this.validationService.checkEmptyOrNull(suggestedSupplierPaymentTerms) || suggestedSupplierPaymentTerms == 0) && (!this.validationService.checkEmptyOrNull(customerCreditdays) || customerCreditdays == 0)) {
      return (((parseFloat(effectivefinalspUnit) - parseFloat(tpUnit)) / parseFloat(effectivefinalspUnit) * 100) + ((suggestedSupplierPaymentTerms - parseFloat(customerCreditdays))/30)).toFixed(2);
    } else {
      return '';
    }
  }

  calculateGmStar(gm, customerCreditDays) {
    if ((!isNaN(Number(gm)) && !isNaN(Number(customerCreditDays)))
      && String(gm).length > 0 && String(customerCreditDays).length > 0) {
      return (parseFloat(gm) - parseFloat(customerCreditDays) / 30 * 1.5).toFixed(2);
    } else {
      return '';
    }
  }

  calculateTotalPreTaxValue(qty, spUnit) {
    return (parseFloat(qty) * parseFloat(spUnit)).toFixed(2);
  }

  calculateInvoiceValue(totalPreTaxValue, tax) {
    return (parseFloat(totalPreTaxValue) * (1 + parseFloat(tax) / 100)).toFixed(2);
  }

  calculateDateDifference(first, second) {
    return isNaN(moment.duration(moment(first, 'MM/DD/YYYY').diff(moment(second, 'MM/DD/YYYY'))).asDays()) ? '' : moment.duration(moment(first, 'MM/DD/YYYY').diff(moment(second, 'MM/DD/YYYY'))).asDays();
  }

}
