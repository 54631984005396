import { Injectable } from '@angular/core';
import * as moment from "moment";

import { ApiService } from './api.service';
import { environment as env } from '../../environments/environment';
import { PARAMS } from '../config/params'
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import * as lodash from 'lodash-es';
import { BuyerService } from '@app/services/buyer.service';
import { SafeResourceUrl } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class PoService {

  poObject: Array<any> = [];
  poMap: any;
  suppliers: Array<any> = [];
  purchasers: Array<any> = [];
  poIds: Array<string> = [];
  selectedPos: Array<string> = [];
  selectedPosChange: Subject<any> = new Subject<any>();
  items: Array<any> = [];
  itemsData: Array<any> = [];
  punchPoData: any = {};
  internalPlantForPO = false;
  itemDataClone: Array<any> = [];
  cancelAndCloneItems: Array<any> = [];
  addReturnItemId: String = '';
  repeatPoItemId: String = '';
  editCPONumberItemId: String = '';
  attachments: any = [];
  rfqByIdData: any = {};
  cancelCloneReasonAndRemark: any = {};
  isPaasPlant: boolean = false;
  ocrAttachments: any = {}
  ocrData: any = {};
  saveClicked: boolean = false;

  public readonly PO_STATUS_REJECTED = 4;

  poStatus = {
    1: 'PO Received', 2: 'PO Punched', 3: 'MIS approval pending', 4: 'MSN pending',
    5: 'Product Specs pending', 6: 'Inventory Check pending', 7: 'Vendor registration pending',
    8: 'Vendor assignment pending', 9: 'To be shared with CPT', 10: 'Shared With CPT',
    11: 'WH Inventory', 12: 'PO to supplier pending', 13: 'GM% approval pending',
    14: 'Advance Payment pending', 15: 'Supplier OD issue', 16: 'Commited Pickup date not Mentioned',
    17: 'Pick up/ Dispatch from vendor pending', 18: 'Partial Pick up/ Dispatch from vendor pending',
    19: 'Lead time product, follow up required', 20: 'On Hold', 21: 'Cancelled / Closed', 22: 'QC_FAIL',
    23: 'MRN Pending', 24: 'Invoice pending', 25: 'Partial Invoice pending', 26: 'Customer Credit Limit Exceeded',
    27: 'Dispatch pending', 28: 'Partial dispatch pending', 29: 'Delivered', 30: 'Partially Delivered',
    31: 'Returned/ Rejected by customer', 32: 'Credit Note Generated', 33: 'Issues/Clarification',
    34: 'Supplier Po Raised', 35: 'BLANK', 36: 'Order In (CPT)', 37: 'Sourcing Done (CPT)',
    38: 'Pickup Aligned (CPT)', 39: 'CPT Clarification required (CPT)', 40: 'Pickup Done (CPT)',
    41: 'Approval pending (CPT)', 42: 'Pick up pending (CPT)', 43: 'Approved (CPT)', 44: 'Sourcing Not Done (CPT)',
    45: 'Rejection Pending (CPT)', 46: 'MRN Done', 47: 'Dispatched', 48: 'Partial MRN Done', 49: 'Warehouse Inventory Allocated', 50: 'Clarified by Business team', 51: 'Approval mail shared', 52: 'Payment issue'
  };

  poAction: string;
  public static readonly PO_ACTION_PO_VIEW = 'viewPO';
  public static readonly PO_ACTION_ITEMS_SHEET = 'itemsSheet';
  public static readonly PO_ACTION_SUPPLIER_SHEET = 'supplierSheet';
  public static readonly PO_ACTION_CATALOG_SHEET = 'catalogSheet';
  public static readonly PO_ACTION_OMT_SHEET = 'omtSheet';
  public static readonly PO_ACTION_SA_SHEET = 'saSheet';
  public static readonly PO_ACTION_SA_SA_EDIT = 'editSA';
  public static readonly PO_ACTION_VIEW_MULTIPLE = 'viewMultiplePO';
  public static readonly PO_ACTION_CREATE = 'createPO';
  public static readonly PO_ACTION_REPEAT = 'repeatPO';
  public static readonly PO_ACTION_EDIT_CPO_NUMBER = 'editCPONumber';
  public static readonly PO_ACTION_MARK_RELEASE = 'markRelease';
  public static readonly PO_ACTION_PUSH_TO_SALESOPS = 'pushToSop';
  public static readonly SA_ACTION_CREATE = 'createSA';
  public static readonly PO_ACTION_UPDATE = 'updatePO';
  public static readonly PO_ACTION_EDIT_PO = 'editPO';
  public static readonly PO_ACTION_EXTEND_PO = 'extendPO';
  public static readonly PO_ACTION_CANCEL_AND_CLONE = 'cancelAndClonePO';
  public static readonly PO_ACTION_DIRECT_PO = 'directPo';
  public static readonly PO_ACTION_LIQUIDATION_PO = 'liquidationPo';
  public static readonly PO_ACTION_APPROVE = 'approvePO';
  public static readonly PO_ACTION_ADD_RETURN_ITEM = 'addReturnItem';
  public static readonly PO_ACTION_CANCEL_AND_CLONE_ITEM = 'cancelAndCloneItem';
  public static readonly PO_ACTION_RFQ_SHEET = 'rfqSheet';
  public static readonly PO_ACTION_VIEW_SOURCINGWORKSPACE = 'sourcingWorkSpace';
  public static readonly SOURCINGWORKSPACE_TYPE_MSN = 'msnPending';
  public static readonly SOURCINGWORKSPACE_TYPE_SPO = 'spoPending';
  public static readonly SOURCINGWORKSPACE_TYPE_TODAY_PICKUP = 'todaysPickup';
  public static readonly SOURCINGWORKSPACE_TYPE_FAILED_PICKUP = 'failedPickup';
  public static readonly PO_ACTION_LIST_SHEET_TYPE = ["createPO", "repeatPO", "approvePO"];
  serviceChargesData: any;


  constructor(private apiService: ApiService, private buyerService: BuyerService) {

  }

  setItemDataClone(itemDataClone) {
    this.itemDataClone = itemDataClone;
  }
  getItemDataClone(): Array<any> {
    return this.itemDataClone;
  }

  setCancelAndCloneItems(items) {
    this.cancelAndCloneItems = items;
  }
  getCancelAndCloneItems(): Array<any> {
    return this.cancelAndCloneItems;
  }

  setAddReturnItemId(item) {
    this.addReturnItemId = item;
  }
  getAddReturnItemId(): String {
    return this.addReturnItemId;
  }

  setPoObject(poObject) {
    this.poObject = poObject;
  }
  getPoObject(): Array<any> {
    return this.poObject;
  }

  setPoIdForRepeatPo(poId) {
    this.repeatPoItemId = poId;
  }

  getPoIdForRepeatPo() {
    return this.repeatPoItemId;
  }

  setPoMap(_poMap) {
    this.poMap = _poMap;
  }
  getPoFromId(_id) {
    if (this.poMap) return this.poMap[_id];
    return null;
  }

  setPoAction(action) {
    localStorage.setItem('poAction', action);
    this.poAction = action;
  }
  getPoAction() {
    if (!this.poAction) {
      this.poAction = localStorage.getItem('poAction');
    }
    return this.poAction;
  }

  setPoIds(poIds) {
    localStorage.setItem('poIds', JSON.stringify(poIds));
    //this.poIds = poIds;
  }

  getPoIds(): Array<string> {
    this.poIds = JSON.parse(localStorage.getItem('poIds'));
    return this.poIds;
  }

  setPunchPoData(punchPoData) {
    this.punchPoData = punchPoData;
  }

  resetPunchPoData() {
    this.punchPoData = {};
  }

  getPunchPoData(): any {
    return this.punchPoData;
  }

  setRfqByIdData(punchPoData) {
    this.rfqByIdData = punchPoData;
  }

  resetRfqByIdData() {
    this.rfqByIdData = {};
  }

  getRfqByIdData(): any {
    return this.rfqByIdData;
  }

  setArcTaggedPoData(i: number, arcRfqType: string) {
    this.punchPoData.items[i].arcRfqType = arcRfqType;
  }
  
  setCategoryDataPo(i, l1Cat, l2Cat) {
    this.punchPoData.items[i].l1Category = l1Cat;
    this.punchPoData.items[i].l2Category = l2Cat;
  }

  getSelectedPos(): Array<any> {
    return this.selectedPos;
  }

  setSelectedPos(selectedPos: Array<string>) {
    this.selectedPos = selectedPos;
    this.selectedPosChange.next(selectedPos);
  }

  setCancelCloneReasonAndRemark(reasonAndRemark: any) {
    this.cancelCloneReasonAndRemark = reasonAndRemark;
  }

  getCancelCloneReasonAndRemark(): any {
    return this.cancelCloneReasonAndRemark;
  }

  resetCancelCloneReasonAndRemark() {
    this.cancelCloneReasonAndRemark = {};
  }

  setIsPaasPlant(plantType: boolean) {
    this.isPaasPlant = plantType;
  }

  getIsPaasPlant() {
    return this.isPaasPlant;
  }

  getOcrAttachments(): any {
    return this.ocrAttachments;
  }

  setOcrAttachments(ocrAttachments: any) {
    this.ocrAttachments = ocrAttachments;
  }

  getOcrData(): any {
    return this.ocrData;
  }

  setOcrData(ocrData: any) {
    this.ocrData = ocrData;
  }

  setSaveClicked(saveClicked: boolean) {
    this.saveClicked = saveClicked;
  }

  getSaveClicked(): boolean {
    return this.saveClicked;
  }

  getPoState(data) {
    let result = '0';
    if (lodash.has(data, 'sapDetails.poState')) {
      if (data.sapDetails.poState === 'open') {
        result = '1';
      }
    }
    return result;
  }

  getPoReleaseState(data) {
    let result = '0';
    if (lodash.has(data, 'sapDetails.releaseFlag')) {
      if (data.sapDetails.releaseFlag === 'G' || data.sapDetails.releaseFlag === '3') {
        result = '0';
      } else {
        result = '1';
      }
    }
    return result;
  }

  setServiceChargesData(data){
    this.serviceChargesData=data
  }
  getServiceChargesData(){
    return this.serviceChargesData;
  }

  /**
   * This function save PO data by handshaking with BuyerInternal backend to maintain the
   * state of data entered by user. It should not be confused with PO punching which happens
   * at Buyer platform.
   *
   * @param data
   * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
   */
  public draftPoData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DRAFT_PO_DATA.URL}`;
    return this.apiService.post(url, data);
  }


  /**
   * Return any PO data saved against a user and not submitted yet for approval
   *
   * @param data
   * @author Kuldeep Dangi <kuldeep.dangi@moglix.com>
   */
  public getDraftedPoData(userId: number) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_BY_USER.URL}/${userId}`;
    return this.apiService.get(url);
  }

  public search(queryString, data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.SEARCH.URL}${queryString}`;
    return this.apiService.post(url, data);
  }

  public searchPosToApprove(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_BY_PLANTS.URL}`;
    return this.apiService.post(url, data);
  }

  public getAllSubmittedDraftByUser(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_PO_DRAFT_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public itemSearch(queryString, data) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.ITEM_SEARCH.URL}${queryString}`;
    return this.apiService.post(url, data);
  }

  public getPurchasers(data): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.PURCHASER.GET_PURCHASERS.URL}`;
    return this.apiService.post(url, data);
  }

  public getCompanyDetails(data): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.PLANTS.GET_COMPANY_DETAILS.URL}/${data.idCompany}`;
    return this.apiService.get(url);
  }

  public getWarehouses(): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.WAREHOUSE.GET_ALL.URL}`;
    return this.apiService.get(url);
  }

  public getWarehousesByCountryCode(countryCode?: number): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.WAREHOUSE.GET_ALL.URL}?country-code=${countryCode}`;
    return this.apiService.get(url);
  }

  getWareHouseByMSN(body?: any) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.GET_WAREHOUSES_BY_MSN.URL}`;
    return this.apiService.post(url, body);
  }

  public getSupplierSuggestions(key: string): Observable<any> {
    // let url = `${env.suppliers.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.SUPPLIERS.SEARCH.URL}?key=${key}`;
    let url = `${env.searchSupplier.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.SUPPLIERS.SEARCH_SUPPLIER.URL}?key=${key}`;
    return this.apiService.get(url);
  }

  public getSupplierSuggestionsNew(key: string): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.SUPPLIERS.SEARCH_SUPPLIER_NEW.URL}${key}`;
    return this.apiService.get(url);
  }

  public getSupplierSuggestionsPost(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.SUPPLIERS.SEARCH_SUPPLIER_POST.URL}`;
    return this.apiService.post(url, data);
  }

  public getSupplierPaymentTerms(data: Object): Observable<any> {
    let url = `${env.suppliers.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.SUPPLIERS.GET_SUPPLIER_PAYMENT_TERMS.URL}`;
    return this.apiService.post(url, data);
  }

  public getTeamFromUserId(data): Observable<any> {
    let url = `${env.salesOps.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SALESOPS.GET_USER_TEAMS.URL}`;
    return this.apiService.post(url, data);
  }

  public getTeamList(): Observable<any> {
    let url = `${env.ems.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.EMS.GET_TEAM_DETAILS.URL}`;
    return this.apiService.get(url);
  }

  public getPlantIdsFromTeam(data): Observable<any> {
    let url = `${env.ems.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.EMS.GET_TEAM_PLANT_MAPPING.URL}`;
    return this.apiService.post(url, data);
  }

  public getPaymentTerms(groupId: number, plantId: number): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.PAYMENT_TERMS.URL}${groupId}/buyer/${plantId}`;
    return this.apiService.get(url);
  }

  public getDetails(id: string): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.DETAIL.URL}${id}`;
    return this.apiService.get(url);
  }

  public validateRfqCpoType(data): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.VALIDATE_RFQ_CPO_TYPE.URL}`;
    return this.apiService.post(url, data);
  }

  public checkCddInPoItems(data): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.CHECK_CDD_FOR_PO_ITEM.URL}`;
    return this.apiService.post(url, data);
  }

  public getTimeFromTimestamp(ts, isNoTime?: boolean) {
    let d = new Date(ts);
    let dateFormatString = "MM/DD/YYYY hh:mm a";
    if (isNoTime) dateFormatString = "MM/DD/YYYY";
    let dateString = moment(d).format(dateFormatString);
    return dateString;
  }

  public getAddressses(data): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.PLANTS.GET_ADDRESS_BY_BRANCH.URL}`;
    return this.apiService.post(url, data);
  }


  fileUpload(url: string, body?: any) {
    return this.apiService.post(url, body);
  }

  createPo(data): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.CREATE.URL}`;
    return this.apiService.put(url, data);
  }

  cancelPo(poId: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.DETAIL.URL}` + poId + `/cancel`;
    return this.apiService.post(url, data);
  }

  getTaxRateFromHsn(data: any): Observable<any> {
    data['countrycode'] = this.buyerService.getPlantData().branch ? this.buyerService.getPlantData().branch.countryCode : null;
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PRODUCT.GET_TAX_RATE_FROM_HSN.URL}`;
    return this.apiService.post(url, data);
  }

  public itemSearchFromMongo(url: string, data: any) {
    return this.apiService.post(url, data);
  }

  public productSearch(queryString, data) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PRODUCT.FETCH_ALL.URL}${queryString}`;
    return this.apiService.post(url, data);
  }

  public getPo(id: string): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.GET_BY_ID.URL}`;
    return this.apiService.get(url + id);
  }

  public getDraftPo(id: string): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_PO_DATA.URL}`;
    return this.apiService.get(url + id);
  }

  updatePo(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO.URL}` + id + `/update`;
    return this.apiService.post(url, data);
  }

  updateItem(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_ITEM.URL}` + id + `/update`;
    return this.apiService.post(url, data);
  }

  cancelAndClonePo(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO.URL}` + id + `/cancelAndClone`;
    return this.apiService.post(url, data);
  }

  editCPONumber(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.EDIT_CPO_NUMBER.URL}`;
    return this.apiService.post(url, data);
  }

  cancelAndCloneItem(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO.URL}` + id + `/cancelAndCloneItem`;
    return this.apiService.post(url, data);
  }

  updateItems(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.UPDATE_ITEMS.URL}`;
    return this.apiService.post(url, data);
  }

  uploadItemExcel(itemFile) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.UPLOAD_ITEM_EXCEL.URL}`;
    return this.apiService.post(url, itemFile);
  }

  downloadTemplateExcel() {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DOWNLOAD_TEMPLATE.URL}`;
    return this.apiService.get(url);
  }

  downloadPoExcel(data: any) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.DOWNLOAD_PO.URL}`;
    return this.apiService.post(url, data);
  }

  public setUploadProofofOrderData(attachment) {
    this.attachments = attachment;
  }

  public bulkUpload(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.BULK_UPLOAD.URL}`;
    return this.apiService.post(url, data);
  }

  resetUploadProofofOrderData() {
    this.attachments = [];
    return this.attachments;
  }

  getUploadProofofOrderData(): any {
    return this.attachments;
  }

  updateAttachmentsPo(data: any) {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO_ATTACHMENTS.URL}`;
    return this.apiService.post(url, data);
  }

  public bulkPushToScm(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.BULK_UPLOAD_PUSH_TO_SCM.URL}`;
    return this.apiService.post(url, data);
  }

  public bulkAddressUpload(data: any): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.BULK_ADDRESS_UPLOAD.URL}`;
    return this.apiService.post(url, data);
  }

  public getBatchDetails(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.PO_BATCH_DETAILS.URL}`;
    return this.apiService.post(url, data);
  }

  public getDownloadData(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DOWNLOAD_BATCH.URL}`;
    return this.apiService.post(url, data);
  }

  public cancelItem(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.CANCEL_ITEM.URL}`;
    return this.apiService.post(url, data);
  }

  public getMsnPendingListData(): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SOURCE_WORKSPACE.MSN_PENDING_LIST.URL}`;
    return this.apiService.get(url);
  }

  public getSpoPendingListData(): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SOURCE_WORKSPACE.SPO_PENDING_LIST.URL}`;
    return this.apiService.get(url);
  }

  public getBatchAddressDetails(): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.ADDRESS_BATCH_DETAILS.URL}`;
    return this.apiService.get(url);
  }

  public getAddressDownloadData(batchId): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DOWNLOAD_BATCH_ADDRESS.URL}${batchId}`
    return this.apiService.get(url);
  }

  public uploadBulkOrderMapping(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.BULK_ORDER_MAPPING.URL}`;
    return this.apiService.post(url, data);
  }

  public saveQuoteData(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.QUOTE_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public saveAutoQuoteAfterGmApproval(data: any) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.AUTO_QUOTE.URL}`
    return this.apiService.post(url, data);
  }

  public getTodaysPickupListData(): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SOURCE_WORKSPACE.TODAYS_PICKUP_LIST.URL}`;
    return this.apiService.get(url);
  }

  public getFailedPickupListData(): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.SOURCE_WORKSPACE.FAILED_PICKUP_LIST.URL}`;
    return this.apiService.get(url);
  }
  public getTatList(queryString, data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ETA.GET_TAT_LIST.URL}${queryString}`;
    return this.apiService.post(url, data);
  }

  updatePoNew(id: string, data: any): Observable<any> {
    let url = `${env.procurement.baseUrl}${PARAMS.PROCUREMENT.MODULES.PO.UPDATE_PO.URL}${id}/update-poc`;
    return this.apiService.post(url, data);
  }

  public bulkCatologUpload(data: any): Observable<any> {
    let url = `${env.meCatalogue.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.CATALOGUE.BULK_UPLOAD.URL}`;
    return this.apiService.post(url, data);
  }

  public downloadSheetData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DOWNLOAD_SHEET_REPORT.URL}`;
    return this.apiService.post(url, data);
  }

  public getRfqSuggestionData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.RFQ_SUGGESTION_LIST.URL}`;
    return this.apiService.post(url, data);
  }
  public getCpoOcrDetails(data) {
    let url = `${env.analyitcs.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_CPO_OCR.URL}`;
    return this.apiService.post(url, data);
  }

  public isRoutingArcRfqTypeAllowedForPlantId(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.IS_ROUTING_ALLOWED_FOR_PLANT.URL}`;
    return this.apiService.post(url, data);
  }

  public checkIsArc(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.CHECK_IS_ARC.URL}`;
    return this.apiService.post(url, data);
  }

  public checkGovrnedMsn(msn) {
    let url = `${env.platform.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.CATALOGUE.GOVERNED_MSN_CHECK.URL}${msn}`;
    return this.apiService.get(url);
  }

  extenPoExpiry(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.EXTEND_EXPIRY_PO.URL}`;
    return this.apiService.post(url, data);
  }

  checkIsAllItemsArc(arcResponce: Array<any>) {
    let bolArrayOfRes: Array<boolean> = arcResponce.map((item: any) => item.arc);
    return !bolArrayOfRes.includes(false)
  }
  getCustomerPaymentTerms(queryString) {
    let url = `${env.accountService.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_CUSTOMER_PAYMENT_TERMS.URL}${queryString}`;
    return this.apiService.get(url);
  }

  public isDirectPoCheck(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.IS_DIRECT_PO.URL}`;
    return this.apiService.post(url, data);
  }

  public getUserDetailsByManager(data) {
    let url = `${env.accountService.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_USER_DETAILS_BY_MANAGER.URL}`;
    return this.apiService.post(url, data);
  }

  public getProductivityDashboardUserData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_PRODUCTIVITY_DASHBOARD_USER_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public downloadProductivityReport(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.DOWNLOAD_PRODUCTIVITY_DASHBOARD_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public refreshProductivityDashboardData(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.REFRESH_PRODUCTIVITY_DASHBOARD_DATA.URL}`;
    return this.apiService.post(url, data);
  }

  public getProductivityOutcome(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_PRODUCTIVITY_OUTCOME.URL}`;
    return this.apiService.post(url, data);
  }

  public getCategoryData(data) {
    let url = `${env.msnSuggesstion.baseUrl}product_suggestion/category/search`;
    return this.apiService.post(url, data);
  }

  public updateCrmmApproval(data) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.UPDATE_CRMM_APPROVAL.URL}`;
    return this.apiService.post(url, data);
  }

  public uploadCpoSpoReport(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.BULK_PUNCHING.UPLOAD_CPO_SPO_REPORT.URL}`;
    return this.apiService.post(url, data);
  }

  public getCpoSpoReport(data: any, pageNumber: number, pageSize: number): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.BULK_PUNCHING.GET_CPO_SPO_REPORT.URL}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.apiService.post(url, data);
  }

  public getUploadPOCopyListing(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.BULK_PUNCHING.GET_CPO_SPO_UPLOAD_PO_COPY.URL}`;
    return this.apiService.post(url, data);
  }

  public uploadPOCopy(data: any): Observable<any> {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.BULK_PUNCHING.UPLOAD_BULK_PO_COPY.URL}`;
    return this.apiService.post(url, data);
  }

  /**
   * storing filter in local storage on the basis of active sheet
   * @author Manoj Meghwal 
   */
  public setFilters(value, sheet) {
    if (sheet == PoService.PO_ACTION_RFQ_SHEET) {
      localStorage.setItem('rfqsheetFilters', JSON.stringify(value));
    }
    else if (sheet == PoService.PO_ACTION_ITEMS_SHEET) {
      localStorage.setItem('itemsheetFilters', JSON.stringify(value));
    }
    // else if (sheet == PoService.PO_ACTION_CATALOG_SHEET) {
    //   localStorage.setItem('catalogsheetFilters', JSON.stringify(value));
    // }
    // else if (sheet == PoService.PO_ACTION_SUPPLIER_SHEET) {
    //   localStorage.setItem('suppliersheetFilters', JSON.stringify(value));
    // }
    // else if (sheet == PoService.PO_ACTION_OMT_SHEET) {
    //   localStorage.setItem('omtsheetFilters', JSON.stringify(value));
    // }
    // else if (sheet == PoService.PO_ACTION_SA_SHEET) {
    //   localStorage.setItem('sasheetFilters', JSON.stringify(value));
    // }
  }

  /**
   * get the stored filters from local storage according to selected sheet 
   * @param sheet  active sheet name
   * @returns stored filter data
   * @author Manoj Meghwal
   */
  public getFilters(sheet) {
    if (sheet == PoService.PO_ACTION_RFQ_SHEET) {
      return JSON.parse(localStorage.getItem('rfqsheetFilters'));
    }
    else if (sheet == PoService.PO_ACTION_ITEMS_SHEET) {
      return JSON.parse(localStorage.getItem('itemsheetFilters'));
    }
    // else if (sheet == PoService.PO_ACTION_CATALOG_SHEET) {
    //   return JSON.parse(localStorage.getItem('catalogsheetFilters'));
    // }
    // else if (sheet == PoService.PO_ACTION_SUPPLIER_SHEET) {
    //   return JSON.parse(localStorage.getItem('suppliersheetFilters'));
    // }
    // else if (sheet == PoService.PO_ACTION_OMT_SHEET) {
    //   return JSON.parse(localStorage.getItem('omtsheetFilters'));
    // }
    // else if (sheet == PoService.PO_ACTION_SA_SHEET) {
    //   return JSON.parse(localStorage.getItem('sasheetFilters'));
    // }
    else {
      return null;
    }
  }
  /**
   * reset stored filter based on the selected sheet
   * @param sheet active sheet name
   * @author Manoj Meghwal 
   */
  public resetFilters(sheet) {
    //localStorage.removeItem("sheetFilters");
    if (sheet == PoService.PO_ACTION_RFQ_SHEET) {
      localStorage.removeItem("rfqsheetFilters");
    }
    else if (sheet == PoService.PO_ACTION_ITEMS_SHEET) {
      localStorage.removeItem("itemsheetFilters");
    }
    else if (sheet == PoService.PO_ACTION_CATALOG_SHEET) {
      localStorage.removeItem("catalogsheetFilters");
    }
    else if (sheet == PoService.PO_ACTION_SUPPLIER_SHEET) {
      localStorage.removeItem("suppliersheetFilters");
    }
    else if (sheet == PoService.PO_ACTION_OMT_SHEET) {
      localStorage.removeItem("omtsheetFilters");
    }
    else if (sheet == PoService.PO_ACTION_SA_SHEET) {
      localStorage.removeItem("sasheetFilters");
    }
  }
  public getCustomerPocList(): Observable<any> {
    let url = `${env.accountService.baseUrl}${PARAMS.USER_MANAGEMENT.MODULES.CUSTOMER_POC.GET_CUSTOMER_POC.URL}`;
    return this.apiService.get(url);
  }

  getSupplierPaymentTermList(itemId: string) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.GET_SUPPLIER_PAYMENT_TERM_LIST.URL}/${itemId}`;
    return this.apiService.get(url);
  }

  getPRSheetGloablFilterList() {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.RFQ.GET_PR_SHEET_GLOBAL_FILTERS.URL}`;
    return this.apiService.get(url);
  }
  getPlantWiseArcTypeMapping(plantId: string) {
    let url = `${env.accountService.baseUrl}${PARAMS.ACCOUNT_SERVICE.MODULES.PLANTS.PLANT_ACCOUNT_TYPE_MAPPING.URL}/${plantId}`;
    return this.apiService.get(url);
  }

  getServiceChargesDetails(data){
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_SERVICE_CHARGE_TAX_DETAILS.URL}`;
    return this.apiService.post(url,data);
  }

  getServiceChargeDetailsByPlantId(plantId:string){
    let url = `${env.accountService.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_SERVICE_CHARGE_DETAILS_BY_PLANT_ID.URL}/${plantId}`;
    return this.apiService.get(url);
  }
  
  getLatestBulkPoBatchData() {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_LATEST_BULK_PO_DATA.URL}`;
    return this.apiService.get(url);
  }

  getBulkPoDataByBatchId(batchId: string) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_BULK_PO_BY_BATCH_ID.URL}/${batchId}`;
    return this.apiService.get(url);
  }

  uploadBulkPOCopy(data: any) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.UPLOAD_BULK_PO_COPY.URL}`;
    return this.apiService.post(url, data);
  }

  populateStoreLocation(plantId: string) {
    let url = `${env.accountService.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GET_STORE_LOCATION_BASED_ON_PLANT.URL}/${plantId}`;
    return this.apiService.get(url);
  }

  updateDirectPO(data: any) {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.UPDATE_DIRECT_PO.URL}`;
    return this.apiService.post(url, data);
  }

  genAIDataExtraction(data: any) {
    let url = `${env.genAI.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.PO.GEN_AI_DATA_EXTRACTION.URL}`;
    return this.apiService.post(url, data);
  }

  getCurrentDate() {
    let url = `${env.buyerInternal.baseUrl}${PARAMS.BUYER_INTERNAL.MODULES.ITEM.GET_CURRENT_DATE_TIME.URL}`;
    return this.apiService.get(url);
  }
}
