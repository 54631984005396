export const PARAMS = {
  AUTH: {
    GOOGLE: {
      KEY: "527261541486-etbg81kjqg406g2j204sp2bd161qvloi.apps.googleusercontent.com",
    },
    MICROSOFT: {
      QAKEY: "7ef76858-5a61-4c38-9937-e94e999bac4a",
      PRODKEY: "49bee008-fe4a-4b36-85e0-fd94a9c9dd4d"
    }
  },
  PROCUREMENT: {
    MODULES: {
      PO: {
        SEARCH: {
          URL: "po/es/search",
        },
        ITEM_SEARCH: {
          URL: "procurement/item/es/search",
        },
        DETAIL: {
          URL: "procurement/po/",
        },
        CREATE: {
          URL: "procurement/po/create",
        },
        GET_BY_ID: {
          URL: "procurement/po/",
        },
        UPDATE_PO: {
          URL: "procurement/po/",
        },
        EDIT_CPO_NUMBER: {
          URL: "po/update-po-number",
        },
        DOWNLOAD_PO: {
          URL: "procurement/po/download/ordersEoC",
        },
        UPDATE_ITEM: {
          URL: "procurement/item/",
        },
        PAYMENT_TERMS: {
          URL: "procurement/terms/vendor/",
        },
        ITEM_MONTHLY_DATA: {
          URL: "procurement/item/getMonthlyData",
        },
        ITEM_COMPANY_DATA: {
          URL: "item/getCompanywiseData",
        },
        INVOICE_TARGET: {
          URL: "procurement/item/saveInvoiceTarget",
        },
        ITEM_DATA: {
          URL: "item/getItemCountAndValue",
        },
        UPDATE_PO_ATTACHMENTS: {
          URL: "procurement/po/update/attachments",
        },
      },
      PRODUCT: {
        PRODUCT: {
          URL: "procurement/product",
        },
        GET_TAX_RATE_FROM_HSN: {
          URL: "procurement/product/hsn/taxrate",
        },
        SEARCH: {
          URL: "procurement/product/search",
        },
        FIND_BY_IDS: {
          URL: "procurement/product/findByIds",
        },
        FETCH_ALL: {
          URL: "procurement/product/search",
        },
        SEARCH_BRAND_BY_KEYWORD: {
          URL: "procurement/brand/search",
        },
        GET_ALL_UOM: {
          URL: "procurement/unit/search",
        },
        CREATE: {
          URL: "procurement/product/create",
        },
        GET_ALL_PRODUCT_TYPES: {
          URL: "procurement/type/search",
        },
      },
      SERVICEABILITY: {
        RETRIEVE_UPLOADED_FILES: {
          URL: "lpp_serviceablity/lpp_status"
        },
        RETRIEVE_CUSTOMERS: {
          URL: "freq_report/dimsysCustomers"
        },
        UPLOAD_FILE: {
          URL: "lpp_serviceablity/lpp_upload"
        },
        DOWNLOAD_FILE: {
          URL: "freq_report/download_desc?meta_data_id"
        },
        UPDATE_USER_ROLES: {
          URL: "serviceability/updateUserRoles"
        }
      }
    },
  },
  //   USER_MANAGEMENT: {
  //     MODULES: {
  //       AUTH: {
  //         GET_SESSION: {
  //           URL: "login/eoc/getSession",
  //         },
  //         LOGIN: {
  //           URL: "login/auth/login",
  //           GOOGLE_URL: "login/eoc/login",
  //         },
  //         LOGOUT: {
  //           URL: "login/auth/logout",
  //         },
  //         FORGET_PASSWORD: {
  //           URL: "auth/forgotPassword",
  //         },
  //         RESET_PASSWORD: {
  //           URL: "auth/resetPassword",
  //         },
  //         GET_BRANCH_ACCESS: {
  //           URL: "login/user/getBranchAccess",
  //         },
  //         AUTHORIZE_GOOGLE_LOGIN: {
  //           URL: "account/auth/login",
  //         },
  //       },
  //       USER: {
  //         GET_BY_BRANCH: {
  //           URL: "login/user/getByBranch",
  //         },
  //         UPDATE_APPROVE_PO_ACCESS: {
  //           URL: "login/user/updateApprovePoAccess",
  //         },
  //       },
  //       PURCHASER: {
  //         GET_PURCHASERS: {
  //           URL: "login/user/getPurchasers",
  //         },
  //       },
  //       SUPPLIERS: {
  //         GET_ALL: {
  //           URL: "login/vendor/getAll",
  //         },
  //         SEARCH: {
  //           URL: "api/supplierList",
  //         },
  //         GET_SUPPLIER_PAYMENT_TERMS: {
  //           URL: "api/supplierEnterpriseInfo",
  //         },
  //       },
  //       PLANTS: {
  //         GET_ADDRESS_BY_BRANCH: {
  //           URL: "login/address/getAddressByBranch",
  //         },
  //         GET_PLANT: {
  //           URL: "login/branch/getDetails",
  //         },
  //         GET_COMPANY_DETAILS: {
  //           URL: "login/company/get",
  //         },
  //       },
  //       SEARCHCOMPANY: {
  //         URL: "login/branch/searchPlantsByCompany/",
  //       },
  //       CHECK_EMAIL_LIST: {
  //         URL: "login/user/check-sourcer-emails",
  //       },
  //     },
  // },
  USER_MANAGEMENT: {
    MODULES: {
      AUTH: {
        GET_SESSION: {
          URL: "login/eoc/getSession",
        },
        LOGIN: {
          URL: "login/auth/login",
          GOOGLE_URL: "login/eoc/login",
        },
        LOGOUT: {
          URL: "account/auth/logout",
        },
        FORGET_PASSWORD: {
          URL: "auth/forgotPassword",
        },
        RESET_PASSWORD: {
          URL: "auth/resetPassword",
        },
        GET_BRANCH_ACCESS: {
          URL: "login/user/getBranchAccess",
        },
        AUTHORIZE_GOOGLE_LOGIN: {
          URL: "account/auth/login",
        },
      },
      USER: {
        GET_BY_BRANCH: {
          URL: "login/user/getByBranch",
        },
        UPDATE_APPROVE_PO_ACCESS: {
          URL: "login/user/updateApprovePoAccess",
        },
      },
      PURCHASER: {
        GET_PURCHASERS: {
          URL: "account/plant/getPurchasers",
        },
      },
      SUPPLIERS: {
        GET_ALL: {
          URL: "login/vendor/getAll",
        },
        SEARCH: {
          URL: "api/supplierList",
        },
        SEARCH_SUPPLIER: {
          URL: "api/searchSupplier",
        },
        GET_SUPPLIER_PAYMENT_TERMS: {
          URL: "api/supplierEnterpriseInfo",
        },
        SEARCH_SUPPLIER_NEW: {
          URL: "po/supplier/getSuppliersByName/",
        },
        SEARCH_SUPPLIER_POST: {
          URL: "po/supplier/getSuppliersByName",
        },
      },
      PLANTS: {
        GET_ADDRESS_BY_BRANCH: {
          URL: "account/v0/plant-address/get-addr-by-plant-id-and-addr-type",
        },
        GET_PLANT: {
          URL: "account/v0/plant/get-plant-details-by-id",
        },
        GET_COMPANY_DETAILS: {
          URL: "account/v0/company/get-company-by-id",
        },
      },
      SEARCHCOMPANY: {
        URL: "login/branch/searchPlantsByCompany/",
      },
      SEARCHCUSTOMERPLANTBYKEY: {
        URL: 'account/plant/search-by-keyword'
      },
      CHECK_EMAIL_LIST: {
        URL: "account/user/check-sourcer-emails",
      },
      FILE_UPLOAD: {
        URL: "account/s3/upload",
      },
      FILE_UPLOAD_PLANT: {
        URL: "account/s3/upload-document",
      },
      CUSTOMER_POC: {
        GET_CUSTOMER_POC: {
          URL: "account/user/get-all-kam"
        }
      }
      // GET_SOURCER_LIST: {
      //   URL: "account/user/sourcer-by-team",
      // }
    },
  },
  DATASYNC: {
    MODULES: {
      PO: {
        PO_SOURCINGALLOCATION: {
          URL: "poSession/getPoCountAndValueAgainstSourcingAllocation",
        },
      },
      ITEM: {
        PRE_SUPPLIER_PO_DEATILS: {
          URL: "item/getPreSupplierPoDetails",
        },
        MSN_PENDING: {
          URL: "item/getMsnPendingItemCountAndValue",
        },
        PO_TP_SUPPLIER_PENDING: {
          URL: "item/getItemsValueAndCountForPoToSupplierPending",
        },
        INVOICE_PENDING: {
          URL: "item/getItemsValueAndCountForInvoicePending",
        },
      },
    },
  },
  /**
   * Buyer Internal is the backend for excel on cloud.
   */
  BUYER_INTERNAL: {
    MODULES: {
      PO: {
        DOWNLOAD_TEMPLATE: {
          URL: "po/item/download-sample-item-csv",
        },
        DRAFT_PO_DATA: {
          URL: "po/session/save",
        },
        GET_BY_USER: {
          URL: "po/session/get-by-user",
        },
        GET_BY_PLANTS: {
          URL: "po/session/get-by-plants",
        },
        UPLOAD_ITEM_EXCEL: {
          URL: "po/item/upload-file",
        },
        GET_PO_DATA: {
          URL: "po/session/get/",
        },
        GET_PO_DRAFT_DATA: {
          URL: "po/session/submitted-by-user",
        },
        GET_ITEMSHEET_CSV_DATA: {
          URL: "po/item/getItemSheetCsvData",
        },
        GET_OMT_SHEET_DOWNLOAD: {
          URL: "po/sheet/downloadOmtSheetData",
        },
        GET_SUPPLIER_SHEET_DOWNLOAD: {
          URL: "po/sheet/downloadSupplierSheetData",
        },
        GET_CATALOG_SHEET_DOWNLOAD: {
          URL: "po/sheet/downloadCatalogSheetData",
        },
        BULK_UPLOAD: {
          URL: "validation/csv/upload-csv-file-generic",
        },
        GET_LATEST_BULK_PO_DATA: {
          URL: "bulk-po/getLatestBulkPoBatchData",
        },
        GET_BULK_PO_BY_BATCH_ID: {
          URL: "bulk-po/getBulkPoByBatchId",
        },
        UPLOAD_BULK_PO_COPY: {
          URL: "bulk-po/uploadBulkPoCopy",
        },
        CHECK_CDD_FOR_PO_ITEM: {
          URL: "po/item/validateCDD",
        },
        VALIDATE_RFQ_CPO_TYPE: {
          URL: "po/rfqitem/validate-rfq-cpo-type",
        },
        UPDATE_ITEMS: {
          URL: "po/item/updateItemFields",
        },
        BULK_UPLOAD_PUSH_TO_SCM: {
          URL: "validation/csv/push-to-scm-post-inventory-check",
        },
        BULK_ADDRESS_UPLOAD: {
          URL: "account/plant-address/bulk-upload",
        },
        PO_BATCH_DETAILS: {
          URL: "bulk-po/getAllBatch",
        },
        DOWNLOAD_BATCH: {
          URL: "bulk-po/getBatchPOs",
        },
        ADDRESS_BATCH_DETAILS: {
          URL: "account/plant-address/get-all-batch-by-userid-addresses",
        },
        DOWNLOAD_BATCH_ADDRESS: {
          URL: "account/plant-address/download/address.csv/",
        },
        DOWNLOAD_SHEET_REPORT: {
          URL: "po/sheet/downloadSheetData",
        },
        BULK_ORDER_MAPPING: {
          URL: "validation/csv/update-order-id-csv",
        },
        CHECK_ARC_CPN_FOR_PO_ITEM: {
          URL: "po/validate-isArc",
        },
        RFQ_SUGGESTION_LIST: {
          URL: "po/rfqitem/getRfqItemAgainstDescription"
        },
        GET_CPO_OCR: {
          URL: "pdf_extraction/cpo_ocr"
        },
        IS_ROUTING_ALLOWED_FOR_PLANT: {
          URL: "routing-plant-mapping/checkMapping"
        },
        SOURCERS_LIST: {
          URL: "po/sheet/getSourcers"
        },
        GET_CUSTOMER_PAYMENT_TERMS: {
          URL: "account/payment-terms/credit-days"
        },
        EXTEND_EXPIRY_PO: {
          URL: "po/extend-po-validity"
        },
        IS_DIRECT_PO: {
          URL: "po/item/checkRepeatPo"
        },
        GET_USER_DETAILS_BY_MANAGER: {
          URL: "account/user/get-user-details-by-manager"
        },
        GET_PRODUCTIVITY_DASHBOARD_USER_DATA: {
          URL: "po/productivityDashboard/user-data"
        },
        REFRESH_PRODUCTIVITY_DASHBOARD_DATA: {
          URL: "po/productivityDashboard/refresh-data"
        },
        DOWNLOAD_PRODUCTIVITY_DASHBOARD_DATA: {
          URL: "po/productivityDashboard/download"
        },
        GET_PRODUCTIVITY_OUTCOME: {
          URL: "po/productivityDashboard/total-outcome"
        },
        UPDATE_CRMM_APPROVAL: {
          URL: "po/updateCrmmApproval"
        },
        GET_STORE_LOCATION_BASED_ON_PLANT: {
          URL: "account/plant/store-location-for-plantId"
        },
        GET_SERVICE_CHARGE_TAX_DETAILS: {
          URL: "po/item/calculate-service-charge"
        },
        GET_SERVICE_CHARGE_DETAILS_BY_PLANT_ID: {
          URL: "account/plant/service-charge-details"
        },
        UPDATE_DIRECT_PO: {
          URL: "po/update-mark-release"
        },
        GEN_AI_DATA_EXTRACTION: {
          URL: "genai/cpo/GenAI/extraction/result"
        },
      },
      ITEM: {
        GET_OMTSHEET_DATA: {
          URL: "po/sheet/getOmtSheetData",
        },
        GET_ITEMSHEET_DATA: {
          URL: "po/sheet/getItemSheetData",
        },
        STATUS_WISE_COUNT: {
          URL: "po/item/status-wise-count",
        },
        GET_SUPPLIERSHEET_DATA: {
          URL: "po/sheet/getSupplierSheetData",
        },
        GET_CATALOGSHEET_DATA: {
          URL: "po/sheet/getCatalogSheetData",
        },
        GET_AUDIT_LOG_FOR_ITEM: {
          URL: "po/item/audit",
        },
        CANCEL_ITEM: {
          URL: "po/item/cancelItemFromPO",
        },
        GET_ROUTING_CONFIGURATION: {
          URL: "po/item/fetch-suggested-supplier",
        },
        SAVE_ROUTING_CONFIGURATION: {
          URL: "po/item/publish-suggested-suppliers",
        },
        GET_ITEMS_DETAILS_USING_ITEMIDS: {
          URL: "po/item/fetch-item-details",
        },
        CLOSE_PO_ITEMS_USING_ITEMIDS: {
          URL: "po/item/updatePoStatus",
        },
        ITEM_SHEET_BULK_UPDATE_UPLOAD: {
          URL: "po/item/updateItemAgainstItemId"
        },
        GET_CURRENT_DATE_TIME: {
          URL: "test/getCurrentDate"
        }
      },
      PRODUCT: {
        SEARCH: {
          URL: "product/es-search",
        },
        SEARCH_BY_MSN: {
          URL: "catalogue/product/get",
        },
        BULK_UPLOAD: {
          URL: "procurement/product/upload"
        },
        PRODUCT_SEARCH: {
          URL: "filter/fetch"
        }
      },
      DASHBOARD: {
        GET_PO_AT_HAND_DATA: {
          URL: "po/dashboard/getPoAtHandData",
        },
        GET_PO_AT_HAND_CSV_DATA: {
          URL: "po/dashboard/getPoAtHandCsvData",
        },
        GET_ITEM_DATA_BY_STAGE: {
          URL: "po/dashboard/getStageWiseData",
        },
        GET_DASHBOARD3_V2_DATA: {
          URL: "po/dashboard/getDashboard3Data/v2",
        },
        GET_STAGEWISE_ITEM_CSV_DATA: {
          URL: "po/dashboard/getStageWiseCsvData",
        },
        GET_INVOICE_CSV_DATA: {
          URL: "po/dashboard/getInvoiceCsvData",
        },
        GET_STATUSWISE_ITEM_CSV_DATA: {
          URL: "po/dashboard/getPoStatusWiseCsvData",
        },
        GET_REVISEDPICKUPDATEWISE_SOURCINGALLOCATION_DATA: {
          URL: "po/dashboard/getRevisedPickupDateWiseSourcingAllocationData",
        },
        GET_REVISEDPICKUPDATEWISE_SOURCINGALLOCATION_CSV_DATA: {
          URL: "po/dashboard/getRevisedPickupDateWiseCsvData",
        },
      },
      SA: {
        GET_ALL_SA_DATA: {
          URL: "sa/getallsa",
        },
        GET_SA_DATA_BY_PLANTID: {
          URL: "sa/search",
        },
        GET_SA_DETAILS: {
          URL: "sa/get-detail",
        },
        CREATE: {
          URL: "sa/create",
        },
        REPEAT: {
          URL: "sa/repeat",
        },
        UPDATE_SA: {
          URL: "sa/update-sa",
        },
        GET_SA_SHEET_DATA: {
          URL: "sa/item/list",
        },
        UPDATE_SA_SHEET_ITEM: {
          URL: "sa/item/update",
        },
        PUSH_TO_SCM: {
          URL: "sa/pushToSCM",
        },
        GET_SA_ITEMSHEET_CSV_DATA: {
          URL: "sa/item/download-list"
        },
        GET_AUDIT_LOG_FOR_SA_ITEM: {
          URL: "sa/item/history"
        },
        EXTEND_SA_VALIDITY: {
          URL: "sa/extend-expiry"
        }
      },
      // ARC Module
      ARC: {
        DOWNLOAD_TEMPLATE: {
          URL: "",
        },
        DOWNLOAD_ARC_Data: {
          URL: "arc-tor/download-v1"
        },
        DOWNLOAD_ARC_SUMMARY: {
          URL: "/arc-tor/summary/download"
        },
        UPLOAD_ARC: {
          URL: "arc-tor/save"
        },
        ARC_List: {
          URL: "arc-tor/list"
        },
        ARC_SUMMARY_LIST: {
          URL: "arc-tor/summary"
        },
        GET_HISTORY: {
          URL: "arc-tor/history"
        },
        ARC_Audit: {
          URL: "arc-tor/audit"
        },
        MASTER_BRAND_TEMPLATE: {
          URL: 'arc-tor/master-brand'
        },
        MASTER_L1_L2_CATEGORY_TEMPLATE: {
          URL: 'arc-tor/master-category'
        },
        MASTER_UOM_TEMPLATE: {
          URL: 'unit/master-uom'
        },
        REGION_LIST: {
          URL: 'account/region'
        },
        ARC_TOR: {
          URL: 'arc-tor/dashboard'
        },
        GET_ARC_INFO: {
          URL: 'arc/performance-summary'
        },
        GET_WORKING_ARC_INFO: {
          URL: 'arc_info/working/arc'
        },
        GET_NOT_WORKING_ARC_INFO: {
          URL: 'arc_info/not_working/arc'
        },
        GET_EXPIRED_ARC_INFO: {
          URL: 'arc_info/expired/arc'
        },
        DOWNLOAD_ARC_PERFORMANCE_REPORT: {
          URL: 'arc/performance-summary/download'
        },
      },
      CATALOGUE: {
        GOVERNED_MSN_CHECK: {
          URL: "cassandraFeed/catalog/product/cptFlag/",
        },
        PRODUCT_VALIDATE: {
          URL: "catalogue/product/validate",
        },
        BULK_UPLOAD: {
          URL: "arc-tor/save"
        }
      },
      BULK_PUNCHING: {
        UPLOAD_CPO_SPO_REPORT: {
          URL: "validation/csv/upload-paas-csv-file"
        },
        GET_CPO_SPO_REPORT: {
          URL: "bulk-po/passPoAuditList"
        },
        GET_CPO_SPO_UPLOAD_PO_COPY: {
          URL: "bulk-po/uploadPoCopyListing"
        },
        UPLOAD_BULK_PO_COPY: {
          URL: "bulk-po/uploadPoCopy"
        }
      },
      EMS: {
        GET_TEAM_DETAILS: {
          URL: "api/salesOps/getAllTeams.json",
        },
        GET_TEAM_PLANT_MAPPING: {
          URL: "api/salesOps/getTeamPlantMapping.json",
        },
        GET_TAX_DETAILS: {
          URL: "api/taxRepository/gstTaxRates.json",
        },
      },
      SALESOPS: {
        GET_USER_TEAMS: {
          URL: "api/v1/user/userTeams",
        },
        GET_MSN_SUPPLIER_TP: {
          URL: "purchase/api/v1/supplier/suggest",
        },
      },
      RFQ: {
        GET_RFQ_ITEM_SHEET: {
          URL: "po/sheet/getRfqItemSheetData",
        },
        GET_RFQ_DATA_BY_RFQ_ID: {
          URL: "po/rfqitem/getRfqDataById",
        },
        UPDATE_RFQ_ITEM_SHEET: {
          URL: "po/rfqitem/update-rfqitem",
        },
        MSN_SUGGESTION_LIST: {
          URL: "product_suggestion_eoc/search"
        },
        DOWNLOAD_CSV: {
          URL: "po/rfqitem/getRFQItemSheetCsvData",
        },
        SEARCH_MSN: {
          URL: "product/getProductGroup",
        },
        SA_SEARCH_MSN: {
          URL: "platform/getProductGroup",
        },
        QUOTE_DATA: {
          URL: "po/rfqitem/saveRfqQoute",
        },
        AUTO_QUOTE: {
          URL: "po/rfqitem/autoQuoteAfterGmApproval",
        },
        PUNCH_ITEMS_DATA: {
          URL: "po/rfqitem/create",
        },
        CHECK_IS_ARC: {
          URL: "po/rfqitem/validate-rfq-cpo-type",
        },
        UPLOAD_RFQ_FILE: {
          URL: "po/rfqitem/upload-file",
        },
        CANCEL_ITEM: {
          URL: "po/rfqitem/mark-cancelled-RfqItems",
        },
        GET_AUDIT_LOG_FOR_RFQ_ITEM: {
          URL: "po/rfqitem/audit",
        },
        BULK_UPDATE_UPLOAD: {
          URL: "po/rfqitem/updateRfqItemAgainstRfqId",
        },
        CHECK_RFQ_IDS: {
          URL: "po/rfqitem/check-rfqItemId",
        },
        Fetch_SP_HISTORY_MSNWISE: {
          URL: "po/rfqitem/fetch-msn-history"
        },
        GET_RFQ_ITEM_DATA: {
          URL: "po/supplier/getRfqItemAgainstRfqItemId"
        },
        ADD_SUPPLIER_FOR_QUOTE: {
          URL: "po/supplier/saveSupplierQuote"
        },
        SUPPLIER_QUOTE_FROM_SUGGESTION: {
          URL: "po/supplier/saveBulkSupplierQuote"
        },
        GET_SUPPLIERS_INFO: {
          URL: "/api/supplierList"
        },
        QUOTE_ALLOWED_OR_NOT: {
          URL: "po/rfqitem/checkQuoteAllowed"
        },
        MAIL_TO_BE_SEND_OR_NOT: {
          URL: "po/rfqitem/checkPlantId"
        },
        SUPPLIERS: {
          GET_SUPPLIER_QUOTE: {
            URL: "supplier/fetch-quotation-response"
          },
          SAVE_EDITED_SUPPLIER_DATA: {
            URL: "supplier/saveQuotationDetails"
          },
          UPLOAD_SUPPLIER_QUOTE_SHEET: {
            URL: "supplier/upload-supplier-quotation-file"
          },
          GET_ANALYTICS_SUGGEST_SUPPLIER: {
            URL: 'ml_engine/get_rfq_detail'
          },
        },
        GET_SUPPLIERS_RESPONSE_FOR_RFQITEM: {
          URL: "po/supplier/fetch-supplierQuotation-response"
        },
        UPDATE_RFQITEM_AND_QUOTE_STATUS_SUPPLIERRESPONSE: {
          URL: "po/supplier/update-supplier-quotation"
        },
        GET_SUPPLIERS_RESPONSE_FOR_RFQ_ID_ITEMS: {
          URL: "po/supplier/fetchSupplierQuotationResponse"
        },
        UPDATE_RFQITEMS_AND_QUOTE_STATUS_SUPPLIERRESPONSE_BULK: {
          URL: "po/supplier/update-supplier-quotation-bulk"
        },
        GM_APPROVAL: {
          GET_RFQ_IDS_DETAILS: {
            URL: "po/rfqitem/fetchRfqData"
          },
          SUBMIT_FOR_GM_APPROVAL: {
            URL: "po/rfqitem/sendGmApprovalRequest"
          },
          APPROVE_OR_REJECT: {
            URL: "po/rfqApproval/updateStatus"
          }
        },
        GET_L1_L2_CATEGORY_DATA: {
          URL: "cassandraFeed/catalogCategory/categorySearch"
        },
        GET_SOURCER_DATA: {
          URL: "po/rfqitem/getAutoSourcer"
        },
        RERTRIEVE_CM_APPROVAL_RFQ_DATA: {
          URL: "po/rfqitem/rfq-data-for-approval"
        },
        DOWNLOAD_RFQ_APPROVAL_DATA: {
          URL: "po/rfqitem/rfq-data-for-approval/download",
        },
        CM_APPROVAL: {
          URL: "po/rfqitem/sendGmApprovalRequest"
        },
        GET_SUPPLIER_SUGGEST: {
          URL: "po/suggest-supplier/fetch-supplier-data"
        },
        ARC_AUTOMATION_CONF: {
          URL: "config/get-arc-automation-conf"
        },
        BULK_PO_CUSTOMER_CONF: {
          URL: "config/get-by-key/bulk-po-customer"
        },
        GET_SUPPLIER_DATA_TYPE: {
          URL: "po/suggest-supplier/check-supplier-data"
        },
        GET_ENQUIRY_PENDENCY_DATA: {
          URL: "fact_enquiry/POC_performance"
        },
        GET_WAREHOUSES_BY_MSN: {
          URL: "warehouse/getWarehouseByMsn"
        },
        GET_SUPPLIER_PAYMENT_TERM_LIST: {
          URL: "po/rfqitem/supplierPaymentTerm"
        },
        GET_STATUS_WISE_COUNT: {
          URL: "po/rfqitem/status-wise-count"
        },
        QUOTE_DOWNLOAD_BY_ID: {
          URL: "test/get-rfq-quote-pdf?uniqueIdentifier="
        },
        GET_PR_SHEET_GLOBAL_FILTERS: {
          URL: "pr/item/fetch-filters"
        },
        GET_PR_DASHBOARD_GLOBAL_FILTERS: {
          URL: "pr/item/dashboard/filter"
        },
        GET_PR_AGAINST_SOURCER: {
          URL: "pr/item/dashboard/pr-sourcer-count"
        },
        GET_PR_AGAINST_CUSTOMERS: {
          URL: "pr/item/dashboard/all-pr-against-customer"
        },
        GET_PR_TYPE_COUNT: {
          URL: "pr/item/dashboard/pr-type-count"
        },
        GET_PR_DEADLINE_CROSSED: {
          URL: "pr/item/dashboard/pr-deadline-count"
        },
        GET_PR_AGEING: {
          URL: "pr/item/dashboard/pr-ageing-count"
        },
        GET_PR_STAGES: {
          URL: "pr/item/dashboard/pr-stage-count"
        },
        GET_PR_CATEGORY_L1: {
          URL: "pr/item/dashboard/pr-categoryL1-count"
        },
        GET_PR_CATEGORY_L2: {
          URL: "pr/item/dashboard/pr-categoryL2-count"
        },
        GET_BRAND_PR_PENDENCY: {
          URL: "pr/item/dashboard/pr-brand-count"
        },
      },
      SOURCE_WORKSPACE: {
        BUSINESS_MTD: {
          URL: "po/sourcing/workspace/businessMTD",
        },
        MSN_PENDING: {
          URL: "po/sourcing/workspace/msnPendingCount",
        },
        SPO_PENDING: {
          URL: "po/sourcing/workspace/spoPendingCount",
        },
        MSN_PENDING_LIST: {
          URL: "po/sourcing/workspace/msnPendingList",
        },
        SPO_PENDING_LIST: {
          URL: "po/sourcing/workspace/spoPendingList",
        },
        TODAYS_PICKUP_COUNT: {
          URL: "po/sourcing/workspace/todaysPickupCount",
        },
        FAILED_PICKUP_COUNT: {
          URL: "po/sourcing/workspace/failedPickupCount",
        },
        TODAYS_PICKUP_LIST: {
          URL: "po/sourcing/workspace/todaysPickup",
        },
        FAILED_PICKUP_LIST: {
          URL: "po/sourcing/workspace/failedPickups",
        },
      },
      ETA: {
        GET_TAT_LIST: {
          URL: "eta/getEtaList",
        },
      },
      WMS: {
        GET_AVAILABLE_INVENTORY: {
          URL: "api/inventory/getAvailableInventoryForEOC"
        }
      },
      GM_APPROVAL: {
        GET_APPROVAL_LIST: {
          URL: "po/rfqApproval/getApprovalData"
        },
        GET_GLOBAL_TEAM_LIST: {
          URL: "account/team"
        },
        GET_PO_CM_APPROVAL_DATA: {
          URL: "po/poCmApproval/getApprovalData"
        },
        UPDATE_PO_CM_APPROVAL_STATUS: {
          URL: "po/poCmApproval/updateStatus"
        }
      },
      RFQ_DASHBOARD: {
        RFQ_PENDENCY: {
          URL: "po/dashboard/getRfqDashboardData"
        },
        RFQ_PENDENCY_MINIDASHBOARD: {
          URL: "po/dashboard/getRfqMiniDashboardData"
        }
      },
      PR: {
        PR_SUMMARY: {
          URL: "validation/csv/upload-pr-csv"
        },
        GET_PR_SUMMARY_DATA: {
          URL: "bulk-pr/audit"
        },
        GET_PR_SHEET_ITEM_DATA: {
          URL: 'pr/item/getSheetData'
        },
        UPDATE_PR_SHEET_DATA: {
          URL: 'pr/item/update'
        },
        BULK_UPLOAD: {
          URL: "pr/item/bulk-update"
        },
        CLOSE_ITEM: {
          URL: "pr/item/close-items"
        }
      },
    }
  },
  /**
   * Refers to the current project i.e buyer internal UI.
   */
  BUYER_EXCEL_CLOUD: {
    PO: {
      AGREEMENT_TYPE: {
        PO: "PO",
        "Open PO": "Open PO",
        "Stock Transfer": "Stock Transfer",
      },
      PO_TYPE: [{ key: "G", value: "Released" }, { key: "U", value: "Un-Released" }, { key: "P", value: "Partial Released" }],
      LINE_ITEMS: {
        REPORTS_TYPE: {
          "PO Details": "PO Details",
          Purchase: "Purchase",
          Sourcing: "Sourcing",
          Delivery: "Delivery",
          Reports: "Reports",
        },
      },
    },
    URLS: {
      LOGIN: "/account/login/",
      LOGOUT: "/account/logout",
      ACCESS_DENIED: "/accessdenied",
      NOT_FOUND: "/po/list",
      DASHBOARD: "/dashboard",
      PO_LIST: "/po/list",
      ITEM_SHEET: "/po/items-sheet",
      SA_LIST: "sa/list",
      CATALOG_PRODUCT: "/catalog/product",
      PUNCH_HEADER: "/po/punch/header",
      SA_HEADER: "/sa/punch/header",
      MARK_RELEASE_PO: "/po/mark-release",
      DIRECT_PO: "/po/direct-po",
      LIQUIDATION_PO: "/po/liquidation-po",
      APPROVAL_LIST: "/po/approval-list",
      PUNCH_LINE_ITEMS: "/po/punch/line-items",
      ITEM_EXCEL_TEMPLATE: "assets/Template-Files/item-template.xlsx",
      ITEM_EXCEL_TEMPLATE_SA:
        "https://s3-ap-southeast-1.amazonaws.com/stg.moglix.com/sample-item-template-sa-new.xlsx",
      ITEM_EXCEL_TEMPLATE_RFQ: "assets/Template-Files/rfq-template.xlsx",
      ADD_SUPPLIER_TO_QUOTE: "/pre-po/supplier-items/add-supplier-to-quote",
      RFQ_SUPPLIER_MSG: "/supplier-items/view-supplier-msg",
      SUPPLIER_RESPONSE_ITEMS: "/pre-po/supplier-items/suppliers-response",
      RFQ_SHEET: "/pre-po/rfq-sheet",
      GM_APPROVAL_SHEET: "/pre-po/gm-approval/gm-approval-sheet",
      PO_GM_APPROVAL_SHEET: "/po/po-approval-sheet",
      RFQ_DASHBOARD: "/report/sourcer-dashboard",
      ARC_LIST: "/arc/arc-list",
      GM_APPROVAL_SCREEN: "/pre-po/gm-approval/gm-approval-screen",
      CPO_SPO_CREATION: "/po/paas-cpo-spo-automation",
      UPLOAD_PO_COPY: "/po/upload-po-copy",
      VENDOR_DISCOVERY: "/vendor/details",
    },
    ACTIONS: {
      VENDOR_DISCOVERY: {
        UPLOAD_BULK_VENDOR_FILE: 'upload-bulk-vendor-file',
        SINGLE_VENDOR_SEARCH_NAVIGATION: 'navigate-single-vendor-search',
        BULK_VENDOR_SEARCH_NAVIGATION: 'navigate-bulk-vendor-search',
        LIQUIDTION_CPO_ITEM_TYPE_VISIBLE: 'liquidation-cpo-item-type-visible',
      }
    },
    BULK_UPLOAD_TEMPLATES: {
      BULK_ADDRESS_UPLOAD:
        "assets/Template-Files/eoc_bulk_address_creation.xlsx",
      BULK_PUSH_TO_SCM_UPLOAD:
        "https://s3-ap-southeast-1.amazonaws.com/doc.moglix.com/buyers/eoc_bulk_pushTOscm.csv",
      BULK_PO_UPLOAD: "assets/Template-Files/EOC_bulkPOnew.csv",
      BULK_PO_ORDER__MAPPING:
        "https://s3-ap-southeast-1.amazonaws.com/stg.moglix.com/SampleCSV+-+Sheet.csv",
      BULK_RFQ_UPDATE_UPLOAD: "assets/Template-Files/RFQ-Bulk-update.csv",
      ARC_UPLOAD: "assets/Template-Files/ARC-template.csv",
      PRODUCT_UPLOAD: "assets/Template-Files/uploadproduct.csv",
      SUPPLIER_QUOTE_TEMPLATE: "assets/Template-Files/supplier-quote-items-template.xls",
      BULK_ITEM_SHEET_UPDATE_UPLOAD: "assets/Template-Files/Item-Sheet-Bulk-Update.csv",
      BULK_PR_SHEET_UPDATE_UPLOAD: "assets/Template-Files/Bulk-Pr-Update-Template.csv", //change
      BULK_PR_SUMMARY_TEMPLATE: "assets/Template-Files/Bulk-PR-Template.csv",
      CPO_SPO_TEMPLATE_UPLOAD: "assets/Template-Files/Bulk-Paas-Po-Template.csv",
      SA_ITEM_TEMPLATE: "assets/Template-Files/sample-item-template-sa-new.xlsx",
    },
    ERROR_MESSAGES: {
      SOMETHING_WENT_WRONG: "SomeThing went wrong Please try again later",
      SOMETHING_WENT_WRONG_NO_RESPONSE:
        "SomeThing Went Wrong Due To Empty Response",
      TAX_REPOSITORY_ERROR: "SomeThing Went Wrong Unable To Display Tax Info",
      RFQ_SHEET_ERROR_MESSAGES: {
        ERROR_SELECT_ATLEAST_ONERFQQUOTE:
          "Please Select Atleast One RFQ Item To Quote",
        ERROR_SELECT_SAME_PLANTS:
          "Please Select Same Plants to Quote Multiple RFQ Items",
        ERROR_CANNOT_QUOTE_ALL_RFQ_ITEMS:
          "Cannot Quote All RFQ Items At a Time",
        ERROR_QUOTING_PRICE: "Please Enter Quote Price.",
        ERROR_LEAD_TIME: "Please Enter Lead Time ( Lead Time To Customer(days))",
        ERROR_SELECT_SAME_TEAMS:
          "Please Select Same Teams to Quote Multiple RFQ Items",
        ERROR_POWON_SELECTED_FOR_QUOTE: "For RFQ Items with PO Won Status Quote is not allowed",
        ERROR_POWON_SELECTED_FOR_QUOTE_SUPPLIER: "For RFQ Items with PO Won Status Quote to Supplier is not allowed"
      },
      SA: {
        ERROR_PUSH_TO_SCM: " SA Cannot Move To SCM, Assign MSN By Editing SA",
      },
    },
    SUCCESS_MESSAGES: {
      SA: {
        PUSH_TO_SCM: "SA Successfully Moved To SCM",
      },
    },
  },
  ACCOUNT_SERVICE: {
    MODULES: {
      AUTH: {
        GET_META_DATA: {
          URL: "account/user/meta-data",
        },
        GET_AUTH_DATA: {
          URL: "account/user/auth-data",
        },
        GET_USER_TEAMS: {
          URL: "account/user/teams",
        },
        GET_USER_TEAMS_BY_USER: {
          URL: "account/user/access/teams",
        },
        GET_COMPANIES_DATA: {
          URL: "account/company/search",
        },
        GET_PLANTS_BY_COMPANY: {
          URL: "account/company-plant/search-plants-by-company",
        },
        GET_TEAM_PLANT_MAPPINGS: {
          URL: "account/team/plant-mappings",
        },
        LOGIN: {
          GOOGLE_URL: "account/auth/login",
        },
        LOGOUT: {
          URL: "account/auth/logout",
        },
        GET_MODULE_ACCESS_BY_USER: {
          URL: 'account/module-access/sub-module/list'
        },
        GET_SUBMODULE_ACCESS_BY_USER: {
          URL: 'account/user/check-module-access'
        }
      },
      WAREHOUSE: {
        GET_ALL: {
          URL: "account/v0/vendor/get-all",
        },
        GET_WAREHOUSES: {
          URL: "account/warehouse/getAll",
        },
        CREATE_WAREHOUSE: {
          URL: "account/warehouse/create",
        },
        UPDATE_WAREHOUSE: {
          URL: "account/warehouse/update",
        },
        GET_WAREHOUSE: {
          URL: "account/warehouse/",
        },
        GET_STATE_CITY_LIST: {
          URL: "/util/getStateCity",
        },
        GET_SUBSIDIARIES: {
          URL: "account/subsidiary/get-all"
        },
        GET_ALL_MAPS: {
          URL: "account/dash-board/get-data-maps"
        }
      },
      USER: {
        GET_ACCOUNTS: {
          URL: "account/user/search",
        },
        GET_ALL_EMAIL: {
          URL: "account/user/search-email"
        },
        UPDATE_ACCOUNT: {
          URL: "account/user/enable/",
        },
        GET_USER_ROLES: {
          URL: "account/user/roles",
        },
        GET_USER_TEAMS: {
          URL: "account/user/teams",
        },
        GET_USER_WAREHOUSES: {
          URL: "account/user/warehouses",
        },
        GET_USER_DETAILS: {
          URL: "account/user/",
        },
        ADD_USER_DETAILS: {
          URL: "account/user/create",
        },
        EDIT_USER_DETAILS: {
          URL: "account/user/update",
        },
        UPDATE_USER_ACCESS: {
          URL: "account/user/access",
        },
        GET_USER_BY_EMAIL: {
          URL: "account/user/by-email",
        },
        GET_USER_MASTER_DATA: {
          URL: "account/master/user",
        },
        UPDATE_USER_ACCESS_TRANSFER: {
          URL: "account/dash-board/update-email",
        }
      },
      DASHBOARD: {
        ENABLE: {
          URL: "account/dash-board/enable/",
        },
      },
      ROLE: {
        GET_ROLES: {
          URL: "account/role/search",
        },
        GET_ROLE_BY_ID: {
          URL: "account/role/",
        },
        GET_ALL_USERS_ROLE: {
          URL: "account/role/all-users-for-role/"
        }
      },
      APP_MODULES: {
        GET_MODULES: {
          URL: "account/module/search",
        },
        SAVE_UPDATE_MODULES: {
          URL: "account/module/save",
        },
        UPDATE_STATUS: {
          URL: "account/module/enable",
        },
      },
      FUNTION_CONFIGURATOIN: {
        GET_ALL: {
          URL: "account/functional-area/get-all",
        },
        SEARCH: {
          URL: "account/functional-area/search",
        },
        SAVE_UPDATE_FUNCTION: {
          URL: "account/functional-area/save",
        },
        ENABLE_STATUS: {
          URL: "account/functional-area/enable/",
        },
        GET_FUNCTION_WITH_ID: {
          URL: "account/functional-area",
        },
        GET_FUNCTION: {
          URL: "account/functional-area",
        },
      },
      MODULE: {
        SEARCH: {
          URL: "account/module/search",
        },
        GET_MODULE: {
          URL: "account/module/",
        },
        SAVE_UPDATE_MODULE_PERMISSION: {
          URL: "account/role/save",
        },
      },
      COMPANY: {
        CREATE: {
          URL: "account/company/create",
        },
        UPDATE: {
          URL: "account/company/update",
        },
        SEARCH: {
          URL: "account/company/search",
        },
        SAVE_OR_UPDATE_COMPANY: {
          URL: "account/company/enable/",
        },
        GET_COMPANY_BY_ID: {
          URL: "account/company/",
        },
        MAP_COMPANY_TO_PAYMENT_TERMS: {
          URL: "procurement/vendor/company/terms/upsert",
        },
      },
      PLANTS: {
        GET_PLANTS: {
          URL: "account/plant/search",
        },
        CREATE_PLANT: {
          URL: "account/plant/create",
        },
        UPDATE_PLANT: {
          URL: "account/plant/update",
        },
        UPDATE_PLANT_PAYMENTTERMS: {
          URL: "payment-terms/upsert"
        },
        UPDATE_PLANT_WAREHOUSE_PAYMENTTERMS: {
          URL: "account/payment-terms/create-mapping"
        },
        UPDATE_PLANT_WAREHOUSE_MAPPING: {
          URL: "account/plant-warehouse/mapping"
        },
        SAVE_OR_UPDATE_PLANT: {
          URL: "account/plant/enable/",
        },
        GET_PLANT_DATA: {
          URL: "account/plant/",
        },
        GET_PLANT_DETAILS: {
          URL: "account/plant/getDetails/",
        },
        GET_COUNTRY_BY_PLANTS: {
          URL: "account/plant/countryByPlants/list",
        },
        GET_TEAM_BY_TEAM_IDS: {
          URL: "account/team/list",
        },
        GET_PAYMENT_TERMS: {
          URL: "account/payment-terms"
        },
        GET_PAYMENT_TERMS_WAREHOUSE_MAPPING_DATA: {
          URL: "account/payment-terms/mapping"
        },
        GET_WAREHOUSE_MAPPING_DATA: {
          URL: "account/plant-warehouse/getDetails/"
        },
        GET_PLANTS_REGION: {
          URL: "account/v0/plant/plants-by-ids"
        },
        PLANT_ACCOUNT_TYPE_MAPPING: {
          URL: "account/plant/account-type-mapping"
        },
      },
      COUNTRY: {
        SEARCH: {
          URL: "account/country/search",
        },
        GET_COUNTRIES: {
          URL: "account/country",
        },
        GET_COUNTRY_BY_ID: {
          URL: "account/country/",
        },
      },
      REGION: {
        GET_REGIONS: {
          URL: "account/region",
        },
        GET_REGION_BY_ID: {
          URL: "account/region/",
        },
      },
      TEAMS: {
        GET_TEAMS: {
          URL: "account/team",
        },
        SEARCH: {
          URL: "account/team/search",
        },
        CREATE_TEAM: {
          URL: "account/team/create",
        },
        UPDATE_TEAM: {
          URL: "account/team/update",
        },
        GET_TEAM_BY_ID: {
          URL: "account/team/",
        },
        GET_TEAM_BY_PLANTID: {
          URL: "account/team/fetchTeamByPlantId/"
        }
      },
      CURRENCY: {
        GET_CURRENCIES: {
          URL: "account/currency/get-all",
        },
      },
      KAM: {
        GET_KAM_EMAILS: {
          URL: "account/user/kams",
        },
        GET_KAM_BY_EMAIL: {
          URL: "account/user/kams",
        },
      },
      PLANT_ADDRESS: {
        GET_PLANT_ADDRESS: {
          URL: "account/plant-address/get-addresses-for-plant/",
        },
        CREATE_PLANT_ADDRESS: {
          URL: "account/plant-address/create-address-for-plant",
        },
        UPDATE_PLANT_ADDRESS: {
          URL: "account/plant-address/update-address-for-plant",
        },
        SEARCH_PLANT_ADDRESS: {
          URL: "account/address/search",
        }
      },
      CITY: {
        GET_CITIES_BY_STATE: {
          URL: "account/city/get-cities-by-state/",
        },
      },
      STATE: {
        GET_STATES_BY_COUNTRY: {
          URL: "account/state/get-states-by-country/",
        },
      },
      VERTICAL: {
        GET_VERTICALS: {
          URL: "account/vertical",
        },
        GET_VERTICAL_BY_ID: {
          URL: "account/vertical/",
        },
      },
      PAYMENT_TERMS: {
        GET_PAYMENT_TERMS: {
          URL: "account/payment-terms"
        }
      },
      FRESH_SERVICE: {
        GET_PLANT_CREATE_DETAILS: {
          URL: "api/v2/tickets/"
        }
      },
      FINANCE: {
        GET_GSTN_DETAILS: {
          URL: "address/getTaxpayerByGstin"
        }
      },
    },
  },
  STATIC_PARAMS: {
    MASK_TEXT: "*",
    MOGLIX_EMAIL_REGEX: "^[a-zA-Z0-9._-]+(@moglix\\.com)$",
    DEFAULT_COUNTRY_INDIA: "India",
    SOURCE_PORTAL: [{ key: "EOC", value: "EOC", }, { key: "SAP", value: "SAP" }, { key: "Bulk", value: "BULK" }, { key: "Manual", value: "MANUAL" }, { key: "WMS", value: "WMS" }],
    PO_TYPE_RFQ: "RFQ",
    PO_TYPE_ROUTING: "Routing",
    // RFQ_STATUS_PO_WON: "PO Won",
    // RFQ_STATUS_PO_LOST: "PO Lost",
    // RFQ_STATUS_QUOTED: "Quoted",
    RFQ_STATUS_PO_WON: "po won",
    RFQ_STATUS_PO_LOST: "po lost",
    RFQ_STATUS_QUOTED: "quoted",
    RFQ_CREATE: "create",
    RFQ_UPDATE: "update",
    PO_TYPE_ARC: "ARC",
    PO_ACTION_CANCEL_AND_CLONE: "cancelAndClonePO",
    PO_ACTION_REPEAT_PO: "repeatPO",
    SIDE_NAV_PARAMS: {
      PO_GROUP: "pogroup",
      SA_GROUP: "sagroup",
      ITEM_SHEET_GROUP: "itemsheetgroup",
      ACCOUNT_SERVICES: "accountServices",
      RFQ: "rfqGroup"
    },
    PHONE_NUMBER_REGEX_10DIGIT: /^[0-9]{10}/,
    CONSIGNEE_CONTACT_REGEX: /^[0-9-+,]+$/,
    RFQ_QUOTE_TERMS_CONDITIONS: [
      "1) All discrepancies (Quantity and Specification) must be reported within 2-days of receipt of goods. No goods shall be returned with an exception for manufacturing defects/Product miss-match of the involved items.",
      "2) Moglix shall not be liable for any third-party claims arising out of the use of quoted goods. The customer shall keep Moglix indemnified from/against all such claims.",
      "3) Price validity : The quoted prices are against the quantity and delivery terms mentioned in the RFQ and Quoted prices are valid so long as there is no change market/list price of the commodity/product.",
      "4) Warranty terms : All items supplied will bear warranty terms & conditions as mentioned by the Manufacturer.",
      "5) Credit Days : On Agreed terms.",
      "6) INCO terms as applicable.",
    ],
    RFQ_TEMPLATES: {
      "356": [ // India Plant
        { view: 'Template 1', value: 'Template 1' },
        { view: 'Template 2', value: 'Template 2' },
        { view: 'Template 3', value: 'Template 3' },
        { view: 'Template 4', value: 'Template 4' }
      ],
      "784": [ // UAE Plant
        { view: 'ABUDHABI', value: 'ABUDHABI' },
        { view: 'ABUDHABI_HSN', value: 'ABUDHABI_HSN' },
        { view: 'DUBAI', value: 'DUBAI' },
        { view: 'DUBAI_HSN', value: 'DUBAI_HSN' },
      ]
    },
    DATA_LIMIT_OPTIONS: [
      { view: '500', value: 500 },
      { view: '10000', value: 10000 },
    ],
    SUPPLIER_COLUMNS_RFQ_ITEM_SHEET_MAP: {
      vendorId: 'sourcing.suggestSupplierId',
      netOutStandingDebitBalance: 'sourcing.netOutStandingDebitBalance',
      supplierPercentageReturnByValue: 'sourcing.supplierPercentageReturnByValue',
      supplierDefaulterCount: 'sourcing.supplierDefaulterCount',
      supplierPercentageGstDefaultCount: 'sourcing.supplierPercentageGstDefaultCount',
      supplierTotalGstrEligibleInvoices: 'sourcing.supplierTotalGstrEligibleInvoices',
      supplierAmountReturnByValue: 'supplierAmountReturnByValue',
      supplierBusinessNature: 'supplierBusinessNature',
      vendorName: 'sourcing.suggestSupplier',
    },
    SHEET_RFQ: "rfqSheet",
    SHEET_SUPPLIER: "supplierSheet",
    OMT_STATUS_COLUMN_ID: "sourcing.omtStatus",
    PLANT_TYPE: {
      REGULAR: 1,
      COMPOSITION: 2,
      SEZ: 3,
      ISD: 4,
      INTERNAL: 5,
      TAX_EXEMPT: 6
    },
    RFQ_ADD_SUPLLIER_TO_QUOTE_COMMENT: "Please find the product for which we would need the quotation and other relevant details. ",
    SUPPLIER_NOT_AUTHORIZED_DEALER_MANUFACTURER: "Selected supplier is not Authorized Dealer/Manufacturer ",
    MSN_SUGGESTIONS_AUTO_POPULATED_FIELDS_COLOR: 'green',
    FINAL_TP_FINAL_SP_ERROR_WARNING_COLOR: 'orange',
    PO_ACTION_CREATE: 'createPO',
    ITEM_SHEET_EDIT_OBJECT_KEY_DIFFER_CDD2_CHECK: ['reasonForDelay', 'sourcing.omtPoc', 'sourcing.omtStatus', 'sourcing.omtBucket', 'remarks.remark9', 'remarks.remark3', 'remark', 'ems.poDataRemark1', 'ems.poStatus', 'remarks.remark5', 'remarks.remark7', 'catalog.urgencyClassification', 'catalog.priority', 'catalog.catalogStatus',
      'catalog.assignee', 'catalog.catalogPoc', 'catalog.clarificationClosed', 'catalog.remarkFromBusiness', 'catalog.remarkFromCatalog', 'sourcing.suggestSupplier', 'sourcing.suggestSupplierName', 'sourcing.suggestSupplierId', 'sourcing.suggestedSupplierPaymentTerms', 'sourcing.diskReceived', 'sourcing.pickupWarehouse', 'sourcing.routingConfiguration', 'remarks.remark8'],
    CANCEL_ITEMS_CHECK: ['delivered', 'partially delivered', 'cancelled', 'returned', 'partially returned', 'rejected', 'received', 'partially received', 'delivered', 'shipped', 'partially shipped', 'invoiced', 'partially invoiced', 'pending for approval', 'closed'],
    SUBJECT_LINE_CHARACHTER_LENGTH: 170,
    SUPPLIERS_RESPONSE_PAGE_PART_URL: '/pre-po/supplier-items/suppliers-response',
    // SUPPLIERS_RESPONSE_PAGE_FIELDS_LIST: [{name:'Suggested Brand', key:'suggestedBrand'},{name:'List Price',key:'listPrice'},{name:'Discount',key:'discount'},{name:'Submission Date',key:'submissionDate'},{name:'Validity',key:'supplierValidity'},{name:'HSN',key:'hsn'},{name:'Tax',key:'tax'},{name:'Remarks',key:'remarks'},{name:'Payment Terms',key:'paymentTerms'},{name:'Inco Terms',key:'incoTerms'}],
    SUPPLIERS_RESPONSE_PAGE_FIELDS_LIST: [{ name: 'Suggested Brand', key: 'suggestedBrand' }, { name: 'List Price', key: 'listPrice' }, { name: 'Discount', key: 'discount' }, { name: 'Submission Date', key: 'submissionDate' }, { name: 'Validity', key: 'supplierValidity' }, { name: 'HSN', key: 'hsn' }, { name: 'Tax', key: 'tax' }, { name: 'Remarks', key: 'remarks' }],
    UAE_COUNTRY_ID: 784,
    INDIA_COUNTRY_ID: 356,
    RFQ_STATUS_QUOTATION_RECEIVED_FROM_SUPPLIER: "quotation received from supplier",
    ITEM_SHEET_FILTER_MONTHS_LIMIT: 3,
    RFQ_STATUS_QUOTE_ACCEPTED: "quote accepted",
    RFQ_STATUS_QUOTE_REJECTED: "quote rejected",
    JIT_NOT_ALLOWED_PLANT_IDS: [16268, 18972],
    CDD2_ENABLE_ROLE: 'CS_ADMIN',
    ITEM_SHEET_UPDATE: "itemsheet_update",
    RFQ_MINIDASHBOARD_STAGES: {
      clarification: 'Clarification Sent To Customer',
      created: 'Created',
      "sent to supplier": 'RFQ Sent To Supplier',
      "quotation received from supplier": 'Quotation Received From Supplier',
      "working": 'Working',
      "erfq response received-unread": 'eRFQ Response Received-Unread',
      "quoted": 'Quoted'

    },
    ACCOUNT_MODULE_ACCESS: {
      createPO: "CREATE PO",
      approvePO: "CREATE PO",
      directPo: "CREATE PO",
      editPO: "PO EDITS",
      cancelAndClonePO: "PO EDITS",
      repeatPO: 'PO EDITS',
      bulkPO: 'BULK PO',
      createSA: 'CREATE SA',
      editSA: 'EDIT SA',
      markRelease: 'PO EDITS',
      cancelAndCloneItem: 'PO EDITS',
      viewPO: 'View PO',
      cancelPO: 'PO EDITS',
      cancelItem: 'PO EDITS',
      viewSA: "View SA",
      viewMultiplePO: "View PO",
      addReturnItem: 'PO EDITS',
      pushToSop: 'PO EDITS',
      repeatSA: 'EDIT SA',
      extendSA: 'EDIT SA',
      extendPO: 'PO EDITS',
      editCPONumber: 'PO EDITS'
    },
    CIS_PORTAL_LINK: "https://cis.moglix.com/product-discovery?sor=EOC&searchStr=",
    DESIGNATION_SOURCER: ["CATEGORY BUYER", "ENTERPRISE SOURCER"],
    DESIGNATION_KAM: ["KAM"]
  },
  STATIC_DROPDOWNS: {
    ARC_RFQ_LIST: [{ id: "ARC", value: "ARC" }, { id: "RFQ", value: "RFQ" }, { id: "CRMM", value: "CRMM" }, { id: "SAMPLE", value: "SAMPLE" }, { id: "INTERNAL", value: "INTERNAL" }, { id: "PAAS", value: "PAAS" }, { id: "LIQUIDATION", value: "LIQUIDATION" }],
    LD_APPLICABLE_LIST: [{ id: true, value: "Y" }, { id: false, value: "N" }],
    OPEN_PO_ARC_RFQ_LIST: [{ id: "ARC", value: "ARC" }, { id: "RFQ", value: "RFQ" }, { id: "CRMM", value: "CRMM" }, { id: "Open PO", value: "Open PO" }, { id: "SAMPLE", value: "SAMPLE" }, { id: "INTERNAL", value: "INTERNAL" }],
    ARC_RFQ_LIST_WITHOUT: [{ id: "RFQ", value: "RFQ" }, { id: "CRMM", value: "CRMM" }],
    CLOSE_PO_ITEM_REASON: [
      'MOQ Based Issues', 'Old-LPP CPO Received', 'Negative Margin', 'Supplier LP Changes', 'Without Confirmation Order', 'Closed from Customer End', 'Duplicate Orders', 'OEM Delay', 'Supplier-Material Not Available', 'CRMM issues', 'Tolerance - short close cases', 'Product obsolete'
    ],
    SA_STATUS_LIST: ["Active", "Closed"],
    SA_TYPE_LIST: { Released: "Released", Unreleased: "Un-Released" },
    SA_SOURCE_LIST: { EOC: "EOC", SAP: "SAP", MANUAL: "MANUAL" }
  },
  VENDOR: {
    VENDOR_DETAILS: 'service/unspscvendor/details',
    VENDOR_STATUS: 'lpp_serviceablity/vendor_status',
    VENDOR_UPLOAD: 'lpp_serviceablity/vendor_upload',
  },

  MRPINVENTORY: {
    INVENTORY: 'inventory/details'
  },
  PR_SHEET: {
    GO_TO_SHEET: 'pr/pr-sheet'
  },
  VENDORS: {
    SINGLE_VENDOR: 'service/unspsc'
  }
};
