
import { LineItemHelperService } from '@app/services/helpers/line-item-helper.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PoService } from '@app/services/po.service';
import { AuthService } from '../auth.service';
import * as moment from 'moment';
import { BuyerService } from '../buyer.service';
import * as lodash from 'lodash-es';
import { ValidationService } from './validation.service';
import { SuppliersSheetHelperService } from './suppliers-sheet-helper.service';
import { AlertMessageService } from '../alert-message.service';
import { ToastrService } from 'ngx-toastr';
import { CatalogSheetModel } from '@app/models/catalogsheet.model';
import { OmtSheetModel } from '@app/models/omtsheet.model';
import { PoItemService } from '../po-item.service';
import { SHEET_DATA_TYPE } from '@app/config/sheetfields-datatype';
import { PARAMS } from '@app/config/params';
import { MESSAGES } from '@app/config/messages';


@Injectable({
  providedIn: 'root'
})
export class OmtSheetHelperService {

  validationFieldType = SHEET_DATA_TYPE.OMT_SHEET_FIELDS_TYPE;
  wholeItemSheetRowObj: any;
  roleList: Array<any> = [];



  constructor(private toastr: ToastrService, private authService: AuthService, private activatedRoute: ActivatedRoute, private alertMessageService: AlertMessageService,
    private suppliersSheetHelperService: SuppliersSheetHelperService, private poService: PoService, private buyerService: BuyerService, private validationService: ValidationService, private poItemService: PoItemService) {
      this.authService.eocModuleAccessRoleList.subscribe((roleList: any) => {
        this.roleList = roleList;
      });
  }


  sheetGroupInfo: Array<any> = [
    { id: 'plantData', class: 'sky-blue', name: 'Plant Data' },
    { id: 'omtData', class: 'light-green', name: 'OMT Data' },
    { id: 'etaData', class: 'dark-blue-green', name: 'ETA Data' }
  ]

  defaultSelectedSheetGroup = ['plantData', 'omtData', 'etaData'];

  getViewLineItemsObjForOmt() {
    let lineObject = {
      plantData: {
        title: 'PO Data',
        headingClass: 'basic-info',
        items: {
          plantId: {
            title: 'Plant ID',
            hide: false,
            value: null,
            disable: true,
          },
          plantName: {
            title: 'Plant Name',
            hide: false,
            value: null,
            disable: true,
          },
          materialDescription: {
            title: 'Material Description',
            hide: false,
            value: null,
            disable: true,
          },
        }
      },
      omtData: {
        title: 'OMT Data',
        headingClass: 'sheet-group-podata',
        items: {
          itemId: {
            title: 'Item Id',
            hide: false,
            value: null,
            disable: true,
          },
          broadStage: {
            title: 'Broad Stage',
            hide: false,
            value: null,
            disable: true,
          },
          supplierPoStatus: {
            title: 'Supplier Po Status',
            hide: false,
            value: null,
            disable: true,
          },
          sourcerEmail: {
            title: 'Sourcing POC',
            hide: false,
            value: null,
            disable: false,
          },
          supplierName: {
            title: 'Supplier Name',
            hide: false,
            value: null,
            disable: true,
          },
          supplierId: {
            title: 'Supplier ID',
            hide: false,
            value: null,
            disable: true,
          },
          supplierPoId: {
            title: 'Supplier PoId',
            hide: false,
            value: null,
            disable: true,
          },
          supplierPoRaisedDate: {
            title: 'Supplier Po Raised Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          supplierPaymentTerms: {
            title: 'Supplier Payment Terms',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.omtPoc': {
            title: 'OMT POC',
            hide: false,
            value: null,
            disable: false,
          },
          'sourcing.sourcingRemarkToOmt': {
            title: 'Sourcing remark to OMT',
            hide: false,
            value: null,
            disable: true,
          },
          // 'sourcing.omtStatus': {
          //   title: 'OMT Status',
          //   hide: false,
          //   value: null,
          //   disable: false,
          //   inputType: 'dropdown'
          // },
          // 'sourcing.omtBucket': {
          //   title: 'OMT Bucket',
          //   hide: false,
          //   value: null,
          //   disable: false,
          //   inputType: 'dropdown'
          // },
          // 'remarks.remark9': {
          //   title: 'OMT Remark',
          //   hide: false,
          //   value: null,
          //   disable: false,
          // },
          // 'remarks.remark3': {
          //   title: 'remark3',
          //   hide: false,
          //   value: null,
          //   disable: false,
          // },
          'remarks.remark4': {
            title: 'remark4',
            hide: false,
            value: null,
            disable: false,
          },
          supplierRemarks: {
            title: 'Supplier Remarks',
            hide: false,
            value: null,
            disable: true,
          },
          'sourcing.supplierFollowDate': {
            title: 'Supplier Follow Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
          },
          "sourcing.supplierPickupDate": {
            title: 'Actual Supplier Pickup Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          committedSupplierPickupDateVsRevisedSupplierPickupDateTat: {
            title: 'Committed Supplier Pickup Date vs Revised Supplier Pickup Date TAT',
            hide: false,
            value: null,
            disable: true,
          },
          'delivery.customerDueDeliveryDate': {
            title: 'Customer Due Delivery Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true
          },
          'delivery.cdd2': {
            title: 'CDD2 (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
            tooltip:'CS Head is authorized to update CDD2 for POs older than 1 year from the creation date'
          },
          'delivery.cdd2Reason': {
            title: 'CDD2 Reason',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          'delivery.cddBreachReason': {
            title: 'CDD Breach Reason',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          'sourcing.omtStatus': {
            title: 'OMT Status',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          'sourcing.omtBucket': {
            title: 'OMT Bucket',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          'remarks.remark9': {
            title: 'OMT Remark',
            hide: false,
            value: null,
            disable: false,
          },
          'remarks.remark3': {
            title: 'Clarification to SRE',
            hide: false,
            value: null,
            disable: false,
          },
        }
      },
      etaData: {
        title: 'ETA',
        headingClass: 'sheet-group-etareport',
        items: {
          'eta.bufferTime': {
            title: 'Buffer',
            hide: false,
            value: null,
            disable: false,
          },
          customerETA: {
            title: 'Customer ETA(MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true
          },
          etaStatus: {
            title: 'ETA > Final CDD',
            hide: false,
            value: null,
            disable: true,
          },
          reasonForDelay: {
            title: 'Reason for Delay',
            hide: false,
            value: null,
            disable: false,
            inputType: 'dropdown'
          },
          supplierFT: {
            title: 'Supplier FT',
            hide: false,
            value: null,
            disable: true,
          },
          "sourcing.supplierPickupDueDate": {
            title: 'Committed Supplier Pickup Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false,
          },
          revisedPickupDate: {
            title: 'Revised Supplier Pickup Date (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: true,
          },
          // 'eta.bufferTime': {
          //   title: 'Buffer',
          //   hide: false,
          //   value: null,
          //   disable: false,
          // },
          // customerETA: {
          //   title: 'Customer ETA(MM/DD/YYYY)',
          //   hide: false,
          //   value: null,
          //   disable: true
          // },
          // etaStatus: {
          //   title: 'ETA > Final CDD',
          //   hide: false,
          //   value: null,
          //   disable: true,
          // },
          // reasonForDelay: {
          //   title: 'Reason for Delay',
          //   hide: false,
          //   value: null,
          //   disable: false,
          //   inputType: 'dropdown'
          // },
          targetPickupDate: {
            title: 'Target Pickup Date',
            hide: false,
            value: null,
            disable: true
          },
          manualCustomerEta: {
            title: 'Manual ETA (MM/DD/YYYY)',
            hide: false,
            value: null,
            disable: false
          },
          poApprovalDate: {
            title: 'PO Approval Date',
            hide: true,
            value: null,
            disable: false,
          }
        }
      }
    }
    return lineObject;
  }

  getFieldValue(data: Object, field: String) {
    return lodash.has(data, field) ? data[`${field}`] : ''
  }

  getFieldValueEmptyReturnNA(data: Object, field: String) {
    if (lodash.has(data, field) && (field == "deliveryTime" || field == "pickTime") && data[`${field}`] === 0) {
      return "Update DB";
    }
    return (lodash.has(data, field) && !this.validationService.checkEmptyOrNull(data[`${field}`])) ? data[`${field}`] : 'NA'
  }

  makeItemsData(data) {
    const poDataSet: any[] = [];
    const dataIndex: Object = {};
    let sheetDataIndex = -1;
    for (let i = 0; i < data.length; i++) {
      const lineItemObject = this.getViewLineItemsObjForOmt();
      const itemData = data[i];
      let plantName = this.getFieldValue(itemData, 'plantName');
      if (lodash.has(itemData, 'plantId') && this.checkEmptyOrNull(plantName)) {
        plantName = this.authService.getPlantNameFromId(itemData.plantId);
      }
      sheetDataIndex = sheetDataIndex + 1;
      dataIndex[itemData.itemId] = sheetDataIndex;

      lineItemObject.plantData.items.plantName.value = plantName;
      lineItemObject.omtData.items.itemId.value = itemData.itemId;
      lineItemObject.plantData.items.plantId.value = this.getFieldValue(itemData, 'plantId');
      lineItemObject.plantData.items.materialDescription.value = lodash.has(itemData, 'materialDescription') ? data[i].materialDescription : '';
      lineItemObject.omtData.items.committedSupplierPickupDateVsRevisedSupplierPickupDateTat.value = this.getFieldValue(itemData, 'committedSupplierPickupDateVsRevisedSupplierPickupDateTat');
      lineItemObject.omtData.items['sourcing.supplierFollowDate'].value = this.validationService.formatDate(this.getFieldValue(itemData, 'supplierFollowDate'));
      lineItemObject.omtData.items.broadStage.value = this.getFieldValue(itemData, 'broadStage');
      lineItemObject.omtData.items.supplierPoStatus.value = this.getFieldValue(itemData, 'supplierPoStatus');;
      lineItemObject.omtData.items.sourcerEmail.value = this.getFieldValue(itemData, 'sourcerEmail');
      lineItemObject.omtData.items['remarks.remark9'].value = this.getFieldValue(itemData, 'remark9');
      lineItemObject.omtData.items['remarks.remark3'].value = this.getFieldValue(itemData, 'remark3');
      lineItemObject.omtData.items['remarks.remark4'].value = this.getFieldValue(itemData, 'remark4');
      lineItemObject.omtData.items.supplierName.value = this.getFieldValue(itemData, 'supplierName');
      lineItemObject.omtData.items.supplierId.value = this.getFieldValue(itemData, 'supplierId');
      lineItemObject.omtData.items.supplierPoId.value = this.getFieldValue(itemData, 'supplierPoId');
      lineItemObject.omtData.items.supplierPoRaisedDate.value = this.getFieldValue(itemData, 'supplierPoRaisedDate');
      lineItemObject.etaData.items.supplierFT.value = this.getFieldValue(itemData, 'supplierFT');
      lineItemObject.omtData.items.supplierPaymentTerms.value = this.getFieldValue(itemData, 'supplierPaymentTerms');
      lineItemObject.omtData.items['sourcing.omtPoc'].value = this.getFieldValue(itemData, 'omtPoc');
      lineItemObject.omtData.items['sourcing.sourcingRemarkToOmt'].value = this.getFieldValue(itemData, 'sourcingRemarkToOmt');
      lineItemObject.omtData.items['sourcing.omtStatus'].value = this.getFieldValue(itemData, 'omtStatus');
      lineItemObject.omtData.items['sourcing.omtBucket'].value = this.getFieldValue(itemData, 'omtBucket');
      lineItemObject.etaData.items['manualCustomerEta'].value = this.validationService.formatDate(this.getFieldValue(itemData, 'manualCustomerEta'));
      lineItemObject.etaData.items['sourcing.supplierPickupDueDate'].value = this.validationService.formatDate(this.getFieldValue(itemData, 'committedSupplierPickupDate'));
      lineItemObject.etaData.items.revisedPickupDate.value = this.getFieldValue(itemData, 'revisedSupplierPickupDate');
      lineItemObject.omtData.items['sourcing.supplierPickupDate'].value = this.getFieldValue(itemData, 'actualSupplierPickupDate');
      lineItemObject.omtData.items['delivery.customerDueDeliveryDate'].value = this.validationService.formatDate(this.getFieldValue(itemData, 'customerDueDeliveryDate'));
      lineItemObject.omtData.items['delivery.cdd2'].value = this.validationService.formatDate(this.getFieldValue(itemData, 'cdd2'));
      lineItemObject.omtData.items['delivery.cdd2Reason'].value = this.getFieldValue(itemData, 'cdd2Reason');
      lineItemObject.omtData.items['delivery.cddBreachReason'].value = this.getFieldValue(itemData, 'cddBreachReason');
      lineItemObject.omtData.items.supplierRemarks.value = this.getFieldValue(itemData, 'updateRemarks');
      lineItemObject.etaData.items['eta.bufferTime'].value = this.getFieldValue(itemData, 'buffer');
      lineItemObject.etaData.items.poApprovalDate.value = this.getFieldValue(itemData, 'poApprovalDate');
      lineItemObject.etaData.items.targetPickupDate.value = this.getFieldValue(itemData, 'targetPickUpDate');
      lineItemObject.etaData.items.etaStatus.value = this.getFieldValue(itemData, 'etaStatus');
      lineItemObject.etaData.items.reasonForDelay.value = this.getFieldValue(itemData, 'reasonForDelay');
      lineItemObject.etaData.items.customerETA.value = this.validationService.formatDate(this.getFieldValue(itemData, 'customerETA'));
      let broardstage = lineItemObject.omtData.items.broadStage.value;
      // let stagearray = ['Shipped', 'Delivered', 'Returned/Rejected', 'Closed', 'Returned', 'Rejected']
      let stagearray = ['Delivered', 'Returned/Rejected', 'Closed', 'Returned', 'Rejected']
      if (stagearray.indexOf(broardstage) > -1) {
        lineItemObject.omtData.items['delivery.cdd2'].disable = true;
        lineItemObject.omtData.items['delivery.cdd2Reason'].disable = true;
        lineItemObject.omtData.items['delivery.cdd2Reason'].inputType = null;
      }
      poDataSet.push(lineItemObject)
    }

    return { poDataSet, dataIndex };
  }



  public checkEmptyOrNull(value) {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
  }

  convertSheetValueToObjectV3(data) {
    if (data) {
      let itemSheetModel = {};
      data.forEach(element => {
        let key = element.id;
        if (element.id) {
          itemSheetModel[key] = element.value;
        }
      });
      this.wholeItemSheetRowObj = itemSheetModel;
      return itemSheetModel;
    }
  }

  async makeDataForUpdateItemInOmtSheet(data: any, editedItem?) {

    const sheetvalues = this.convertSheetValueToObjectV3(data);
    const itemSheetModel = editedItem ? editedItem.editObj : {};
    if (!(lodash.has(editedItem, 'id'))) {
      return null;
    }
    let emptycheck = this.emptycheck(sheetvalues, itemSheetModel);
    let sourcerEmailCheck: any = true;
    if (!this.checkEmptyOrNull(itemSheetModel.sourcerEmail)) {
      if (this.validationService.emailFormatValidationCheck(itemSheetModel.sourcerEmail)) {
        let emailcheck = [];
        itemSheetModel.sourcerEmail = itemSheetModel.sourcerEmail.toLowerCase();
        emailcheck.push(itemSheetModel.sourcerEmail)
        sourcerEmailCheck = await this.poItemService.checkSourcerEmail(emailcheck);
      }
      else {
        sourcerEmailCheck = false;
      }
    }
    if (emptycheck != 'error' && sourcerEmailCheck) {

      let itemObj = this.convertFinalEditedObj(editedItem);
      Object.keys(itemObj).forEach(validationfield => {
        if (this.validationFieldType[validationfield] == "string") {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
        else if (this.validationFieldType[validationfield] == "number") {
          itemObj[validationfield] = this.validationService.getNumberFormat(itemObj[validationfield])
        }
        else if (this.validationFieldType[validationfield] == "date") {
          itemObj[validationfield] = this.validationService.getDateFormatToLongInt(itemObj[validationfield])
        }
        else {
          itemObj[validationfield] = this.validationService.getStringFormat(itemObj[validationfield]);
        }
      });
      return itemObj;
    }
    else {
      return 'error';
    }
  }

  convertFinalEditedObj(data) {
    if (data) {
      let itemSheetModel = data.editObj;
      if (Object.keys(data.editObj).length > 0) {
        itemSheetModel['_id'] = data.id;
      }
      return itemSheetModel;
    }
  }


  public emptycheck(itemSheetModel, editObject?) {
    let emptyCheck = ["", null, undefined];
    let editObjectKey = editObject ? Object.keys(editObject):null
    let editObjectKeyDiffrenceArray;
    if(editObjectKey){
     editObjectKeyDiffrenceArray = editObjectKey.filter(x => ! PARAMS.STATIC_PARAMS.ITEM_SHEET_EDIT_OBJECT_KEY_DIFFER_CDD2_CHECK.includes(x)) ;
    }
    if (emptyCheck.indexOf(itemSheetModel['delivery.cdd2']) < 0) {
      if (emptyCheck.indexOf(itemSheetModel['delivery.cdd2Reason']) > -1) {
        this.alertMessageService.addError("Please update CDD2 Reason to save").show();
        return 'error';
      }
    }
    if (emptyCheck.indexOf(itemSheetModel['delivery.cdd2Reason']) < 0) {
      if (emptyCheck.indexOf(itemSheetModel['delivery.cdd2']) > -1) {
        this.alertMessageService.addError("Please update CDD2 to save").show();
        return 'error';
      }
    }
    if ((emptyCheck.indexOf(itemSheetModel['delivery.cdd2']) < 0) && ((moment(itemSheetModel['delivery.cdd2'], 'MM/DD/YYYY', true).valueOf()).toString() == "NaN") || Math.sign(moment(itemSheetModel['delivery.cdd2'], 'MM/DD/YYYY', true).valueOf()) == -1) {
      this.alertMessageService.addError("Please Enter Valid CDD2 Date").show();
      return 'error';
    }
    if ((emptyCheck.indexOf(itemSheetModel['manualCustomerEta']) < 0) && ((moment(itemSheetModel['manualCustomerEta'], 'MM/DD/YYYY', true).valueOf()).toString() == "NaN") || Math.sign(moment(itemSheetModel['manualCustomerEta'], 'MM/DD/YYYY', true).valueOf()) == -1) {
      this.alertMessageService.addError("Please Enter Valid Manual ETA Update Date").show();
      return 'error';
    }
    if ((emptyCheck.indexOf(itemSheetModel['sourcing.poDocumentDate']) < 0) && ((moment(itemSheetModel['sourcing.poDocumentDate'], 'MM/DD/YYYY', true).valueOf()).toString() == "NaN") || Math.sign(moment(itemSheetModel['sourcing.poDocumentDate'], 'MM/DD/YYYY', true).valueOf()) == -1) {
      this.alertMessageService.addError("Please Enter Valid Po Document Date").show();
      return 'error';
    }
    if ((emptyCheck.indexOf(itemSheetModel['sourcing.supplierPickupDueDate']) < 0) && ((moment(itemSheetModel['sourcing.supplierPickupDueDate'], 'MM/DD/YYYY', true).valueOf()).toString() == "NaN") || Math.sign(moment(itemSheetModel['sourcing.supplierPickupDueDate'], 'MM/DD/YYYY', true).valueOf()) == -1) {
      this.alertMessageService.addError("Please Enter Valid Commited Supplier Pick up  Date").show();
      return 'error';
    }
   if ((emptyCheck.indexOf(itemSheetModel['sourcing.supplierFollowDate']) < 0) && ((moment(itemSheetModel['sourcing.supplierFollowDate'], 'MM/DD/YYYY', true).valueOf()).toString() == "NaN") || Math.sign(moment(itemSheetModel['sourcing.supplierFollowDate'], 'MM/DD/YYYY', true).valueOf()) == -1) {
      this.alertMessageService.addError("Please Enter Valid Supplier Follow Date").show();
      return 'error';
    } 
    if ((emptyCheck.indexOf(itemSheetModel['customerFollowupDate']) < 0) && ((moment(itemSheetModel['customerFollowupDate'], 'MM/DD/YYYY', true).valueOf()).toString() == "NaN") || Math.sign(moment(itemSheetModel['customerFollowupDate'], 'MM/DD/YYYY', true).valueOf()) == -1) {
      this.alertMessageService.addError("Please Enter Valid Followup Date").show();
      return 'error';
    }
    if ((emptyCheck.indexOf(itemSheetModel['extraDetails.tolerance']) < 0) && itemSheetModel['extraDetails.tolerance'] > 50) {
      this.alertMessageService.addError("Please Enter Tolerance upto 50").show();
      return 'error';
    }
    if ((emptyCheck.indexOf(itemSheetModel.poApprovalDate) < 0) && (emptyCheck.indexOf(itemSheetModel['delivery.cdd2']) < 0) && (emptyCheck.indexOf(editObject['manualCustomerEta']) > -1) ) {
      let poCreationDateAdditionOneYear = moment(itemSheetModel.poApprovalDate).add(1, 'y');
      let poCreationDateAdditionTwoYear = moment(itemSheetModel.poApprovalDate).add(2, 'y');
       if (!editObjectKey || (editObjectKeyDiffrenceArray && editObjectKeyDiffrenceArray.length>0)) {
        if (moment(itemSheetModel['delivery.cdd2']).isBetween(poCreationDateAdditionOneYear, poCreationDateAdditionTwoYear)) {
          if (![130].includes(Number(this.authService.getBusinessDesignationType()))) { // "130" -> "CS HEAD",
            this.alertMessageService.addError(MESSAGES.ERROR.COMMON.CDD2_VALIDATION_BETWEEN_ONE_TO_TWO_YEAR_ERROR).show();
            return 'error';
          }
        }
        else if (!moment(itemSheetModel['delivery.cdd2']).isBetween(itemSheetModel.poApprovalDate, poCreationDateAdditionOneYear) || (moment(itemSheetModel['delivery.cdd2'], "MM/DD/YYYY") < moment(new Date(), "MM/DD/YYYY"))) {
          this.alertMessageService.addError(MESSAGES.ERROR.COMMON.CDD2_VALIDATION_ABOVE_TWO_YEAR_ERROR).show();
          return 'error';
        }
      }
    }
    if ((emptyCheck.indexOf(itemSheetModel['eta.bufferTime']) < 0) && ((isNaN(itemSheetModel['eta.bufferTime'])) || ((itemSheetModel['eta.bufferTime']) % 1 != 0))) {
      this.alertMessageService.addError("Buffer Should be Numeric And Non Decimal").show();
      return 'error';
    }
    if (emptyCheck.indexOf(itemSheetModel['manualCustomerEta']) < 0 && emptyCheck.indexOf(itemSheetModel['reasonForDelay']) > -1) {
      if (moment(itemSheetModel['manualCustomerEta'], 'MM/DD/YYYY') > moment(itemSheetModel['customerDueDeliveryDate'], 'MM/DD/YYYY') || moment(itemSheetModel['manualCustomerEta'], 'MM/DD/YYYY') > moment(itemSheetModel['delivery.customerDueDeliveryDate'], 'MM/DD/YYYY')) {
        this.alertMessageService.addError("Please update Reason for Delay to save").show();
        return 'error';
      } else if (moment(itemSheetModel['manualCustomerEta'], 'MM/DD/YYYY') > moment(itemSheetModel['delivery.cdd2'], 'MM/DD/YYYY')) {
        this.alertMessageService.addError("Please update Reason for Delay to save").show();
        return 'error';
      }
    }
    if (emptyCheck.indexOf(itemSheetModel['manualCustomerEta']) < 0 && emptyCheck.indexOf(itemSheetModel['poItemCreationDate']) < 0) {
      if (moment(itemSheetModel['manualCustomerEta'], 'MM/DD/YYYY') < moment(itemSheetModel['poItemCreationDate'], 'MM/DD/YYYY')) {
        this.alertMessageService.addError("Manual ETA Date must be greater then Po Creation Date").show();
        return 'error';
      }
    }
    return null
  }

}



